import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import moment from "moment/moment";
import eFactureAxios from "../../../api/eFacture/axios";

export const fetchAllVatRecordings = createAsyncThunk("fetchAllVatRecordings", async (data) => {
  return (await eFactureAxios.get(`/vat-recording/group?dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`)).data;
});

export const postVatRecordings = createAsyncThunk("postVatRecordings", async (data) => {
  return (await eFactureAxios.post(`/vat-recording/group`, data)).data;
});

export const fetchAllVatIndividualRecordings = createAsyncThunk("fetchAllVatIndividualRecordings",
  async ({ dateFrom, dateTo }) => {
    return (await eFactureAxios.get(`/vat-recording/individual?dateFrom=${dateFrom}&dateTo=${dateTo}`)).data;
  });

export const postIndividualVatRecordings = createAsyncThunk("postIndividualVatRecordings", async (data) => {
  return (await eFactureAxios.post(`/vat-recording/individual`, data)).data;
});

export const cancelIndividualVatRecording = createAsyncThunk("cancelIndividualVatRecording", async (data) => {
  return (await eFactureAxios.post(`/vat-recording/individual/cancel/${data.IndividualVatId}`)).data;
});
export const getIndividualVatRecording = createAsyncThunk("cancelIndividualVatRecording", async (data) => {
  return (await eFactureAxios.get(`/vat-recording/individual/${data}`)).data;
});

const initialState = {
  allVatRecordings: [],
  allIndividualVatRecordings: [],
  loading: false,
  error: undefined
};

export const slice = createSlice({
  name: "eFactureVats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllVatRecordings.pending, updateLoading)
      .addCase(fetchAllVatRecordings.fulfilled, updateVatRecordings("allVatRecordings"))
      .addCase(fetchAllVatRecordings.rejected, updateLoading)

      .addCase(fetchAllVatIndividualRecordings.pending, updateLoading)
      .addCase(fetchAllVatIndividualRecordings.fulfilled, updateVatRecordings("allIndividualVatRecordings"))
      .addCase(fetchAllVatIndividualRecordings.rejected, updateLoading)

      .addCase(postVatRecordings.pending, updateLoading)
      .addCase(postVatRecordings.fulfilled, addToVatRecordings("allVatRecordings"))
      .addCase(postVatRecordings.rejected, updateLoading)

      .addCase(postIndividualVatRecordings.pending, updateLoading)
      .addCase(postIndividualVatRecordings.fulfilled, addToVatRecordings("allIndividualVatRecordings"))
      .addCase(postIndividualVatRecordings.rejected, updateLoading)

      .addCase(cancelIndividualVatRecording.pending, updateLoading)
      .addCase(cancelIndividualVatRecording.fulfilled, cancelIndividualVatRecordingStatus)
      .addCase(cancelIndividualVatRecording.rejected, updateLoading);
  }
});

function updateLoading(state) {
  state.loading = true;
}

function updateVatRecordings(vatRecordingsList) {
  return (state, { payload }) => {
    state[vatRecordingsList] = payload.sort((a, b) => moment(b.StatusChangeDate).diff(moment(a.StatusChangeDate)));
    state.loading = false;
  };
}

function addToVatRecordings(vatRecordingsList) {
  return (state, { payload }) => {
    const arr = [...current(state[vatRecordingsList])];
    arr.push(payload);
    state[vatRecordingsList] = arr;
    state.loading = false;
  };
}

function cancelIndividualVatRecordingStatus(state, { payload }) {
  const arr = [...current(state.allIndividualVatRecordings)];
  const index = arr.findIndex(i => i.IndividualVatId === payload);
  if (index !== -1) {
    arr[index] = { ...arr[index], VatRecordingStatus: "Cancelled" };
    state.allIndividualVatRecordings = arr;
  }
  state.loading = false;
}

export default slice.reducer;
