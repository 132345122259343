import {Alert, Button, Container} from "@mui/material";
import {MaintenanceIllustration} from "../assets/illustratuions";

export default function Fallback({error}) {
    const handleGoToHome = () => {
        window.location.href = '/';
    }

    return (
        <Container sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "98vh",
            gap: 2
        }}>
            <MaintenanceIllustration sx={{width: 1, maxWidth: 600}}/>
            <Alert severity="error">
                Something went wrong:
                <pre style={{color: "red"}}>{error.message}</pre>
            </Alert>

            <Button
                variant="contained"
                onClick={handleGoToHome}>
                Go to home
            </Button>
        </Container>
    );
}
