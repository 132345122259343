import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useReducer } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile
} from "firebase/auth";
import { child, get, getDatabase, ref } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref as sRef } from "firebase/storage";
import { dispatch as reduxDispatch } from "../redux/store";

// config
import { FIREBASE_API } from "../config";
import {
  clearSession,
  getAndCreateUniqueComputerUid,
  getSessionOperator,
  setEFactureApiKey,
  setEFactureUserData,
  setSessionOperator,
  setSessionUserInfo
} from "../helper/session";
import { createOperatorSession } from "../redux/slices/operators";

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  operator: null
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      operator: action.payload.operator
    };
  }
  if (action.type === "OPERATOR") {
    return {
      ...state,
      operator: action.payload.operator
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);
export const analyticsFB = getAnalytics();
export const AUTH = getAuth(firebaseApp);
export const DB = getFirestore(firebaseApp);
export const RDB = getDatabase(firebaseApp);
export const dbRef = ref(RDB);
export const storageRef = getStorage(firebaseApp);
export const productsImageStorage = sRef(storageRef, "public/itemphotos");

AuthProvider.propTypes = {
  children: PropTypes.node
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const operator = getSessionOperator();
          const eFacture = (await get(child(dbRef, `users/${user.uid}/protected/efakture`))).val();
          const tin = (await get(child(dbRef, `users/${user.uid}/profile/pub`))).val();
          const userInfo = (await get(child(dbRef, `public/businesses/${tin?.TIN}`))).val();
          setEFactureApiKey(eFacture?.apiKey);
          setEFactureUserData(userInfo);
          setSessionUserInfo({
            ...user,
            ...eFacture
          });
          dispatch({
            type: "INITIAL",
            payload: {
              isAuthenticated: true,
              user,
              operator
            }
          });
        } else {
          clearSession(true);
          dispatch({
            type: "INITIAL",
            payload: {
              isAuthenticated: false,
              user: null,
              operator: null
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);

  const operatorLogin = async (username, password) => {
    const arr = [];
    (await get(child(ref(RDB), `users/${state.user.uid}/private/operators`))).forEach(child1 => {
      arr.push({
        ...child1.val(),
        uid: child1.key
      });
    });
    const foundedOperator = arr.find(operator => parseInt(operator.secret, 10) === parseInt(password, 10) &&
      operator.username === username);
    if (!foundedOperator) {
      throw new Error("Pogresno korisničko ime ili lozinka!");
    }
    await reduxDispatch(createOperatorSession({
      operatorID: foundedOperator.username,
      deviceID: getAndCreateUniqueComputerUid(),
      licenseType: "ESIR"
    }));
    setSessionOperator(foundedOperator);
    dispatch({
      type: "OPERATOR",
      payload: {
        operator: foundedOperator
      }
    });
  };

  const logOutOperator = () => {
    clearSession();
    dispatch({
      type: "OPERATOR",
      payload: {
        operator: null
      }
    });
  };

  // LOGOUT
  const logout = async () => {
    clearSession(true);
    await signOut(AUTH);
    if (window) {
      window.location.reload();
    }
  };

  const sendEmailPasswordReset = () => sendPasswordResetEmail(AUTH, state.user.email);

  const updateDisplayName = (displayName) => updateProfile(state.user, {
    displayName
  });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        login,
        logout,
        operatorLogin,
        logOutOperator,
        sendEmailPasswordReset,
        updateDisplayName
      }}>
      {children}
    </AuthContext.Provider>
  );
}
