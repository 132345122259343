import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAllWrittenOffProductsFromFirestore } from "../../helper/firestore/writeOff";
import { getSessionOperator } from "../../helper/session";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { AUTH, DB } from "../../auth/FirebaseContext";
import { changeIngQuantity, changeItemQuantity } from "../../helper/realtimeDatabase";
import { CHANGE_ITEM_QUANTITY } from "../../constants";

export const addWriteOff = createAsyncThunk("addWriteOff", async (data) => {
  let cashier = getSessionOperator().username;
  let id = Date.now();
  await setDoc(doc(DB, "writeOff", "users", AUTH.currentUser.uid, id.toString()), {
    orderId: data.orderId || null,
    product: {
      uid: data.productId.uid,
      name: data.productId.name,
      price: Number(data.productId.price)
    },
    quantity: Number(data.quantity),
    cashier,
    note: data.note || null,
    writeOfTime: Timestamp.fromMillis(id)
  });
  if (data.isIng) {
    await changeIngQuantity(data.productId.uid, Number(data.quantity), CHANGE_ITEM_QUANTITY.reduce);
  } else {
    await changeItemQuantity(data.productId.uid, Number(data.quantity), CHANGE_ITEM_QUANTITY.reduce);
  }
  return {
    ...data,
    id,
    product: {
      ...data.productId,
      uid: data.productId.uid
    },
    writeOfTime: Timestamp.fromMillis(id)
  };
});
export const fetchAllWrittenOffItems = createAsyncThunk("fetchAllWrittenOffItems", async () => {
  return getAllWrittenOffProductsFromFirestore();
});

const initialState = {
  writeOffList: [],
  loading: false
};

const slice = createSlice({
  name: "writeOff",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllWrittenOffItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllWrittenOffItems.fulfilled, (state, { payload }) => {
        state.writeOffList = payload;
        state.loading = false;
      })
      .addCase(fetchAllWrittenOffItems.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addWriteOff.fulfilled, (state, { payload }) => {
        const arr = [...current(state.writeOffList)];
        arr.push(payload);
        state.writeOffList = arr;
      });
  }
});

// Reducer
export default slice.reducer;
