import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { child, get, set } from "firebase/database";
import { AUTH, dbRef } from "../../auth/FirebaseContext";

export const fetchAllUserMessages = createAsyncThunk("fetchAllUserMessages", async () => {
  let arr = [];
  (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox`))).forEach(child => {
    let childValue = child.val();
    arr.push({
      uid: child.key,
      ...childValue
    });
  });
  return arr;
});

export const setNotificationAsRead = createAsyncThunk("setNotificationAsRead", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox/${data.uid}`), {
    body: data.body,
    date: data.date,
    isRead: true,
    title: data.title
  });
});

export const setAllNotificationAsRead = createAsyncThunk("setAllNotificationAsRead", async (data) => {
  const updatePromises = data.map(async (msg) => {
    await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox/${msg.uid}`), msg);
  });
  await Promise.all(updatePromises);
  return data;
});

const initialState = {
  messages: [],
  loading: false
};

export const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addMessage: (state, { payload }) => {
      const { messages } = current(state);
      if (payload && !messages.some((message) => message.uid === payload.uid)) {
        state.messages = [...messages, payload];
      }
    },
    changeMessage: (state, { payload }) => {
      const { messages } = current(state);
      const index = messages.findIndex((message) => message.uid === payload.uid);
      if (payload && index !== -1) {
        state.messages = Object.assign([...messages], { [index]: payload });
      }
    },
    deleteMessage: (state, { payload }) => {
      const { messages } = current(state);
      const index = messages.findIndex((message) => message.uid === payload.uid);
      if (payload && index !== -1) {
        state.messages = messages.filter((_, i) => i !== index);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUserMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllUserMessages.fulfilled, (state, { payload }) => {
        state.messages = payload;
        state.loading = false;
      })
      .addCase(fetchAllUserMessages.rejected, (state) => {
        state.loading = false;
      })
      .addCase(setNotificationAsRead.fulfilled, (state, { meta }) => {
        const { arg } = meta;
        const newData = { ...arg, isRead: true };
        state.messages = state.messages.map((notification) =>
          notification.uid === newData.uid ? newData : notification
        );
      })
      .addCase(setAllNotificationAsRead.pending, (state) => {
        state.loading = true;
      })
      .addCase(setAllNotificationAsRead.fulfilled, (state, { payload }) => {
        state.messages = payload;
        state.loading = false;
      })
      .addCase(setAllNotificationAsRead.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const { addMessage, deleteMessage, changeMessage } = slice.actions;
export default slice.reducer;


