import uuidv4 from "../../utils/uuidv4";

const THEME_PATH = "esir-v2-theme";
const SESSION_LANGUAGE = "esir-v2-language";
const USER_INFO_PATH = "esir-v2-userInfo";
const COMPUTER_INDENTITY_PATH = "esir-v2-uid-computer";
const SESSION_ID_PATH = "esir-v2-session_id";
const TRAINING_MODE_PATH = "esir-v2-training";
const OPERATOR_PATH = "esir-v2-operator";
const SELECTED_LOCATION_PATH = "esir-v2-selected-location";
const APP_VERSION_PATH = "esir-v2-app-version";
const EFACTURE_API_KEY_PATH = "esir-v2-api_key";
const EFACTURE_USER_DATA_PATH = "esir-v2-user_data";
const LPFR_URL_PATH = "esir-v2-lpfr-path";

export const saveLpfrUrl = (url) => {
    localStorage.setItem(LPFR_URL_PATH, url);
}

export const getLpfrUrl = () => {
    return localStorage.getItem(LPFR_URL_PATH)
}

// ----------- eFActure USER DATA -----------------
export const setEFactureUserData = (userData) => {
    localStorage.setItem(EFACTURE_USER_DATA_PATH, JSON.stringify(userData));
};

export const getEFactureUserData = () => {
    const data = localStorage.getItem(EFACTURE_USER_DATA_PATH);
    return data ? JSON.parse(data) : undefined
};
// ----------- eFActure API KEY -----------------
export const setEFactureApiKey = (apiKey) => {
    localStorage.setItem(EFACTURE_API_KEY_PATH, apiKey);
};

export const getEFactureApiKey = () => {
    return localStorage.getItem(EFACTURE_API_KEY_PATH);
};

export const getSessionLanguage = () => {
    return localStorage.getItem(SESSION_LANGUAGE);
};

export const setSessionLanguage = (language) => {
    return localStorage.setItem(SESSION_LANGUAGE, language);
};

// ----------- USER INFO ------------------

export const setSessionUserInfo = (payload) => {
    localStorage.setItem(USER_INFO_PATH, JSON.stringify(payload));
};

// ----------- LOKACIJA -------------------
export function setSessionSelectedLocation(location) {
    localStorage.setItem(SELECTED_LOCATION_PATH, JSON.stringify(location));
}

export function getSessionSelectedLocation() {
    try {
        return localStorage.getItem(SELECTED_LOCATION_PATH)
            ? JSON.parse(localStorage.getItem(SELECTED_LOCATION_PATH))
            : undefined;
    } catch (e) {
        console.error("PUCA PARSIRANJE LOKACIJE", e);
        return undefined;
    }

}

// ----------- OPERATER -------------------

export function setSessionOperator(operator) {
    localStorage.setItem(OPERATOR_PATH, JSON.stringify(operator));
}

export function removeSessionOperator() {
    localStorage.removeItem(OPERATOR_PATH);
}

export function getSessionOperator() {
    return localStorage.getItem(OPERATOR_PATH)
        ? JSON.parse(localStorage.getItem(OPERATOR_PATH))
        : undefined;
}

// ----------- VERZIJA -------------------
export function getSessionAppVersion() {
    return localStorage.getItem(APP_VERSION_PATH)
        ? parseFloat(localStorage.getItem(APP_VERSION_PATH))
        : null;
}

export function setSessionAppVersion(version) {
    localStorage.setItem(APP_VERSION_PATH, version.toString());
}

// ----------- SESIJA -------------------
export function getAndCreateUniqueComputerUid() {
    let id = uuidv4();
    let storedSession = localStorage.getItem(COMPUTER_INDENTITY_PATH);
    if (!storedSession) {
        localStorage.setItem(COMPUTER_INDENTITY_PATH, id);
        return id;
    }
    return storedSession;
}

export function setSessionId(sessionId) {
    localStorage.setItem(SESSION_ID_PATH, sessionId);
}

export function getSessionId() {
    return localStorage.getItem(SESSION_ID_PATH);
}

export function deleteLocalSessionId() {
    localStorage.removeItem(SESSION_ID_PATH);
}

// ----------- OBUKA MOD -------------------
export function setSessionTrainingMode(active) {
    localStorage.setItem(TRAINING_MODE_PATH, active);
}

export function getSessionTrainingMode() {
    return localStorage.getItem(TRAINING_MODE_PATH) ? (localStorage.getItem(TRAINING_MODE_PATH) === "true") : false;
}

// ----------- TEMA -------------------
export function setSessionTheme(theme) {
    localStorage.setItem(THEME_PATH, theme);
}

export function getSessionTheme() {
    let theme = localStorage.getItem(THEME_PATH);
    return theme || "light";
}

// ----------- OSTALO -------------------
export function clearSession(isLogout = false) {
    let itemsToRemove;
    if (isLogout) {
        itemsToRemove = [SELECTED_LOCATION_PATH, TRAINING_MODE_PATH, OPERATOR_PATH];
    } else {
        itemsToRemove = [TRAINING_MODE_PATH, OPERATOR_PATH];
    }
    itemsToRemove.forEach(item => localStorage.removeItem(item));
}
