// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "";
const ROOTS_EFACTURE = ROOTS_DASHBOARD + "/eFacture";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  operatorLogin: path(ROOTS_AUTH, "/operator-login"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/home"),
    csvInvoice: path(ROOTS_DASHBOARD, "/csvInvoice"),
    analytic: path(ROOTS_DASHBOARD, "/analytic"),
    createOrder: path(ROOTS_DASHBOARD, "/create-order"),
    settings: path(ROOTS_DASHBOARD, "/settings")
  },
  compensation: {
    root: path(ROOTS_DASHBOARD, "/compensation"),
    list: path(ROOTS_DASHBOARD, "/compensation/list"),
    editRemove: path(ROOTS_DASHBOARD, "/compensation/edit")
  },
  referrals: {
    root: path(ROOTS_DASHBOARD, "/referrals"),
  },
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`)
  },
  reports: {
    root: path(ROOTS_DASHBOARD, "/reports"),
    overview: path(ROOTS_DASHBOARD, "/reports/overview"),
    item: path(ROOTS_DASHBOARD, "/reports/item"),
    costs: path(ROOTS_DASHBOARD, "/reports/costs"),
    writeOff: path(ROOTS_DASHBOARD, "/reports/writeOff"),
    dpu: path(ROOTS_DASHBOARD, "/reports/dpu"),
    activeOrders: path(ROOTS_DASHBOARD, "/reports/activeOrders"),
    filter: path(ROOTS_DASHBOARD, "/reports/filter"),
    table: path(ROOTS_DASHBOARD, "/reports/table"),
    turnoverWithoutCompensation: path(ROOTS_DASHBOARD, "/reports/turnoverWithoutCompensation"),
    profit: path(ROOTS_DASHBOARD, "/reports/profit"),
    itemCard: path(ROOTS_DASHBOARD, "/reports/item-card"),
    representations: path(ROOTS_DASHBOARD, "/reports/representations")

  },
  database: {
    root: path(ROOTS_DASHBOARD, "/warehouse"),
    list: path(ROOTS_DASHBOARD, "/warehouse/list"),
    addWarehouse: path(ROOTS_DASHBOARD, "/warehouse-add"),
    warehouseLagerItems: path(ROOTS_DASHBOARD, "/warehouse/lager-items")
  },
  warehouse: {
    root: path(ROOTS_DASHBOARD, "/warehouse"),
    suppliers: path(ROOTS_DASHBOARD, "/warehouse/suppliers"),
    newSupplier: path(ROOTS_DASHBOARD, "/warehouse/suppliers/new"),
    editSupplier: path(ROOTS_DASHBOARD, "/warehouse/suppliers/edit"),
    factures: path(ROOTS_DASHBOARD, "/warehouse/factures"),
    newFacture: path(ROOTS_DASHBOARD, "/warehouse/factures/new"),
    editFacture: path(ROOTS_DASHBOARD, "/warehouse/factures/edit"),
    viewFacture: path(ROOTS_DASHBOARD, "/warehouse/factures/view"),
    addPaidAmountFacture: path(ROOTS_DASHBOARD, "/warehouse/factures/addPaidAmountFacture"),
    stockList: path(ROOTS_DASHBOARD, "/warehouse/stock-list"),
    ingredients: {
      root: path(ROOTS_DASHBOARD, "/warehouse/ingredients"),
      list: path(ROOTS_DASHBOARD, "/warehouse/ingredients/list"),
      ingredientRatio: path(ROOTS_DASHBOARD, "/warehouse/ingredients/ingredientRatio")
    },
    editIngredient: path(ROOTS_DASHBOARD, "/warehouse/ingredients/edit"),
    createIngredient: path(ROOTS_DASHBOARD, "/warehouse/ingredients/create"),
    inventory: {
      root: path(ROOTS_DASHBOARD, "/warehouse/inventory"),
      list: path(ROOTS_DASHBOARD, "/warehouse/inventory/list"),
      history: path(ROOTS_DASHBOARD, "/warehouse/inventory/history")
    },
    inventoryPreview: path(ROOTS_DASHBOARD, "/warehouse/inventory/preview"),
    writeOff: path(ROOTS_DASHBOARD, "/warehouse/writeOff"),
    newWriteOff: path(ROOTS_DASHBOARD, "/warehouse/writeOff/new"),
    clients: path(ROOTS_DASHBOARD, "/warehouse/clients"),
    clientsPreview: path(ROOTS_DASHBOARD, "/warehouse/clients/preview")
  },
  items: {
    root: path(ROOTS_DASHBOARD, "/items"),
    list: path(ROOTS_DASHBOARD, "/items/list"),
    new: path(ROOTS_DASHBOARD, "/items/new"),
    starters: path(ROOTS_DASHBOARD, "/items/starters"),
    edit: (id) => path(ROOTS_DASHBOARD, `/items/${id}/edit`)
  },
  discounts: {
    root: path(ROOTS_DASHBOARD, "/discounts"),
    list: path(ROOTS_DASHBOARD, "/discounts/list"),
    new: path(ROOTS_DASHBOARD, "/discounts/new"),
    customers: path(ROOTS_DASHBOARD, "/discounts/customers"),
    customersNew: path(ROOTS_DASHBOARD, "/discounts/customersNew"),
    edit: (uid) => path(ROOTS_DASHBOARD, `/discounts/${uid}/edit`),
    editCustomer: (uid) => path(ROOTS_DASHBOARD, `/discounts/${uid}/editCustomer`)
  },
  operators: {
    root: path(ROOTS_DASHBOARD, "/operators"),
    list: path(ROOTS_DASHBOARD, "/operators/list"),
    new: path(ROOTS_DASHBOARD, "/operators/new"),
    edit: (username) => path(ROOTS_DASHBOARD, `/operators/${username}/edit`)
  },
  customers: {
    root: path(ROOTS_DASHBOARD, "/customers"),
    list: path(ROOTS_DASHBOARD, "/customers/list"),
    new: path(ROOTS_DASHBOARD, "/customers/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/customers/${id.uid}/edit`),
    card: (id) => path(ROOTS_DASHBOARD, `/customers/${id.uid}/card`)
  },
  costs: {
    root: path(ROOTS_DASHBOARD, "/costs"),
    list: path(ROOTS_DASHBOARD, "/costs/list"),
    new: path(ROOTS_DASHBOARD, "/costs/new"),
    edit: (uid) => path(ROOTS_DASHBOARD, `/costs/${uid}/edit`)
  },
  invoices: {
    root: path(ROOTS_DASHBOARD, "/invoices"),
    list: path(ROOTS_DASHBOARD, "/invoices/list"),
    advance: path(ROOTS_DASHBOARD, "/invoices/advance/list"),
    old: path(ROOTS_DASHBOARD, "/invoices/old/list"),
    createOld: path(ROOTS_DASHBOARD, "/invoices/old/new"),
    saved: path(ROOTS_DASHBOARD, "/invoices/saved/list")
  },
  analytics: {
    root: path(ROOTS_DASHBOARD, "/analytics")
  },
  eFacture: {
    root: path(ROOTS_EFACTURE, ""),
    analytics: path(ROOTS_EFACTURE, "/analytics"),
    incomingInvoices: path(ROOTS_EFACTURE, "/incomingInvoices"),
    exitInvoices: path(ROOTS_EFACTURE, "/exitInvoices"),
    exitInvoicesList: path(ROOTS_EFACTURE, "/exitInvoices/list"),
    new: path(ROOTS_EFACTURE, "/new"),
    individualRecords: path(ROOTS_EFACTURE, "/individualRecords"),
    individualRecordsList: path(ROOTS_EFACTURE, "/individualRecords/list"),
    newIndividualRecord: path(ROOTS_EFACTURE, "/newIndividualRecord"),
    editIndividualRecord: path(ROOTS_EFACTURE, "/editIndividualRecord"),
    summaryRecords: path(ROOTS_EFACTURE, "/summaryRecords"),
    summaryRecordsList: path(ROOTS_EFACTURE, "/summaryRecords/list"),
    newSummaryRecord: path(ROOTS_EFACTURE, "/newSummaryRecord")
  }
};
