import {
  getLastInsertedOfflineInvoice, getOfflineInvoiceByInvoiceNumber,
  getOfflineInvoicesByRefNumber,
  putInvoiceOffline, setIsReferencedOnInvoiceOffline,
  storeErrorToOffline
} from "../../store/offlineDb";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where
} from "firebase/firestore";
import {AUTH, DB} from "../../auth/FirebaseContext";
import {getTimestampFromSdc} from "../invoice";
import moment from "moment";
import {INVOICE_TYPE_ARRAY, PAYMENT_TYPE_ARRAY, TRANSACTION_TYPE_ARRAY} from "../../constants";

export function parseInvoice(invoice, formatSdcDate, timestamp) {
  let payments = [];
  if (invoice.paymentMethod !== undefined) {
    invoice.paymentMethod.forEach(value => {
      payments.push({
        paymentType: PAYMENT_TYPE_ARRAY[parseInt(value.paymentType)],
        amount: value.amount
      });
    });
  }
  let transactionType = TRANSACTION_TYPE_ARRAY[parseInt(invoice.transactionType)];
  let invoiceType = INVOICE_TYPE_ARRAY[parseInt(invoice.invoiceType)];
  let sdcDateTime = invoice.sdcDateTime;
  if (formatSdcDate) {
    let date = invoice.sdcDateTime.substring(0, 19);
    sdcDateTime = date.replace("T", " ");
  }
  return {
    ...invoice,
    timestamp: timestamp,
    paymentMethod: payments,
    transactionType: transactionType,
    invoiceType: invoiceType,
    sdcDateTime: sdcDateTime
  };
}

export async function getInvoiceByTimestamp(timestamp) {
  if (!timestamp) {
    return undefined;
  }
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(DB, `invoices/users/${AUTH.currentUser.uid}/${timestamp}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        resolve(parseInvoice({
          ...docSnap.data(),
          timestamp: docSnap.id
        }, false, docSnap.id));
      } else {
        resolve();
      }
    } catch (e) {
      storeErrorToOffline("getInvoiceByTimestamp", "firestore.js",
        [timestamp], e?.toString());
      reject(e);
    }
  });
}

export async function getInvoicesByUserForPeriod(arr, resolve, reject, period) {
  const startDate = period.startDate;
  const invoice = period.foundInvoice;

  if (!startDate || !AUTH.currentUser) {
    return reject();
  }

  let lastDocument = null;
  let hasMoreData = true;

  const batchSize = 50;

  while (hasMoreData) {
    try {
      let q;

      if (lastDocument) {
        q = query(
          collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
          orderBy("sdcDateTime"),
          startAfter(lastDocument),
          limit(batchSize)
        );
      } else {
        q = query(
          collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
          orderBy("sdcDateTime"),
          startAfter(moment(startDate).format("YYYY-MM-DD")),
          limit(batchSize)
        );
      }

      const querySnapshot = await getDocs(q);
      const snapshotSize = querySnapshot.docs.length;

      if (snapshotSize === 0) {
        resolve(arr);
        break;
      }

      for (const doc of querySnapshot.docs) {
        const parsedInvoice = parseInvoice(doc.data(), false, doc.id);
        if (parsedInvoice.invoiceNumber !== invoice.invoiceNumber) {
          arr.push(parsedInvoice);
          await putInvoiceOffline({
            ...parsedInvoice,
            isInsertedOffline: false
          });
        } else {
          hasMoreData = false;
          resolve(arr);
          break;
        }
        lastDocument = doc;
      }
    } catch (e) {
      console.error("getInvoicesByUserForPeriod", e);
      storeErrorToOffline("getInvoicesByUserForPeriod", "firestore.js", [], e?.toString());
      reject(e);
      break;
    }
  }
}

export async function getInvoicesByUser(lastData, arr, resolve, reject, fDayOfPrevMonth) {
  if (!AUTH.currentUser) {
    return reject();
  }

  if (lastData === null) {
    lastData = await getLastInsertedOfflineInvoice();
  }

  try {
    let q;
    if (!lastData) {
      q = await query(
        collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
        orderBy("sdcDateTime"),
        startAfter(fDayOfPrevMonth),
        limit(200)
      );
    } else {
      q = await query(
        collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
        orderBy("sdcDateTime"),
        startAfter(lastData.sdcDateTime),
        limit(200)
      );
    }

    const querySnapshot = await getDocs(q);
    const snapshotSize = querySnapshot.docs.length;

    if (snapshotSize === 0) {
      resolve(arr);
    }

    let i = 0;

    await querySnapshot.forEach((doc) => {
      const parsedInvoice = parseInvoice(doc.data(), false, doc.id);
      putInvoiceOffline({
        ...parsedInvoice,
        isInsertedOffline: false
      });
      arr.push(parsedInvoice);

      if (i === snapshotSize - 1) {
        getInvoicesByUser(parsedInvoice, arr, resolve, reject);
      }

      i += 1;
    });
  } catch (e) {
    console.error("getInvoicesByUser", e);
    storeErrorToOffline("getInvoicesByUserAndLocation", "firestore.js", [], e?.toString());
    reject(e);
  }
}

export async function getInvoicesByReferentDocumentNumber(invoiceNumber) {
  try {
    const offlineInvoices = await getOfflineInvoicesByRefNumber(invoiceNumber);
    if (offlineInvoices && offlineInvoices.length > 0) {
      return offlineInvoices;
    }
    let arr = [];
    const q = query(collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
        where("referentDocumentNumber", "==", invoiceNumber));
    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
      arr.push(parseInvoice(doc.data(), false, doc.id));
    });
    return arr;
  } catch (e) {
    storeErrorToOffline("getInvoicesByReferentDocumentNumber", "firestore.js",
        [invoiceNumber], e?.toString());
    throw e;
  }
}

// Import racuna
export async function getOnlineInvoiceByInvoiceNumber(invoiceNumber, parse = false) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = query(collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
        where("invoiceNumber", "==", invoiceNumber));
      const querySnapshot = await getDocs(q);
      for (const doc of querySnapshot.docs) {
        if (parse) {
          const parsedInvoice = parseInvoice(doc.data(), false, doc.id);
          arr.push(parsedInvoice);
          await putInvoiceOffline({
            ...parsedInvoice,
            isInsertedOffline: false
          });
        } else {
          arr.push({
            ...doc.data(),
            id: doc.id
          });
        }
      }
      resolve(arr[0]);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export async function getOnlineInvoicesByBuyerId(buyerId, parse = false) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = query(collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
        where("buyerTin", "==", buyerId));
      const querySnapshot = await getDocs(q);
      for (const doc of querySnapshot.docs) {
        if (parse) {
          const parsedInvoice = parseInvoice(doc.data(), false, doc.id);
          arr.push(parsedInvoice);
        } else {
          arr.push({
            ...doc.data(),
            id: doc.id
          });
        }
      }
      resolve(arr);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

const PAYMENT_TYPE_TAX = {
  "Other": 0,
  "Cash": 1,
  "Card": 2,
  "Check": 3,
  "WireTransfer": 4,
  "Wire Transfer": 4,
  "Voucher": 5,
  "MobileMoney": 6
};
const validateTaxInvoice = (taxInvoice) => {
  const keys = ["TaxItems", "Items", "InvoiceType", "Cashier", "InvoiceNumber",
    "SDCTime_ServerTimeZone", "TotalAmount"];
  for (const key of keys) {
    if (taxInvoice[key] === undefined) {
      throw new Error(`Neispravan podatak ${key}. Ne može biti prazan!`);
    }
  }
};
const taxSdcDateTimeToTimestamp = (sdcDateTime) => {
  try {
    let split = sdcDateTime.split(" ");
    let date = split[0].split(".");
    let time = split[1].split(":");
    return getTimestampFromSdc(new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]));
  } catch (e) {
    return 123;
  }
};
export const convertInvoiceType = (data) => {
  switch (data) {
    case "Normal":
      return "0";
    case "Proforma":
      return "1";
    case "Copy":
      return "2";
    case "Training":
      return "3";
    case "Advance":
      return "4";
    default:
      throw new Error(`Neispravan 'InvoiceType'. 
      Može biti ("Normal", "Proforma", "Copy", "Training", "Advance")`);
  }
};

const itemsFromTaxCoreToFirebaseItems = (items) => {
  let arr = [];
  for (const item of items) {
    arr.push({
      gtin: item.GTIN,
      labels: item.Labels,
      name: item.Name,
      quantity: Number(Number(item.Quantity).toFixed(3)),
      totalAmount: Number(Number(item.TotalAmount).toFixed(2)),
      unitPrice: Number(Number(item.UnitPrice).toFixed(2))
    });
  }
  return arr;
};

const parsePaymentMethodFromTaxInvoice = (data) => {
  let arr = [];
  if (!data.Payments || !Array.isArray(data.Payments)) {
    throw  new Error("'Payments ne postoji ili nije ispravan!");
  }
  for (const payment of data.Payments) {
    const paymentType = PAYMENT_TYPE_TAX[payment.Type];
    if (paymentType === undefined) {
      throw new Error(`Neispravan json file. 'Payments Type' može biti: 
      ("Other", "Cash", "Card", "Check", "WireTransfer", "Voucher", "MobileMoney")`);
    }
    if (payment.Amount === undefined) {
      throw  new Error("'Payments Amount' new može biti prazan!");
    }
    arr.push({
      paymentType: PAYMENT_TYPE_TAX[payment.Type],
      amount: Number(Number(payment.Amount).toFixed(2))
    });
  }
  return arr;
};

const getFormattedSdcDateAndTime = (sdcDateTime) => {
  try {
    let split = sdcDateTime.split(" ");
    let date = split[0].split(".");
    let time = split[1].split(":");
    return moment(new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]))
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  } catch (e) {
    return 123;
  }
};

const taxItemsFromTaxToFirebaseTaxItems = (taxItems) => {
  return taxItems.map(tax => ({
    amount: tax.Amount,
    categoryName: tax.CategoryName,
    label: tax.Label,
    rate: tax.Rate
  }));
};

export const convertTransactionType = (data) => {
  switch (data) {
    case "Sale":
      return "0";
    case "Refund":
      return "1";
    default:
      throw new Error(`Neispravan 'TransactionType'. Može biti ("Sale", "Refund")`);
  }
};

export async function insertTaxInvoiceIntoFirestore(taxInvoice) {
  validateTaxInvoice(taxInvoice);
  let timestamp = taxSdcDateTimeToTimestamp(taxInvoice.SDCTime_ServerTimeZone);
  const invoice = {
    dateAndTimeOfIssue: taxInvoice.DateAndTimeOfPos || null,
    buyerCostCenter: taxInvoice.BuyersCostCenter || null,
    buyerTin: taxInvoice.BuyerTin || null,
    cashier: taxInvoice.Cashier,
    invoiceNumber: taxInvoice.InvoiceNumber,
    invoiceType: convertInvoiceType(taxInvoice.InvoiceType),
    items: itemsFromTaxCoreToFirebaseItems(taxInvoice.Items),
    paymentMethod: parsePaymentMethodFromTaxInvoice(taxInvoice),
    referentDocumentDT: taxInvoice.referentDocumentDT || null,
    referentDocumentNumber: taxInvoice.referentDocumentNumber || null,
    sdcDateTime: getFormattedSdcDateAndTime(taxInvoice.SDCTime_ServerTimeZone),
    taxItems: taxItemsFromTaxToFirebaseTaxItems(taxInvoice.TaxItems),
    totalAmount: taxInvoice.TotalAmount,
    transactionType: convertTransactionType(taxInvoice.TransactionType)
  };
  await setDoc(doc(DB, "invoices", "users", AUTH.currentUser.uid, timestamp),
    invoice, { merge: true });
  const parsed = parseInvoice(invoice, false, timestamp);
  return await putInvoiceOffline(parsed);
}

export async function getAllInvoicesByOldAdvance(sort = true) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = await query(collection(DB, `invoices/oldInvoices/${AUTH.currentUser.uid}`));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        if (doc.data().invoiceNumber) {
          arr.push(parseInvoice(doc.data(), false, doc.id));
        }
      });
      if (sort) {
        arr = arr.sort((a, b) => moment.unix(a.timestamp / 1000)
          .isBefore(moment.unix(b.timeStamp / 1000)) ? 1 : -1);
      }
      resolve(arr);
    } catch (e) {
      storeErrorToOffline("getInvoicesByOldAdvance", "firestore.js", [], e?.toString());
      reject(e);
    }
  });
}

export async function getOldAdvancedInvoiceByInvoiceNumber(invoiceNumber) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = await query(collection(DB, `invoices/oldInvoices/${AUTH.currentUser.uid}`),
        where("invoiceNumber", "==", invoiceNumber));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push(parseInvoice(doc.data(), false, doc.id));
      });
      resolve(arr[0]);
    } catch (e) {
      storeErrorToOffline("getOldAdvancedInvoiceByInvoiceNumber",
        "firestore/oldAdvanced/index.js", [invoiceNumber], e?.toString());
      reject(e);
    }
  });
}

export async function checkIfOldInvoiceExist(invoiceNumber) {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(collection(DB, `invoices/oldInvoices/${AUTH.currentUser.uid}`),
        where("invoiceNumber", "==", invoiceNumber));
      const querySnapshot = await getDocs(q);
      return resolve(querySnapshot.size);
    } catch (e) {
      storeErrorToOffline("checkIfOldInvoiceExist", "firestore.js", [], e?.toString());
      reject(e);
    }
  });
}


export const setIsReferencedOnInvoice = async (invoiceNumber) => {
  const q = query(collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
    where("invoiceNumber", "==", invoiceNumber));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0];
    const docRef = doc.ref;
    const invoiceData = doc.data();
    const internalData = invoiceData.internalData || {};
    internalData.isReferenced = true;
    await updateDoc(docRef, {
      internalData: internalData
    });
    await setIsReferencedOnInvoiceOffline(invoiceNumber);
  }
}
