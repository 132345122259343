const ru = {
  oldFiscalError: "Unesite IBFM ili XXXXXXXX-br. računa",
  oldFiscalHelp:"IBFM ili XXXXXXXX-br. računa",
  onlyDecimals: "Вы можете ввести только {{number}} десятичных знаков.",
  customerIdentification: "Идентификация клиента",
  customerIdHelper: "Номер ID или другой документ, удостоверяющий личность",
  otherPaymentType: "Другой",
  optionalBuyerInfo: "Необязательное поле клиента",
  closeInvoice: "Закрыть аккаунт",
  cancelInvoice: "Отменить учетную запись",
  issueInvoice: "Выставить счет",
  reportsEFiscal: "Отчеты eFiscal",
  analyticsEFiscal: "Аналитика eFiscal",
  reportEFactures: "Отчеты eFactures",
  exitConfirmTitle: "Вы уверены, что хотите выйти?",
  exitImportProducts: "Все импортированные продукты будут удалены.",
  uid: "UID",
  dragFileOrOpen: "Перетащите файл сюда или нажмите",
  deleteExistingProducts: "Удалить существующие продукты",
  productImport: "Импорт продукции",
  someProductsNotValid: "Некоторые продукты недействительны!",
  fileNotImported: "Файл не импортирован!",
  changeProductOnBillName: "Измените название продукта",
  changeProductOnBillPrice: "Введите новую цену товара",
  changeProductOnBillDiscount: "Введите скидку на товар",
  invoiceTranslate: {
    ba:{
      tax: "Porez",
      name: "Naziv",
      total: "Ukupno",
      tin: "JIB",
      address: "Adresa",
      forPayment: "Za plaćanje",
      price: "Cijena",
      pfrTime: "PFR vrijeme",
      esirTime: "ESIR vrijeme:",
      refTime: "ESIR vrijeme:"
    },
    sr:{
      tax: "Porez",
      name: "Naziv",
      total: "Ukupno",
      tin: "JIB",
      address: "Adresa",
      forPayment: "Za plaćanje",
      price: "Cena",
      pfrTime: "PFR vreme",
      esirTime: "ESIR vreme:",
      refTime: "ESIR vreme:"
    }
  },
  loginHelmet: "Вход | ESIR",
  aboutAppItemsSR: [
    { title: "Производитель:", value: "IT Creator SLTS doo, ul. Krunska 24, 11000 Белград" },
    { title: "Серийный номер:", value: "20220128" },
    { title: "Версия программного обеспечения:", value: "1.0" },
    { title: "Электронная почта для поддержки:", value: "podrska@it-creator.sr" },
    { title: "Номер поддержки:", value: "+381 11 4444 588" },
    { title: "Веб-сайт", value: "www.esir.rs" },
    {
      title: "Полезные ссылки:", value: [
        {
          title: "Руководство пользователя для ESIR WIN Fiscal",
          value: "https://it-creator.sr/download/Korisnicko-uputstvo-za-ESIR-WIN-Fiskal.pdf"
        },
        { title: "Функции ESIR WIN Fiscal", value: "https://it-creator.sr/download/ESIR-WIN_Fiskal_funkcije.pdf" },
        {
          title: "Инструкция по работе с ESIR складом",
          value: "https://it-creator.sr/download/ESIR_Magacin_Uputstvo.pdf"
        },
        {
          title: "Руководство пользователя по выдаче и привязке авансовых счетов ESIR WIN Fiscal",
          value: "https://it-creator.sr/download/" +
            "Korisnicko_uputstvo_za_izdavanje_i_vezivanje_avansnih_računa-ESIR-WIN-Fiskal.pdf"
        }
      ]
    }
  ],
  aboutAppItemsBA: [
    { title: "Производитель:", value: "IT Creator Solutions doo, ul. Sime Šolaje 1A, 78000 Banja Luka" },
    { title: "Серийный номер:", value: "20240401" },
    { title: "Версия программного обеспечения:", value: "1.1" },
    { title: "Электронная почта для поддержки:", value: "podrska@it-creator.ba" },
    { title: "Номер поддержки:", value: "051 981 295" },
    { title: "Веб-сайт", value: "https://elektronskafiskalizacija.ba/" },
    {
      title: "Полезные ссылки:", value: [
        {
          title: "Руководство пользователя для ESIR WIN Fiscal",
          value: "https://it-creator.ba/download/Korisnicko-uputstvo-za-ESIR-WIN-Fiskal.pdf"
        }
      ]
    }
  ],
  loginOperatorHelmet: "Вход оператора | ESIR",
  operatorsNotFound: "Операторы не найдены…",
  compensation: "Компенсация",
  dragOrSelectFile: "Перетащите или выберите файл",
  dragFilesHereOrClick: "Перетащите файлы сюда или нажмите",
  browse: "просмотреть",
  toAddFileMaxSizeIs: "добавить файл максимального размера",
  maxNumberOfImagesIs: "Максимальное количество изображений",
  deleteImage: "Удалить изображение",
  areYouSureYouWantToDeleteImage: "Вы уверены, что хотите удалить изображение?",
  discount: "Скидка",
  compensations: "Компенсации",
  dashboard: "Панель управления",
  factureNumber: "Номер счета-фактуры",
  factureDetails: "Детали счета-фактуры",
  paid: "Оплачено",
  factures: "Счета-фактуры",
  facturePreview: "Предварительный просмотр счета-фактуры",
  allFactures: "Все счета-фактуры",
  allInvoices: "Все счета",
  withCompensation: "С компенсацией",
  warehouse: "Склад",
  byCompensation: "По компенсации",
  unpaid: "Неоплаченный",
  overdue: "Просроченный",
  factureNotFound: "Счет-фактура не найден, попробуйте еще раз!",
  total: "Итого",
  addCompensation: "Добавить компенсацию",
  remainingToPay: "Осталось заплатить",
  invoiceNumber: "Номер счета",
  editExpenses: "Редактировать расходы",
  expense: "Расход",
  save: "Сохранить",
  totalPriceExcludingVAT: "Общая цена без НДС",
  totalPriceIncludingVAT: "Общая цена с НДС",
  discountPerProduct: "Скидка (за продукт)",
  unitPriceExcludingVAT: "Цена за единицу без НДС",
  unitPriceIncludingVAT: "Цена за единицу с НДС",
  priceIsPurchase: "Цена является закупочной",
  rate: "ставка",
  discounts: "Скидки",
  listExpenses: "Список расходов",
  edit: "Редактировать",
  clear: "Очистить",
  close: "Закрыть",
  newUser: "Новый пользователь",
  active: "Активный",
  costLower: "стоимость",
  activateLower: "активировать",
  cardNumber: "Номер карты",
  discountUsers: "Пользователи со скидкой",
  listOfDiscounts: "Список скидок",
  deactivateLower: "деактивировать",
  areYouSureYouWantTo: "Вы уверены, что хотите",
  expenseModification: "Модификация расходов",
  activate: "Активировать",
  totalAmount: "Общая сумма",
  referenceNumber: "Референсный номер",
  statusModification: "Модификация статуса",
  deactivate: "Деактивировать",
  deactivated: "Деактивирован",
  copy: "Копировать",
  details: "Детали",
  training: "Тренинг",
  sale: "Продажа",
  proforma: "Проформа",
  profitExclVAT: " Прибыль без НДС",
  profitInclVAT: " Прибыль с НДС",
  linkedAdvanceInvoices: "Связанные авансовые счета",
  advance: "Аванс",
  cancellation: "Отмена",
  counter: "Счетчик",
  invoice: "Счет",
  receptionDate: "Дата приема",
  issuingTime: "Время выдачи",
  productOrServiceName: "Название продукта или услуги",
  issuanceDate: "Дата выдачи",
  transactionType: "Тип транзакции",
  invoiceType: "Тип счета",
  refund: "Возврат",
  cin: "CIN",
  quotations: "Котировки",
  traffic: "Трафик",
  stockList: "Список запасов",
  invoices: "Счета",
  entrance: "Вход",
  paymentDate: "Дата платежа",
  paymentDateTime: "Дата и время платежа",
  purchasePriceWithoutVAT: "Закупочная цена без НДС",
  purchasePriceWithVAT: "Закупочная цена с НДС",
  purchaseValueWithVAT: "Закупочная стоимость с НДС",
  purchaseValueWithoutVAT: "Закупочная стоимость без НДС",
  sellingPriceWithoutVAT: "Продажная цена без НДС",
  sellingPriceWithVAT: "Продажная цена с НДС",
  sellingValueWithoutVAT: "Продажная стоимость без НДС",
  sellingValueWithVAT: "Продажная стоимость с НДС",
  purchaseValueOfOutputWithoutVAT: "Закупочная стоимость выхода без НДС",
  purchaseValueOfOutputWithVAT: "Закупочная стоимость выхода с НДС",
  outputTaxRate: "Ставка налога на выход",
  lpfr: "LPFR",
  vpfr: "V-PFR",
  addNew: "Добавить новый",
  contactEmails: "Контактные электронные адреса",
  addOrChangeContactEmail: "Добавить/изменить контактный электронный адрес",
  developerMode: "Режим разработчика",
  currentAccount: "Текущий счет",
  virtualKeyboard: "Виртуальная клавиатура",
  invoiceSettings: "Настройки счета",
  trainingModSection: "Раздел обучения",
  rawMaterials: "Сырье",
  inputTaxRate: "Ставка налога на вход",
  selectTaxRate: "Выберите ставку налога!",
  unitPrice: "Цена за единицу",
  lastPurchasePrice: "Последняя закупочная цена",
  currentStock: "Текущий запас",
  unitPriceCalculator: "Калькулятор цены за единицу",
  group: "Группа",
  preview: "Предварительный просмотр",
  cancel: "Отмена",
  delete: "Удалить",
  action: "Действие",
  areYouSureYouWantToDelete: "Вы уверены, что хотите удалить?",
  payments: "Платежи",
  taxCategory: "Налоговая категория",
  taxRate: "Налоговая ставка",
  aboutApp: "О приложении",
  remove: "Удалить",
  addPaymentMethod: "Добавить способ оплаты",
  addPayment: "Добавить платеж",
  paymentMethod: "Способ оплаты",
  totalAmountForPayment: "Общая сумма к оплате",
  removeAll: "Удалить все",
  append: "Добавить",
  totalPaid: "Всего оплачено",
  toPay: "К оплате",
  turnedOff: "Выключен",
  inStock: "В наличии",
  uponExpiration: "По истечении срока",
  notAvailable: "Недоступен",
  notDefined: "Не определен",
  productList: "Список продуктов",
  products: "Продукты",
  areYouSureToDelete: "Вы уверены, что хотите удалить ",
  productsQuestion: "продукт(ы)?",
  code: "Код",
  show: "Показать",
  unit: "Единица",
  category: "Категория",
  state: "Состояние",
  product: "Продукт",
  posted: "Опубликован",
  supplier: "Поставщик",
  paidAmount: "Оплаченная сумма",
  receiptDate: "Дата получения",
  valueDate: "Дата валютирования",
  printing: "Печать",
  base: "База",
  sellingPrice: "Продажная цена",
  printTablePrompt: "Вы хотите распечатать всю или отфильтрованную таблицу?",
  savedInvoices: "Сохраненные счета",
  printingError: "Ошибка печати. Пожалуйста, попробуйте еще раз.",
  errorDeletingInvoice: "Ошибка при удалении счета",
  invoiceDeleted: "Счет удален",
  errorLoadingSavedInvoices: "Ошибка при загрузке сохраненных счетов",
  itemNumber: "Номер позиции",
  finalInvoice: "Окончательный счет",
  quantity: "Количество",
  operator: "Оператор",
  role: "Роль",
  creationDate: "Дата создания",
  fiscalize: "Фискализировать",
  discountUserModification: "Модификация пользователя со скидкой",
  actions: "Действия",
  newDiscountUser: "Новый пользователь со скидкой",
  errorWhileDeleting: "Ошибка при удалении",
  invoiceNotFound: "Счет не найден",
  discountUser: "Пользователь со скидкой",
  creatingDiscountUser: "Создание пользователя со скидкой",
  username: "Имя пользователя",
  amount: "Сумма",
  issuanceTime: "Время выдачи",
  firstNameLastName: "Имя и фамилия",
  enterCardNumbers: "Введите номера карт (нажмите Enter после ввода)",
  status: "Статус",
  unableToDeleteDiscount: "Невозможно удалить эту скидку.",
  phone: "Телефон",
  numberOfProducts: "Количество продуктов",
  contactPerson: "Контактное лицо",
  creatingDiscount: "Создание скидки",
  priceWithDiscount: "Цена со скидкой",
  productOrService: "Продукт/услуга",
  unitOfMeasure: "Единица измерения",
  allowedFormat: "Допустимый формат",
  dateFrom: "Дата с",
  customerType: "Тип клиента",
  invoiceCancellation: "Аннулирование счета",
  enterIdentificationNumber: "Вы должны ввести идентификационный номер!",
  loading: "Загрузка",
  submit: "Отправить",
  final: "Окончательный",
  saved: "Сохраненный",
  dpu: "DPU",
  warehouseManagement: "Управление складом",
  representations: "Представления",
  calculations: "Расчеты",
  nonFeeTransactions: "Безвозмездные операции",
  netQuantity: "Чистый вес",
  oldFiscalization: "Старая фискализация",
  selectOperator: "Выберите оператора",
  creatingIngredient: "Создание ингредиента",
  supplierName: "Название поставщика",
  dateTo: "Дата по",
  logout: "Выйти",
  analytics: "Аналитика",
  salesRecords: "Записи продаж",
  analyticsAndReports: "Аналитика и отчеты",
  ean: "Ean",
  label: "Этикетка",
  initial: "Начальный",
  writeOff: "Списание",
  orderNumber: "Номер заказа",
  deletionTime: "Время удаления",
  changePasswordRequest: "Запрос на смену пароля",
  description: "Описание",
  enterFinalInvoiceDetails: "Введите детали окончательного счета",
  lastAdvanceInvoiceNumber: "Последний номер авансового счета для конкретной операции",
  createInvoiceOldFiscalization: "Создание счета по старой фискализации",
  invoiceCreation: "Создание счета",
  invoiceNumberBuyerVat: "Номер счета \\ Номер НДС покупателя…",
  maximumSizeUpTo: "максимальный размер до",
  productsServices: "Продукты/Услуги",
  purchasePrice: "Закупочная цена",
  enterOrChangeLpfr: "Введите или измените LPFR",
  taxPreview: "Предварительный просмотр налога",
  trainingMode: "Режим обучения",
  turnOnOrOffTrainingMode: "Включить или выключить режим обучения",
  activeTaxRates: "Активные налоговые ставки",
  selectRate: "Выберите ставку",
  saveImage: "Сохранить изображение",
  invoiceAlreadyExists: "Счет с таким номером уже существует",
  totalSumMismatch: "Общая сумма продуктов и способов оплаты не совпадает!",
  enterAtLeastOnePaymentMethod: "Вы должны ввести хотя бы один способ оплаты",
  enterAtLeastOneProduct: "Вы должны ввести хотя бы один продукт",
  operatorModification: "Модификация оператора",
  supplierSuccessfullyDeleted: "Поставщик успешно удален",
  errorWhileLoadingData: "Ошибка при загрузке данных",
  noData: "Нет данных",
  choosePaymentMethods: "Выберите способы оплаты",
  exportImport: "Экспорт/Импорт",
  customerCard: "Карта клиента",
  chooseLocation: "Выберите местоположение",
  itemsOnBill: "Позиции в счете",
  changeQuantity: "Изменить количество",
  productName: "Название продукта",
  saveInDatabase: "Сохранить в базе данных",
  changeProductOnBill: "Изменить продукт в счете",
  enter: "Ввести",
  trafficSale: "Продажа трафика",
  closeDialog: "Закрыть диалог",
  clearAllCategories: "Очистить все категории",
  productNotFound: "Продукт не найден",
  dpuList: "Список DPU",
  dpuForDate: "DPU за дату",
  serialNumber: "Серийный номер",
  dishAndBeverageName: "Название блюда и напитка для потребления на месте",
  transferredQuantity: "Переданное количество",
  procuredQuantity: "Закупленное количество",
  endingStock: "Конечный запас",
  consumedQuantityDuringDay: "Потребленное количество за день (7-8)",
  unitPriceWithVAT: "Цена за единицу с НДС",
  servicesRevenue: "Выручка от услуг (9x10)",
  totalRevenueFromDishesAndBeverages: "Общая выручка от блюд и напитков для потребления на месте (6x10)",
  beveragesRevenue: "Выручка от напитков",
  dishesRevenue: "Выручка от блюд",
  difference: "Разница",
  selectSupplier: "Выберите поставщика",
  inventory: "Инвентарь",
  stockListForDate: "Список запасов на дату",
  cardOverview: "Обзор карты",
  expected: "Ожидаемый",
  measured: "Измеренный",
  listOfCustomers: "Список клиентов",
  averagePurchasePrice: "Средняя закупочная цена",
  totalQuantity: "Общее количество",
  suppliers: "Поставщики",
  bookIt: "Забронировать",
  unBook: "Отменить бронь",
  productCard: "Карта продукта",
  id: "Id",
  split: "Разделить",
  factureWithThisNumberAlreadyExist: "Фактура с таким номером уже существует",
  moreThanRemainingPayment: "Вы ввели большую сумму, чем остаток к оплате",
  supplierHasActiveFactures: "У поставщика есть активные фактуры. Удаление невозможно!",
  openAdvances: "Открытые авансы",
  selectAtLeastOneProduct: "Выберите хотя бы 1 продукт",
  selectDateAndEnterAmount: "Выберите дату и введите сумму по ставке",
  paymentTotalMustMatch: "Общая сумма оплаты должна совпадать с общей суммой",
  choosePaymentMethod: "Выберите способ оплаты",
  youCanOnlyEnterNumbers: "Вы можете ввести только цифры",
  atLeast7Characters: "Должно быть не менее 7 символов",
  exportError: "Ошибка при экспорте. Пожалуйста, попробуйте еще раз.",
  fieldIsRequired: "Поле обязательно для заполнения",
  successfullyModifiedIngredient: "Ингредиент успешно изменен",
  successfullyCreatedIngredient: "Ингредиент успешно создан",
  errorCreatingIngredient: "Ошибка при создании ингредиента",
  errorModifyingIngredient: "Ошибка при изменении ингредиента",
  print: "Печать",
  addingProductService: "Добавление продукта/услуги",
  factureEdit: "Изменение счета-фактуры",
  purchasePriceExclVAT: "Закупочная цена без НДС",
  companyRegistrationNumber: "Регистрационный номер",
  jbkjs: "жбкйс",
  accountant: "Бухгалтер",
  item: "Продукт",
  settings: "Настройки",
  editIngredient: "Изменение ингредиента",
  accountSettings: "Настройки учетной записи",
  myInvoices: "Мои счета-фактуры",
  companyName: "Название компании",
  filteredTable: "Отфильтрованная таблица",
  export: "Экспорт",
  download: "Скачать",
  pibNotFoundInAPR: "ПИБ не найден в АПР.",
  exportTablePrompt: "Хотите ли вы экспортировать всю или отфильтрованную таблицу?",
  supplierWithEnteredPIBExists: "Поставщик с введенным ПИБ уже существует!",
  supplierSuccessfullyModified: "Поставщик успешно изменен",
  supplierSuccessfullyAdded: "Поставщик успешно добавлен",
  enterPIBToFillInData: "Введите ПИБ, чтобы заполнить данные из АПР",
  addAtLeastOneProduct: "Добавьте хотя бы один продукт",
  minimumQuantityIs1: "Минимальное количество - 1",
  invalidDate: "Неверная дата",
  invalidDateFrom: "Неверная дата с",
  invalidDateTo: "Неверная дата по",
  expenses: "Расходы",
  totalBase: "Общая база",
  rawMaterial: "Сырье",
  totalRebate: "Общая скидка",
  ingredientRatio: "Соотношение ингредиентов",
  totalVAT: "Общая сумма НДС",
  writeOffTime: "Время списания",
  forPayment: "К оплате",
  normative: "Норматив",
  addExpense: "Добавить расход",
  addDiscount: "Добавить скидку",
  addIngredient: "Добавить ингредиент",
  addUser: "Добавить пользователя",
  createFacture: "Создать фактуру",
  newIngredient: "Новый ингредиент",
  ingredients: "Ингредиенты",
  ingredient: "Ингредиент",
  addProduct: "Добавить продукт",
  newProduct: "Новый продукт",
  enterProductFirst: "Вы должны сначала ввести продукт",
  enterIngredientFirst: "Вы должны сначала ввести ингредиент",
  enterExpenseFirst: "Вы должны сначала ввести расход",
  reportByFilter: "Отчет по фильтру",
  change: "Изменить",
  publishing: "Включить публикацию",
  select: "Выбрать",
  createdBy: "Создано",
  hide: "Скрыть",
  additionalOptions: "Дополнительные опции",
  newExpense: "Новый расход",
  creatingClientSupplier: "Создание клиента/поставщика",
  expenseCreation: "Создание расхода",
  selectAccounts: "Выберите счета",
  errorOccurred: "Произошла ошибка!",
  clientSupplier: "Клиент/Поставщик",
  clientsCustomers: "Клиенты / Покупатели",
  reports: "Отчеты",
  fullScreen: "Полный экран",
  minimize: "Свернуть",
  syncCompleted: "Завершена синхронизация за выбранный период",
  syncError: "Произошла ошибка при синхронизации",
  owe: "Должен",
  time: "Время",
  searchProduct: "Поиск продукта",
  searchClientSupplier: "Поиск клиента или поставщика",
  date: "Дата",
  table: "Таблица",
  claims: "Претензии",
  balance: "Баланс",
  licenseDuration: "Срок действия лицензии",
  note: "Примечание",
  icons: "Иконки",
  codes: "Коды",
  viewItems: "Просмотреть позиции",
  chooseItemDisplay: "Выберите желаемый способ отображения позиций",
  database: "База данных",
  notValidEmail: "неверный электронный адрес.",
  writtenOffItems: "Списанные позиции",
  editSupplier: "Редактировать поставщика",
  inventoryHistory: "История инвентаризации",
  newClientSupplier: "Новый клиент/поставщик",
  newEditClientSupplier: "Новый/редактировать клиента/поставщика",
  errorLoadingData: "Ошибка при загрузке данных",
  costReport: "Отчет о затратах",
  ingredientNotFound: "Ингредиент не найден, пожалуйста, попробуйте еще раз!",
  passwordUsernameUsedByAnotherOperator: "Этот пароль или имя пользователя уже используется другим оператором.",
  listOfClientsSuppliers: "Список клиентов/поставщиков",
  clientSupplierModification: "Модификация клиента/поставщика",
  selectAtLeastOneAccount: "Выберите хотя бы 1 счет",
  enterSearchParameter: "Введите параметр поиска",
  errorDuringSearch: "Ошибка при поиске!",
  unableToRetrieveGroups: "Не удалось получить группы!",
  unableToRetrieveOperators: "Не удалось получить операторов!",
  totalSalesForThisMonth: "Общий объем продаж за этот месяц",
  totalRefundForThisMonth: "Общая сумма возврата за этот месяц",
  totalEarningsForThisMonth: "Общая прибыль за этот месяц",
  annualTurnover: "Годовой оборот",
  totalSoldFoodBeveragesOther: "Всего продано еды, напитков и прочего",
  food: "Еда",
  remaining: "Остаток",
  createNew: "Создать новый",
  list: "Список",
  itemExample: "Пример позиции",
  notValid: "Недействительный",
  itemName: "Название позиции",
  issueDate: "Дата выдачи",
  priceWithVAT: "Цена с НДС",
  dateAndTime: "Дата и время",
  selectPaymentMethod: "Выберите способ оплаты",
  closeOrMergeAdvanceInvoices: "Закрытие или слияние открытых авансовых счетов",
  selectDateAndAmountPerRate: "Выберите дату и введите сумму по ставке",
  totalAmountMismatch: "Общая сумма по налоговой ставке не совпадает с общей суммой способов оплаты!",
  unableToFindInvoice: "Не удалось найти счет! Свяжитесь со службой поддержки.",
  priceSuccessfullyUpdated: "Цена успешно обновлена",
  errorUpdatingPrice: "Ошибка при обновлении цены!",
  appendingOrIssuingFinal: "Добавление счета или выдача окончательного",
  productViewEdit: "Просмотр/редактирование продукта",
  invoiceOverview: "Обзор счета",
  invalidInvoiceContactSupport: "Счет недействителен. Пожалуйста, свяжитесь со службой поддержки!",
  totalProductAmountCannotBeLess: "Общая сумма продукта не может быть меньше, чем оплаченная сумма!",
  exampleAdvanceInvoiceOldFiscalization: "Пример авансового счета по старой фискализации",
  noAccountsAssociatedWithInvoice: "В настоящее время с этим счетом не связаны никакие счета",
  noInvoices: "Нет счетов",
  newPrice: "Новая цена",
  invoiceList: "Список счетов",
  itemOverviewEdit: "Обзор/редактирование позиции",
  successfullyCompletedCompensation: "Вы успешно завершили компенсацию",
  successfullyRemovedCompensation: "Вы успешно удалили компенсацию",
  tax: "Налог",
  taxExample: "Группа: A Налог: 0%",
  clients: "Клиенты",
  priceEdit: "Редактирование цены",
  exit: "Выход",
  error: "Ошибка",
  confirm: "Подтвердить",
  search: "Поиск",
  tin: "ИНН",
  invoiceFrom: "Счет от",
  paymentCurrency: "Валюта платежа",
  type: "Тип",
  accountNumber: "Номер счета",
  months: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
  beverage: "Напиток",
  other: "Другое",
  cash: "Наличные",
  card: "Карта",
  check: "Чек",
  wireTransfer: "Банковский перевод",
  voucher: "Ваучер",
  mobileMoney: "Мобильные деньги",
  cashier: "Кассир",
  country: "Страна",
  sales: "Продажи",
  top: "Топ",
  email: "Электронная почта",
  address: "Адрес",
  city: "Город",
  user: "Пользователь",
  number: "Номер",
  totalSales: "Общий объем продаж",
  totalRefund: "Общая сумма возврата",
  password: "Пароль",
  addingSupplier: "Добавление поставщика",
  signOut: "Выйти",
  deleting: "Удаление",
  accept: "Принять",
  chosen: "Выбран",
  theme: "Тема",
  new: "Новый",
  old: "Старый",
  youHave: "У вас есть",
  unreadMessages: "непрочитанных сообщений",
  MarkAllAsRead: "Отметить все как прочитанные",
  surname: "Фамилия",
  notifications: "Уведомления",
  showAll: "Показать все",
  jsonFileInterrupted: "Json-файл прерван",
  importSuccess: "Успешный импорт",
  downloadJsonFileErrors: "Скачать файл с ошибками json-объектов",
  importErrors: "Ошибки импорта",
  dragFileHereOrClick: "Перетащите файл сюда или нажмите",
  invoiceImport: "Импорт счетов",
  chooseLightOrDark: "Выберите светлую или темную тему",
  successSaved: "Успешно сохранено",
  passwordResetLinkSentSuccessfully: "Ссылка для сброса пароля успешно отправлена",
  adjustAppearanceInvoice: "Настройте внешний вид своего счета",
  productDontHaveId: "У продукта нет идентификатора",
  startProducts: "Начальные продукты",
  advertisingText: "Рекламный текст",
  printLogo: "Печать логотипа",
  printTestInvoice: "Распечатать образец счета",
  turnOnOrOffVirtualKeyboard: "Включить или выключить виртуальную клавиатуру",
  searchProductsServices: "Поиск продуктов и услуг",
  signIn: "Войти",
  enterFullVATID: "Введите полный номер НДС, чтобы найти нового клиента",
  confirmDelete: "Вы уверены, что хотите удалить?",
  operators: "Операторы",
  addOperator: "Добавить оператора",
  customerList: "Список клиентов",
  listOperators: "Список операторов",
  exampleSignInToThePortal: "Пример входа в портал",
  passwordIsRequired: "Пароль обязателен!",
  invalidCredentials: "Введенные учетные данные недействительны!",
  emailIsRequired: "Электронная почта обязательна!",
  emailFormatValidation: "Адрес электронной почты должен быть в действительном формате!",
  thanLastMonth: " чем в прошлом месяце",
  topSellingProductsForThisMonth: "Самые продаваемые продукты за этот месяц",
  topOperatorsForThisMonth: "Лучшие операторы за этот месяц",
  trafficAnalytics: "Аналитика трафика",
  errorMessage: "Произошла ошибка",
  listOfOperators: "Список операторов",
  addCustomers: "Добавить клиентов",
  newOperator: "Новый оператор",
  customerDatabase: "Клиенты",
  inactive: "Неактивный",
  creatingOperator: "Создание оператора",
  basic: "Базовый",
  name: "Имя",
  all: "Все",
  add: "Добавить",
  price: "Цена",
  profile: "Профиль",
  advertisements: "Реклама",
  categories: "Категории",
  bill: "Счет",
  ppProducts: "продукты",
  noteIsRequired: "Примечание обязательно!",
  printA4: "Печать A4",
  imageMustBeIn169Format: "Изображение должно быть в формате 16:9",
  noSearchResult: "Нет результатов поиска",
  noSearchResultFor: "Нет результатов поиска для",
  pleaseCheckInput: "Пожалуйста, проверьте правильность ввода",
  enterKeyword: "Введите ключевые слова",
  issueAgin: "Выдать снова",
  chooseItems: "Выберите позиции",
  issue: "Выдать",
  forbidden: "Запрещено",
  noAcces: "Нет доступа",
  cinJmbg: "УИН/ИНН",
  choose: "Выбрать",
  searchPages: "Поиск страниц",
  esirNumber: "Номер ESIR",
  issuingPlace: "Место выдачи",
  cannotLoadLocations: "Не удается загрузить местоположения!",
  cannotLoadTaxRates: "Не удается загрузить налоговые ставки!",
  cannotLoadSettings: "Не удается загрузить настройки!",
  cannotLoadBankAccount: "Не удается загрузить банковский счет!",
  cannotLoadUserImage: "Не удается загрузить логотип!",
  cannotLoadAds: "Не удается загрузить рекламу!",
  invoiceSyncError: "Не удается синхронизировать счета!",
  locationSuccessAdded: "Местоположение успешно добавлено!",
  enterNewName: "Введите новое имя",
  enterNewPrice: "Введите новую цену",
  a4Preview: "Предварительный просмотр A4",
  a4Print: "Печать A4",
  company: "Компания",
  location: "Местоположение",
  district: "Район",
  productOrProducts: "продукт/а",
  loadingData: "Загрузка данных",
  discountEdit: "Редактирование скидки",
  deleted: "Удален",
  cannotIssueThisInvoice: "Невозможно выдать этот счет",
  itemsCodeDescription: "(КОЛИЧЕСТВО * КОД ПРОДУКТА)",
  newProductService: "Новый продукт/услуга",
  alreadyHaveCode: "Уже существует продукт/услуга с этим внутренним кодом",
  addNormative: "Добавить норматив",
  sold: "Продано",
  refunded: "Возвращено",
  yearSell: "Годовая продажа",
  contact: "Контакт",
  jbkjc: "ЖБКЙС",
  eFactures: "еФактуры",
  exitInvoices: "Исходящие счета-фактуры",
  newExitInvoice: "Новый исходящий счет-фактура",
  incomingInvoices: "Входящие счета-фактуры",
  individualRecords: "Индивидуальная запись",
  newIndividualRecord: "Новая индивидуальная запись",
  summaryRecords: "Сводная запись",
  newSummaryRecord: "Новая сводная запись",
  customerAlreadyExist: "Уже существует покупатель с этим номером ПИБ/МБ",
  a4Email: "Электронная почта для отображения в формате а4",
  companyInfo: "Детали о компании",
  basicInfo: "Настройки учетной записи",
  saveBasicInfo: "Сохранить настройки учетной записи",
  saveCompanyInfo: "Сохранить настройки о компании",
  yes: "Да",
  no: "Нет",
  overview: "Обзор",
  allF: "Все",
  totalTaxForThisPeriod: "Общий налог за этот период",
  totalBasicForThisPeriod: "Общая основа за этот период",
  editProductService: "Изменение продукта/услуги",
  chooseProductService: "Выберите продукт/услугу",
  reportFor: "Отчет за",
  month: "Месяц",
  year: "Год",
  itemReport: "Отчет о продукте",
  downloadExcel: "Скачать эксель",
  downloadImage: "Скачать изображение",
  dateFromNotValid: "Дата от недействительна",
  dateToNotValid: "Дата до недействительна",
  dateToBeforeFrom: "Дата от больше даты до",
  ingName: "Название ингредиента",
  taxVat: "Налоговая ставка",
  dateOfIssue: "Дата выдачи",
  kitchen: "Кухня",
  bar: "Бар",
  bartender: "Бармен",
  driver: " Водитель",
  parking: " Парковка",
  adminTourist: "Админ Туриста",
  operatorTourist: "Оператор Туриста",
  waiter: "Официант",
  chef: "Повар",
  admin: "Администратор",
  manager: "Менеджер",
  services: "Услуги",
  eFactureAdmin: "еФактуры Админ",
  eFactureExitDocuments: "еФактуры исходящие документы",
  eFactureEnterDocuments: "еФактуры входящие документы",
  eFactureUser: "еФактуры Пользователь",
  eFactureRevisor: "еФактуры Ревизор",
  goods: "Товары",
  units: ["шт", "кг", "км", "г", "л", "тонна", "м2", "м3", "минута", "ч", "день", "месяц", " год", "пара", "сумма", "другое"],
  licenseExpiredMessage: "Ваша лицензия неактивна из-за неуплаты.",
  callTenantMessage: "Пожалуйста, обратитесь к вашему дистрибьютору.",
  mustBeGreaterThen: "Должно быть больше 0",
  oneItemRequired: "Вы должны выбрать хотя бы один продукт",
  productIng: "Продукт/Ингредиент",
  marza: "Маржа",
  ruc: "РУЦ",
  logoutError: "Ошибка при выходе",
  createWriteOff: "Создание списания",
  maxSessionMessage: "Достигнуто максимальное количество активных сессий.",
  file: "Файл",
  alreadyExist: "уже существует!",
  csvInvoice: "Импорт csv счета",
  oneFileRequired: "Вы должны выбрать хотя бы один .csv файл!",
  priceWithDiscountWithoutTax: "Цена со скидкой без НДС",
  noOnState: "Нет в наличии",
  expiration: "По истечении",
  oneOf: "Одна из",
  fileIsNotValid: "Файл недействителен!",
  validate: "Валидировать",
  profit: "Прибыль",
  withTax: "С НДС",
  withoutTax: "Без НДС",
  periodFrom: "Период от",
  period: "Период",
  filter: "Фильтр",
  chooseDates: "Выберите даты",
  chooseMinPeriod: "Выберите меньший период!",
  stateOverview: "Обзор состояния",
  yesterdayOverview: "Вчерашний обзор",
  overviewByDate: "Обзор по дате",
  totalInvoiceNumber: "Общее количество выданных счетов",
  JID: "ЖИД",
  trafficByLocation: "Трафик по локации",
  saleProduct: "Проданные продукты/услуги",
  refundProduct: "Возвращенные продукты/услуги",
  paymentTypes: "Способы оплаты",
  cashiers: "Кассиры",
  taxByVat: "Сумма налога по налоговым ставкам",
  summary: "Суммирование",
  taxBalance: "Сальдо налога",
  totalTaxForRefund: "Общий налог за возврат",
  totalTaxForSale: "Общий налог за продажу",
  totalSaleBalance: "Общий сальдо продажи",
  totalRefundBalance: "Общая сумма возврата",
  totalAmountOfSale: "Общая сумма продаж",
  firmName: "Название фирмы",
  zipCode: "Почтовый индекс",
  reportForPeriod: "Отчет за период",
  to: "до",
  reportDetails: "Детали отчета",
  sellItems: "Проданные товары",
  errorWithExport: "Ошибка при экспорте. Попробуйте еще раз.",
  report: "отчета",
  allReport: "Полный отчет",
  allDetails: "Со всеми деталями",
  partialReport: "Частичный отчет",
  onlyBalance: "Только сводка",
  reportB: "Отчет",
  tehnicalSupport: "Техническая поддержка доступна: ",
  signInTitle: "Войдите в WIN Fiskal",
  welcomeText: "Добро пожаловать в WIN Fiskal - Фискализация, которая вам нужна",
  detailedOn: "Подробнее на: ",
  or: " или ",
  percentage: "Процентно",
  inputDiscount: "Введите скидку в ",
  enterDiscount: "Введите сумму скидки",
  newPriceCannotBeBelovedZero: "Новая цена не может быть ниже нуля!",
  checkOnline: "Проверить онлайн",
  notFound: "Не найден",
  prepTime: "Подготовка",
  qtyForAlarm: "Количество для сигнализации",
  productType: "Тип продукта",
  disableDiscount: "Отключить скидку",
  turnOffProduct: "Выключить продукт",
  productIsNormative: "Продукт является нормативным",
  productHaveNormative: "Продукт имеет норматив",
  addProductNormativeDesc: "Если вы выберете норматив, количество не добавляется для продукта.",
  draft: "Черновик",
  recorded: "Записан",
  replaced: "Заменен",
  cancelled: "Отменен",
  statusChangeDate: "Дата изменения статуса",
  success: "Успешно",
  send: "Отправить",
  russian: "русский",
  serbian: "сербский",
  english: "английский",
  customers: "Клиенты",
  referrals: "Рефералы",
  commingSoon: "вскоре",
  prevousMonthPrecentage: "чем в прошлом месяце",
  lastSaleTransactions: "Недавние сделки купли-продажи",
  trafficExitFactures: "Оборот исходящих счетов",
  trafficEnterFactures: "Оборот входящих счетов",
  permissionDenied: "Доступ запрещен",
  notHavePermission: "Вы не имеете доступа к этой странице",
  back: "Back",
  connect: "Следовать за",
};

export default ru;
