import axios from "axios";
import { LPFR_URL } from "../config";
import { getSessionSelectedLocation } from "../helper/session";

const customAxios = axios.create({
  baseURL: LPFR_URL,
  headers: {
    "Accept": "application/json"
  },
  withCredentials: false
});


export function getStatusLpfr(url) {
  const location = getSessionSelectedLocation();
  let headers = {};
  if (location?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
    headers = {
      "Accept": "application/json",
      "Accept-Language": "sr-Cyrl-BA"
    };
  }
  return customAxios.get("status", {
    baseURL: url,
    headers: headers
  });
}

export function postInvoiceLpfr(data, url) {
  const location = getSessionSelectedLocation();
  let headers = {};
  if (location?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
    headers = {
      "Accept": "application/json",
      "Accept-Language": "sr-Cyrl-BA"
    };
  }
  const sendData = { ...data, options: { ...data.options, OmitQRCodeGen: 0 } };
  return customAxios.post("invoices", sendData, {
    baseURL: url,
    headers: headers
  });
}
