import { AUTH, DB } from "../../../auth/FirebaseContext";
import { collection, getDocs, query, Timestamp, where } from "firebase/firestore";

export async function getAllWrittenOffProductsFromFirestore() {
  let arr = [];
  const q = await query(collection(DB, `writeOff/users/${AUTH.currentUser.uid}`));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    arr.push({
      ...doc.data(),
      uid: doc.id
    });
  });
  return arr;
}

export async function getAllWrittenOffProductsFromFirestoreForDate(startDate, endDate) {
  let arr = [];
  const q = await query(collection(DB, `writeOff/users/${AUTH.currentUser.uid}`),
    where("writeOfTime", ">=", Timestamp.fromDate(startDate)),
    where("writeOfTime", "<=", Timestamp.fromDate(endDate)));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    arr.push({
      ...doc.data(),
      uid: doc.id
    });
  });
  return arr;
}
