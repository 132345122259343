import { format, formatDistanceToNow, getTime } from "date-fns";

export function fDate(date, newFormat) {
  const fm = newFormat || "dd.MM.yyyy";

  return date ? format(new Date(date), fm) : "";
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true
    })
    : "";
}
