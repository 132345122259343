// routes
import { PATH_DASHBOARD } from "../../../routes/path";
// components
import SvgColor from "../../../components/svg-color";
import { ESIR_OPERATORS_GROUPS, OPERATORS_GROUP } from "../../../constants";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;


// ----------------------------------------------------------------------

const ICONS = {
  discount: icon("ic_discount"),
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  eFacture: <Iconify icon="simple-icons:invoiceninja" width={1} />,
  csvInvoice: <Iconify icon="material-symbols:csv" width={1} />
};

const navConfig = (translate) => [
    {
      subheader: translate("dashboard"),
      items: [
        {
          title: translate("dashboard"),
          roles: ESIR_OPERATORS_GROUPS,
          path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard
        },
        {
          title: translate("csvInvoice"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.adminTourist, OPERATORS_GROUP.operatorTourist],
          path: PATH_DASHBOARD.general.csvInvoice, icon: ICONS.csvInvoice
        }
      ]
    },
    {
      subheader: translate("analyticsAndReports"),
      items: [
        {
          title: translate("reportsEFiscal"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist],
          path: PATH_DASHBOARD.reports.root,
          icon: ICONS.invoice,
          children: [
            { title: translate("salesRecords"), path: PATH_DASHBOARD.reports.overview },
            { title: translate("product"), path: PATH_DASHBOARD.reports.item },
            { title: translate("expenses"), path: PATH_DASHBOARD.reports.costs },
            { title: translate("writeOff"), path: PATH_DASHBOARD.reports.writeOff },
            { title: translate("reportByFilter"), path: PATH_DASHBOARD.reports.filter },
            { title: translate("nonFeeTransactions"), path: PATH_DASHBOARD.reports.turnoverWithoutCompensation },
            { title: translate("calculations"), path: PATH_DASHBOARD.reports.profit },
            { title: translate("productCard"), path: PATH_DASHBOARD.reports.itemCard }
            // { title: translate("representations"), path: PATH_DASHBOARD.reports.representations }
          ]
        },
        {
          title: translate("analyticsEFiscal"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist],
          path: PATH_DASHBOARD.analytics.root, icon: ICONS.analytics
        },
        {
          title: translate("reportEFactures"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant,
            OPERATORS_GROUP.manager, OPERATORS_GROUP.eFactureAdmin],
          path: PATH_DASHBOARD.eFacture.analytics,
          icon: ICONS.eFacture
        },
      ]
    },
  {
    subheader: translate("allInvoices"),
    items: [
      {
        title: translate("invoices"),
        roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant,
          OPERATORS_GROUP.manager, OPERATORS_GROUP.adminTourist],
        path: PATH_DASHBOARD.invoices.root,
        icon: ICONS.invoice,
        children: [
          { title: translate("list"), path: PATH_DASHBOARD.invoices.list },
          { title: translate("openAdvances"), path: PATH_DASHBOARD.invoices.advance },
          { title: translate("oldFiscalization"), path: PATH_DASHBOARD.invoices.old },
          { title: translate("saved"), path: PATH_DASHBOARD.invoices.saved }
        ]
      }
    ]
  },
    {
      subheader: translate("warehouseManagement"),
      items: [
        {
          title: translate("compensation"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist],
          path: PATH_DASHBOARD.compensation.root, icon: ICONS.ecommerce
        },
        {
          title: translate("warehouse"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist],
          path: PATH_DASHBOARD.warehouse.root,
          icon: ICONS.booking,
          children: [
            { title: translate("suppliers"), path: PATH_DASHBOARD.warehouse.suppliers },
            { title: translate("factures"), path: PATH_DASHBOARD.warehouse.factures },
            { title: translate("stockList"), path: PATH_DASHBOARD.warehouse.stockList },
            {
              title: translate("normative"), path: PATH_DASHBOARD.warehouse.ingredients.root,
              children: [
                { title: translate("list"), path: PATH_DASHBOARD.warehouse.ingredients.list },
                { title: translate("ingredientRatio"), path: PATH_DASHBOARD.warehouse.ingredients.ingredientRatio }
              ]
            },
            {
              title: translate("inventory"), path: PATH_DASHBOARD.warehouse.inventory.root,
              children: [
                { title: translate("list"), path: PATH_DASHBOARD.warehouse.inventory.list },
                { title: translate("inventoryHistory"), path: PATH_DASHBOARD.warehouse.inventory.history }
              ]
            },
            { title: translate("writeOff"), path: PATH_DASHBOARD.warehouse.writeOff },
            { title: translate("clientsCustomers"), path: PATH_DASHBOARD.warehouse.clients },
            {
              title: translate("expenses"),
              roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
                OPERATORS_GROUP.adminTourist],
              path: PATH_DASHBOARD.costs.root,
              children: [
                { title: translate("listExpenses"), path: PATH_DASHBOARD.costs.list },
                { title: translate("addExpense"), path: PATH_DASHBOARD.costs.new }
              ]
            },
          ]
        }
      ]
    },
    {
      subheader: translate("database"),
      items: [

        {
          title: translate("products"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist],
          path: PATH_DASHBOARD.items.root,
          icon: ICONS.ecommerce,
          children: [
            { title: translate("productList"), path: PATH_DASHBOARD.items.list },
            { title: translate("addProduct"), path: PATH_DASHBOARD.items.new }
          ]
        },

        {
          title: translate("discounts"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist],
          path: PATH_DASHBOARD.discounts.root,
          icon: ICONS.discount,
          children: [
            { title: translate("discounts"), path: PATH_DASHBOARD.discounts.list },
            { title: translate("addDiscount"), path: PATH_DASHBOARD.discounts.new },
            { title: translate("discountUsers"), path: PATH_DASHBOARD.discounts.customers },
            { title: translate("addUser"), path: PATH_DASHBOARD.discounts.customersNew }
          ]
        },
        {
          title: translate("operators"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist],
          path: PATH_DASHBOARD.operators.root,
          icon: ICONS.user,
          children: [
            { title: translate("listOperators"), path: PATH_DASHBOARD.operators.list },
            { title: translate("addOperator"), path: PATH_DASHBOARD.operators.new }
          ]
        },
        {
          title: translate("customerDatabase"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist],
          path: PATH_DASHBOARD.customers.root,
          icon: ICONS.booking,
          children: [
            { title: translate("customerList"), path: PATH_DASHBOARD.customers.list },
            { title: translate("addCustomers"), path: PATH_DASHBOARD.customers.new }
          ]
        }

      ]
    },
    {
      subheader: translate("eFactures"),
      path: PATH_DASHBOARD.eFacture.root,
      items: [
        {
          title: translate("exitInvoices"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist, OPERATORS_GROUP.eFactureAdmin],
          path: PATH_DASHBOARD.eFacture.exitInvoices,
          icon: ICONS.eFacture,
          children: [
            { title: translate("exitInvoices"), path: PATH_DASHBOARD.eFacture.exitInvoicesList },
            { title: translate("newExitInvoice"), path: PATH_DASHBOARD.eFacture.new }
          ]
        },
        {
          title: translate("incomingInvoices"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist, OPERATORS_GROUP.eFactureAdmin],
          path: PATH_DASHBOARD.eFacture.incomingInvoices,
          icon: ICONS.eFacture
        },
        {
          title: translate("individualRecords"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist, OPERATORS_GROUP.eFactureAdmin],
          path: PATH_DASHBOARD.eFacture.individualRecords,
          icon: ICONS.eFacture,
          children: [
            { title: translate("individualRecords"), path: PATH_DASHBOARD.eFacture.individualRecordsList },
            { title: translate("newIndividualRecord"), path: PATH_DASHBOARD.eFacture.newIndividualRecord }
          ]
        },
        {
          title: translate("summaryRecords"),
          roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager,
            OPERATORS_GROUP.adminTourist, OPERATORS_GROUP.eFactureAdmin],
          path: PATH_DASHBOARD.eFacture.summaryRecords,
          icon: ICONS.eFacture,
          children: [
            { title: translate("summaryRecords"), path: PATH_DASHBOARD.eFacture.summaryRecordsList },
            { title: translate("newSummaryRecord"), path: PATH_DASHBOARD.eFacture.newSummaryRecord }
          ]
        }
      ]
    }
  ]
;

export default navConfig;
