import PropTypes from "prop-types";
import {Container, Typography} from "@mui/material";
import MotionContainer from "../components/animate/MotionContainer";
import ForbiddenIllustration from "../assets/illustratuions/ForbiddenIllustration";
import {getSessionOperator} from "../helper/session";
import {useLocales} from "../locales";

RoleBasedGuard.propTypes = {
    children: PropTypes.node,
    hasContent: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.number)
};

export default function RoleBasedGuard({hasContent, roles, children}) {
    const {translate} = useLocales();
    const operator = getSessionOperator();

    if (typeof roles !== "undefined" && !roles.includes(operator?.group)) {
        return hasContent ? (
            <Container component={MotionContainer} sx={{textAlign: "center"}}>
                <Typography variant="h3" paragraph>
                    {translate("forbidden")}
                </Typography>
                <Typography sx={{color: "text.secondary"}}>
                    {translate("noAcces")}
                </Typography>
                <ForbiddenIllustration sx={{height: 260, my: {xs: 5, sm: 10}}}/>
            </Container>
        ) : null;
    }

    return <>{children}</>;
}
