import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH, dbRef, storageRef } from "../../auth/FirebaseContext";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { child, get, set } from "firebase/database";
import { sendPasswordResetEmail } from "firebase/auth";
import { getSessionTrainingMode, setSessionTrainingMode } from "../../helper/session";

export const updateAdvertisementText = createAsyncThunk("updateAdvertisementText", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/others/advertisementText`), data);
});

export const fetchAdvertisementText = createAsyncThunk("fetchAdvertisementText", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/others/advertisementText`))).val();
});

export const fetchContactEmails = createAsyncThunk("fetchContactEmails", async () => {
  let emails = (await get(child(dbRef, `users/${AUTH.currentUser.uid}/protected/contactEmail`))).val();
  if (emails) {
    return emails.split(",");
  }
  return [];
});

export const updateContactEmails = createAsyncThunk("updateContactEmails", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/protected/contactEmail`), data);
});

export const fetchTIN = createAsyncThunk("fetchTin", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/profile/pub/TIN`))).val();
});

export const fetchSettings = createAsyncThunk("fetchSettings", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings`))).val();
});

export const updateSettings = createAsyncThunk("updateSettings", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings`), data);
});

export const insertUserImage = createAsyncThunk("insertUserImage", async (image) => {
  let imageRef = ref(ref(storageRef, "public/userphotos"), `${AUTH.currentUser.uid}.png`);
  return await uploadBytes(imageRef, image);
});

export const fetchUserLogoUrl = createAsyncThunk("fetchUserLogoUrl", async () => {
  const imageRef = ref(ref(storageRef, "public/userphotos"), `${AUTH.currentUser.uid}.png`);
  return await getDownloadURL(imageRef);
});

export const fetchBankAccount = createAsyncThunk("fetchBankAccount", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/bankAccount`))).val();
});

export const updateBankAccount = createAsyncThunk("updateBankAccount", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/bankAccount`), data);
});

export const fetchCompanyInfo = createAsyncThunk("fetchCompanyInfo", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/companyInfo`))).val();
});
export const updateCompanyInfo = createAsyncThunk("updateCompanyInfo", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/companyInfo`), data);
});

export const fetchAccountant = createAsyncThunk("fetchAccountant", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/accountant`))).val();
});

export const updateAccountant = createAsyncThunk("updateAccountant", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/accountant`),
    {
      email: data.email,
      lastName: data.lastName,
      name: data.name,
      telephone: data.telephone || ""
    });
});

export const requestPasswordReset = createAsyncThunk("requestPasswordReset", async () => {
  return await sendPasswordResetEmail(AUTH, AUTH.currentUser.email);
});

const initialState = {
  tin: undefined,
  isTrainingModActive: getSessionTrainingMode(),
  settings: undefined,
  bankNumber: undefined,
  accountant: undefined,
  userImage: null,
  loading: false,
  updating: false,
  uploading: false,
  advertisementText: undefined,
  companyInfo: undefined
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    turnOffTrainingMode: (state) => {
      updateTrainingMode(state, false);
    },
    turnOnTrainingMode: (state) => {
      updateTrainingMode(state, true);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserLogoUrl.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserLogoUrl.fulfilled, (state, { payload }) => {
        state.userImage = payload;
        state.loading = false;
      })
      .addCase(fetchUserLogoUrl.rejected, (state) => {
        state.userImage = undefined;
        state.loading = false;
      })
      .addCase(insertUserImage.pending, (state) => {
        state.uploading = true;
      })
      .addCase(insertUserImage.fulfilled, (state, { payload }) => {
        state.userImage = payload;
        state.uploading = false;
      })
      .addCase(insertUserImage.rejected, (state) => {
        state.uploading = false;
      })
      .addCase(fetchAccountant.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccountant.fulfilled, (state, { payload }) => {
        state.accountant = payload;
        state.loading = false;
      })
      .addCase(fetchAccountant.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateAccountant.fulfilled, (state, { meta }) => {
        state.accountant = meta.arg;
        state.loading = false;
      })
      .addCase(fetchBankAccount.fulfilled, (state, { payload }) => {
        state.bankNumber = payload;
        state.loading = false;
      })
      .addCase(updateBankAccount.fulfilled, (state, { meta }) => {
        state.bankNumber = meta.arg;
        state.loading = false;
      })
      // Add other cases here...
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSettings.fulfilled, (state, { payload }) => {
        state.settings = payload;
        state.loading = false;
      })
      .addCase(fetchSettings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateSettings.fulfilled, (state, { meta }) => {
        state.settings = meta.arg;
      })
      .addCase(fetchTIN.fulfilled, (state, { payload }) => {
        state.tin = payload;
      })
      .addCase(fetchAdvertisementText.fulfilled, (state, { payload }) => {
        state.advertisementText = payload;
      })
      .addCase(updateAdvertisementText.fulfilled, (state, { meta }) => {
        state.advertisementText = meta.arg;
      })
      .addCase(updateCompanyInfo.fulfilled, (state, { meta }) => {
        state.companyInfo = meta.arg;
      })
      .addCase(fetchCompanyInfo.fulfilled, (state, { payload }) => {
        state.companyInfo = payload;
      });
  }
});

function updateTrainingMode(state, isActive) {
  setSessionTrainingMode(isActive);
  state.isTrainingModActive = isActive;
}

export const { turnOffTrainingMode, turnOnTrainingMode } = slice.actions;
// Reducer
export default slice.reducer;
