// @mui
import {useTheme} from "@mui/material/styles";
// hooks
// import { useWidth } from "../../hooks/useResponsive";
// theme
import {remToPx} from "../../theme/typography";

// ----------------------------------------------------------------------

export default function useTypography(variant) {
    const theme = useTheme();

    // const breakpoints = useWidth();

    const key = theme.breakpoints.up("lg");

    const hasResponsive =
        variant === "h1" ||
        variant === "h2" ||
        variant === "h3" ||
        variant === "h4" ||
        variant === "h5" ||
        variant === "h6";

    const getFont =
        hasResponsive && theme.typography[variant][key] ? theme.typography[variant][key] : theme.typography[variant];

    const fontSize = remToPx(getFont.fontSize);

    const lineHeight = Number(theme.typography[variant].lineHeight) * fontSize;

    const {fontWeight, letterSpacing} = theme.typography[variant];

    return {fontSize, lineHeight, fontWeight, letterSpacing};
}
