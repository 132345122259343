import { dispatch, useSelector } from "../../../redux/store";
import { useLayoutEffect } from "react";
import { fetchAllUserLicense } from "../../../redux/slices/license";
import { useSnackbar } from "notistack";
import { useLocales } from "../../../locales";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import Iconify from "../../../components/Iconify";
import moment from "moment/moment";

export default function ActiveLicense() {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const { licenses, loading: loadingLicenses } = useSelector((state => state.license));

  useLayoutEffect(() => {
    if (licenses.length === 0) {
      dispatch(fetchAllUserLicense()).unwrap().catch(reason => {
        console.error("fetchAllUserLicense", reason);
        enqueueSnackbar(translate("errorWhileLoadingData"), { variant: "error" });
      });
    }
  }, [licenses.length]);

  return (
    <Box sx={{ px: 2.5 }}>
      {loadingLicenses ?
        <Skeleton variant="rectangular" width="100%" height={50} /> :
        licenses.map((license, index) =>
          <Stack
            key={`all-license-${index}`}
            direction="row" spacing={3}
            alignItems="center">
            <Iconify width={20} icon="grommet-icons:license" />
            <Stack direction="column">
              <Typography fontWeight="bold" variant="caption" textTransform="uppercase">
                {translateLicense(translate, license.productID)}
              </Typography>
              <Typography variant="caption">{translate("licenseDuration")}: </Typography>
              <Typography
                color={isLicenseValid(license) ? "primary.main" : "error.main"}
                variant="caption">
                {moment(license?.expiredDate.substring(0, 10)).format("DD.MM.YYYY")}
              </Typography>
            </Stack>
          </Stack>
        )
      }
    </Box>
  );
}

const isLicenseValid = (license) => {
  return moment().isSameOrBefore(moment(license?.expiredDate.substring(0, 10)));
};

const translateLicense = (translate, index) => {
  const arr = [translate("lpfr"), translate("web"), translate("eFacture"), translate("restaurant")];
  return arr[index];
};
