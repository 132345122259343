// DENI
import { child, get } from "firebase/database";
import { dbRef } from "../auth/FirebaseContext";
import moment from "moment/moment";

export const LICENSE_EXPIRED_CODE = "75457845";
export const LICENSE_IS_NOT_VALID = "75457841";

export const ONE_DAY_SECONDS_TIMEOUT = 86400000;
export const ONE_MINUTE_SECONDS_TIMEOUT = 60000;

export function dateDiffInMinutes(licenceTime) {
  const a = moment(new Date());
  const b = moment(licenceTime);
  return b.diff(a, "minutes");
}

export const waitSeconds = (timeInMs) => {
  return new Promise(resolve => {
    setTimeout(() => {
      return resolve();
    }, timeInMs);
  });
};

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export const getObjKey = (obj, value) => {
  const val = obj.find(key => key.name === value);
  if (val) {
    return val?.elements[0]?.text || "";
  }
  return null;
};

export const getTotalAmount = (obj, value) => {
  const val = obj.find(key => key.name === value);
  if (val) {
    return val?.elements[5]?.elements[0]?.text || "";
  }
  return null;
};

export async function getClientName(obj, value) {
  const val = obj.find(key => key.name === value);
  if (val) {
    const foundPIB = val.elements[0].elements.find(i => i.name === "cbc:EndpointID").elements[0].text;
    let result;
    if (val?.elements[0]?.elements[1]?.elements[0]?.elements[0]?.text) {
      result = val?.elements[0]?.elements[1]?.elements[0]?.elements[0]?.text;
    } else {
      result = (await get(child(dbRef, `public/businesses/${foundPIB}`))).val().name;

    }
    return result;
  }
  return null;
}

export const findPdf = (obj) => {
  const val = obj.find(key => key.name === "env:DocumentPdf");
  if (val) {
    return val.elements[0].text;
  }
  return null;
};

export const handleDocumentType = (obj, value) => {
  const val = obj.find(key => key.name === value);
  if (val) {
    switch (val?.elements[0]?.text) {
      case "383":
        return "Knjižno zaduženje";
      case "380":
        return "Faktura";
      case "386":
        return "Avans";
      default:
        return "Knjižno odobrenje";
    }
  }
  return "Knjižno odobrenje";
};

export const findAttachment = (data) => {
  const arr = [];
  const pdfbase64 = data.find(obj => obj.name === "cac:AdditionalDocumentReference");
  if (pdfbase64?.elements) {
    if (pdfbase64?.elements[1]?.elements) {
      if (pdfbase64?.elements[1]?.elements[0]?.elements) {
        if (pdfbase64?.elements[1]?.elements[0]?.elements[0]?.text) {
          arr.push(`data:application/pdf;base64,${pdfbase64?.elements[1]?.elements[0]?.elements[0]?.text}`);
        }
      }
    }
  }
  return arr;
};

export const errorConvertMessage = (errorCode) => {

  switch (errorCode) {
    case "EInvoiceNumberDublicate":
      return "Faktura pod ovim brojem već postoji";
    case "UBLOrderNumberLotNumberOrContractNumberIsRequired":
      return "Unesite broj ugovora ili broj okvirnog sporazuma.";
    case "UBLReferenceAndModelNumberFormatNotValid":
      return "Poziv na broj i broj modela nisu ispravni.";
    case "Invalid":
      return "Podaci nisu ispravno uneti.";
    case "UBLPrepaymentInvoiceLineQuanitityNotValid":
      return "Količina proizvoda/usluge mora biti 1.";
    case "UBLPrepaymentInvoiceLineUnitCodeNotValid":
      return "Jedinica mere mora da bude Kom.";
    case "UBLSourceInvoiceNotApproved":
      return "Račun na koji se referencirate treba da bude odobren.";
    case "UnknownError":
      return "Zbog problema u radu SEF-a, došlo je do greške.";
    case "UBLSourceInvoiceReceiverNotSameAsDocumentReceiver":
      return "Izvorni primalac fakture se razlikuje od primaoca dokumenta";
    case "UBLFileNotFound":
      return "Uspešno! Ukoliko se račun ne pojavi u tabeli odmah, osvežite stranicu posle nekog vremena.";
    default:
      return "Greška, proverite da li ste uneli ispravno podatke.";
  }
};

