// routes
import { PATH_DASHBOARD } from "./routes/path";
import { CACHE_SIZE_UNLIMITED } from "firebase/firestore";

// ----------------------------------------------------------------------
export const HOST_API_KEY = "https://europe-central2-esir-44ade.cloudfunctions.net";
export const LPFR_URL = "http://127.0.0.1:8058/api/v3/";
export const TEST_LPFR_URL_BA = "http://devesdc.sandbox.suf.poreskaupravars.org:8888/afe85ebf-2c70-4cf4-b47b-71476b198699/api/v3";
export const FIREBASE_API = {
  apiKey: "AIzaSyBkbGTn5VSCHNuNIpB9YTHTVnCbpv8uaQA",
  authDomain: "esir-44ade.firebaseapp.com",
  projectId: "esir-44ade",
  databaseURL: "https://esir-44ade-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "esir-44ade.appspot.com",
  messagingSenderId: "616435190745",
  appId: "1:616435190745:web:77c3746713ce2c94aaa7c8",
  measurementId: "G-JB89VKRG17",
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  persistence: true
};


// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32
};

export const NAV = {
  W_BASE: 260,
  W_INV_BASE: 460,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 98,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22
};
