import { useEffect, useLayoutEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";

import { Box } from "@mui/material";

import { getAuth, onIdTokenChanged } from 'firebase/auth';
import { child, get } from "firebase/database";

import { dbRef } from "../../auth/FirebaseContext";
import { getSessionAppVersion, setSessionAppVersion } from "../../helper/session";
import { setAllRealtimeDbListeners } from "../../helper/realtimeDatabase/listeners";
import { ALL_LICENSE, ONE_DAY_SECONDS_TIMEOUT, ONE_MINUTE_SECONDS_TIMEOUT } from "../../constants";
import { useLoading } from "../../context/Loading";
import { useLocales } from "../../locales";

import { syncAllInvoices } from "../../redux/slices/synchronization";
import { fetchAllUserLicense, updateIsExpired } from "../../redux/slices/license";
import { fetchAllLocations } from "../../redux/slices/locations";
import { fetchTaxCoreStatus } from "../../redux/slices/taxCore";
import {
  fetchAdvertisementText,
  fetchBankAccount,
  fetchCompanyInfo,
  fetchSettings,
  fetchUserLogoUrl
} from "../../redux/slices/settings";

import Main from "./Main";
import Header from "./header";
import NavVertical from "./nav/NavVertical";
import LicenseExpiredDialog from "../../components/licenseExpiredDialog";
import CacheDialog from "../../components/cacheDialog";
import LoadingModal from "../../components/loading-modal";
import LocationDialog from "../../components/LocationDialog";
import SessionExpiredDialog from "./SessionExpiredDialog";
import {dispatch} from "../../redux/store";

import { useAppSettings } from "../../context/AppSettings";
// ----------------------------------------------------------------------
export default function DashboardLayout() {
  const { loading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { location, setAppLocation } = useAppSettings();

  const { isWebLicenseExpired } = useSelector(((state) => state.license));
  const { settings, userImage, advertisementText, bankNumber, companyInfo } = useSelector(state => state.settings);
  const { currentTaxRates } = useSelector(((state) => state.taxCore));

  const [open, setOpen] = useState(false);
  const [openVersionConfirm, setOpenVersionConfirm] = useState(false);
  const [openLocationDialog, setOpenLocationDialog] = useState(false);
  const [openSessionDialog, setOpenSessionDialog] = useState(false);

  useEffect(() => {
    //fb listeners
    const listeners = setAllRealtimeDbListeners(() => setOpenSessionDialog(true));
    return () => {
      for (const listener of listeners) {
        listener();
      }
    };
  }, []);

  useEffect(() => {
    const auth = getAuth();
    let refreshTimeout;

    const refreshIdToken = async () => {
      const user = auth.currentUser;
      if (user) {
        await user.getIdToken(true);
        scheduleTokenRefresh();
      }
    };

    const scheduleTokenRefresh = () => {
      const user = auth.currentUser;
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const expirationTimeGMT = moment.utc(idTokenResult.expirationTime);

          const currentTimeGMT = moment.utc();

          const refreshTime = expirationTimeGMT.subtract(5, 'minutes');

          const delay = refreshTime.diff(currentTimeGMT);

          if (delay > 0) {
            clearTimeout(refreshTimeout);
            refreshTimeout = setTimeout(refreshIdToken, delay);
          } else {
            console.log('No refresh scheduled, delay is less than 0');
          }
        });
      }
    };


    const unsubscribe = onIdTokenChanged(auth, (user) => {
      if (user) {
        scheduleTokenRefresh();
      }
    });

    return () => {
      clearTimeout(refreshTimeout);
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAllLocations()).unwrap().then(response => {
      if (response.length === 0) {
        return;
      }
      if (response.length === 1) {
        setAppLocation(response[0]);
      } else if (location === undefined) {
        setOpenLocationDialog(true);
      } else {
        let selected = response.some(value => value.PAC === location.PAC);
        if (!selected) {
          setOpenLocationDialog(true);
        }
      }
    }).catch(reason => {
      console.error("fetchAllLocations", reason);
      enqueueSnackbar(translate("cannotLoadLocations"), { variant: "error" });
    });

  }, []);

  useLayoutEffect(() => {
    if (companyInfo === undefined) {
      dispatch(fetchCompanyInfo()).unwrap().catch(reason => {
        console.error("fetchSettings", reason);
        enqueueSnackbar(translate("errorWhileLoadingData"), { variant: "error" });
      });
    }

  }, [companyInfo]);

  useEffect(() => {
    if (currentTaxRates.length === 0) {
      dispatch(fetchTaxCoreStatus()).unwrap().catch(reason => {
        console.error("fetchSettings", reason);
        enqueueSnackbar(translate("cannotLoadTaxRates"), { variant: "error" });
      });
    }

  }, [currentTaxRates.length]);

  useEffect(() => {
    if (!settings) {
      dispatch(fetchSettings()).unwrap().catch(reason => {
        console.error("fetchSettings", reason);
        enqueueSnackbar(translate("cannotLoadSettings"), { variant: "error" });
      });
    }

  }, [settings]);

  useEffect(() => {
    if (!bankNumber) {
      dispatch(fetchBankAccount()).unwrap().catch(reason => {
        console.error("fetchSettings", reason);
        enqueueSnackbar(translate("cannotLoadBankAccount"), { variant: "error" });
      });
    }

  }, [bankNumber]);

  useEffect(() => {
    if (userImage === null) {
      dispatch(fetchUserLogoUrl());
    }

  }, [userImage]);

  useEffect(() => {
    if (advertisementText === undefined) {
      dispatch(fetchAdvertisementText()).unwrap().catch(error => {
        console.error("fetchAdvertisementText", error);
        enqueueSnackbar(translate("cannotLoadAds"), { variant: "error" });
      });
    }

  }, [advertisementText]);

  useLayoutEffect(() => {
    dispatch(syncAllInvoices()).unwrap().catch(reason => {
      console.error("syncAllInvoices", reason);
      enqueueSnackbar(translate("invoiceSyncError"), { variant: "error" });
    });

  }, []);

  useEffect(() => {
    let sessionVersion = getSessionAppVersion();

    get(child(dbRef, `public/webAppData/version`)).then(value => {
      if (sessionVersion === undefined || sessionVersion === null) {
        setSessionAppVersion(value.val());
      } else if (sessionVersion !== value.val()) {
        setOpenVersionConfirm(true);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(fetchAllUserLicense()).unwrap().then(response => {
      let webLicense = response.filter(license => license.productID === ALL_LICENSE.webESIR);
      let isWebLicenseExpired = moment(webLicense[0].expiredDate).isBefore(moment.now());
      if (!isWebLicenseExpired) {
        let timeTrigger = dateDiffInMinutes(webLicense[0].expiredDate);
        if (ONE_DAY_SECONDS_TIMEOUT > timeTrigger * ONE_MINUTE_SECONDS_TIMEOUT) {
          const timer = setTimeout(() => {
            dispatch(updateIsExpired());

          }, timeTrigger * ONE_MINUTE_SECONDS_TIMEOUT);
          return () => clearTimeout(timer);
        }
      }
    });
  }, [isWebLicenseExpired]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  const onLocationChosen = (e, selectedLocation) => {
    setAppLocation(selectedLocation);
    setOpenLocationDialog(false);
    enqueueSnackbar(translate("locationSuccessAdded"), { variant: "success" });
    dispatch(fetchTaxCoreStatus());
  };

  function dateDiffInMinutes(licenceTime) {
    let a = moment(new Date());
    let b = moment(licenceTime);
    return b.diff(a, "minutes");
  }

  return (
    <>
      {loading && <LoadingModal />}
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          height: "100vh",
          display: { lg: "flex" },
          minHeight: { lg: 1 }
        }}>
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>
      {openLocationDialog && <LocationDialog open={openLocationDialog} handleLocationClick={onLocationChosen} />}
      {isWebLicenseExpired && <LicenseExpiredDialog open={isWebLicenseExpired} />}
      {openVersionConfirm && <CacheDialog open={openVersionConfirm} setOpen={setOpenVersionConfirm} />}
      {openSessionDialog && <SessionExpiredDialog open={openSessionDialog} />}
    </>
  );
}
