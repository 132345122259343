import { clearSession, setSessionAppVersion } from "../session";
import { getDownloadURL, ref } from "firebase/storage";
import { productsImageStorage } from "../../auth/FirebaseContext";
import { translate } from "../../locales/i18n";
import { ONLY_TIN } from "../../constants";
import { isEmpty } from "../../utils/other";

export const emptyFunc = () => {
  console.debug("click");
};

export function get_browser() {
  let ua = window.navigator.userAgent, tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: (tem[1] || "") };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [window.navigator.appName, window.navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1]
  };
}

export function sortByDateDescending(array) {
  return array.sort((a, b) => {
    const dateA = new Date(a.sdcDateTime);
    const dateB = new Date(b.sdcDateTime);
    return dateB - dateA;
  });
}

export function clearCache(appVersion) {
  if ("caches" in window) {
    caches.keys().then(async (names) => {
      // Delete all the cache files
      for (const name of names) {
        await caches.delete(name);
      }
    });
  }
  clearSession();
  window.location.reload();
  setSessionAppVersion(appVersion);
}

export function taxValueForLabel(item, currentTaxRates) {
  let tax = 0;
  for (const taxRate of currentTaxRates) {
    const found = taxRate.taxRates.find(ct => ct.label === item.vat);
    if (found) {
      tax = found.rate;
    }
  }
  return tax;
}

export function taxValueForSingleLabel(label, currentTaxRates) {
  let tax = 0;
  for (const taxRate of currentTaxRates) {
    const found = taxRate.taxRates.find(ct => ct.label === label);
    if (found) {
      tax = found.rate;
    }
  }
  return tax;
}

export function getTaxValueByLabel(label, currentTaxRates) {
  let tax = 0;
  for (const taxRate of currentTaxRates) {
    if (taxRate.taxRates[0].label === label) {
      tax = taxRate.taxRates[0].rate;
    }
  }
  return tax;
}

export function calculateBasicOfItem(item, currentTaxRates) {
  let tax = taxValueForLabel(item, currentTaxRates);
  return (parseFloat(item.unitPrice) / (tax * 0.01 + 1)) *
    parseFloat(item.addedQuantity || item.quantity);
}

export function calculateBasic(items, currentTaxRates) {
  let sum = 0;
  for (const item of items) {
    let tax = taxValueForLabel(item, currentTaxRates);
    sum += (parseFloat(item.unitPrice) / (tax * 0.01 + 1)) *
      parseFloat(item.addedQuantity || item.quantity);
  }
  return sum;
}

export function isArrayContainsTax(array, tax) {
  let index = -1;
  if (!array) {
    return index;
  }
  if (array.length === 0) {
    return index;
  }
  for (let i = 0; i < array.length; i++) {
    if (array[i].label === tax.label) {
      index = i;
      return index;
    }
  }
  return index;
}

export function calculatePdv(items, currentTaxRates) {
  let sum = 0;
  for (const item of items) {
    sum += parseFloat(item.unitPrice) *
      parseFloat(item.addedQuantity || item.quantity) - calculateBasicOfItem(item, currentTaxRates);
  }
  return sum;
}

export const findRoleName = (role) => {
  switch (role) {
    case 0:
      return translate("admin");
    case 1:
      return translate("manager");
    case 2:
      return translate("Korisnik");
    case 3:
      return translate("accountant");
    case 4:
      return translate("driver");
    case 5:
      return translate("parking");
    case 6:
      return translate("adminTourist");
    case 7:
      return translate("operatorTourist");
    case 8:
      return translate("chef");
    case 9:
      return translate("waiter");
    case 10:
      return translate("bartender");
    case 31:
      return translate("eFactureAdmin");
    case 32:
      return translate("eFactureExitDocuments");
    case 33:
      return translate("eFactureEnterDocuments");
    case 34:
      return translate("eFactureUser");
    case 35:
      return translate("eFactureRevisor");
    default:
      return translate("other");
  }
};

export const fetchImageUrlAsync = async (uid) => {
  const imageRef = ref(productsImageStorage, `${uid}.png`);
  const downloadURL = await getDownloadURL(imageRef);
  // Fetch the image data as Blob
  let blob;
  try {
    const response = await fetch(downloadURL);
    blob = await response.blob();
  } catch (e) {
  }
  return {
    url: downloadURL,
    blobImage: blob
  };
};

export function getTaxDetailsByLabel(label, currentTaxRates) {
  for (let obj of currentTaxRates) {
    for (let taxRate of obj.taxRates) {
      if (taxRate.label === label) {
        return {
          name: obj.name,
          rate: taxRate.rate,
          label: taxRate.label
        };
      }
    }
  }
  return null;
}

export const getCustomerByBuyerTin = (customers, buyerTin) => {
  if (!buyerTin) {
    return;
  }
  const split = buyerTin.split(":");
  return customers.find(customer => {
    if (split.length === 3) {
      return (customer.taxCorePrefix === Number(split[0]) &&
        customer.jbkjc === split[2] &&
        (customer.jmbg === split[1] || customer.tin === split[1]));
    }
    return customer.taxCorePrefix === Number(split[0]) &&
      (customer.jmbg === split[1] || customer.tin === split[1]);
  });
};

export const TAX_MAPPER = {
  "dž": "Џ",
  "dz": "Џ",
  "DŽ": "Џ",
  "Dz": "Џ",
  "DZ": "Џ",
  "ДЗ": "Џ",
  "Дз": "Џ",
  "đ": "Ђ",
  "dj": "Ђ",
  "Đ": "Ђ",
  "ђ": "Ђ",
  "Dj": "Ђ",
  "DJ": "Ђ",
  "ДЈ": "Ђ",
  "Дј": "Ђ",
  "a": "А",
  "A": "А",
  "b": "Б",
  "B": "Б",
  "c": "Ц",
  "C": "Ц",
  "č": "Ч",
  "Č": "Ч",
  "ć": "Ћ",
  "Ć": "Ћ",
  "d": "Д",
  "D": "Д",
  "e": "Е",
  "E": "Е",
  "f": "Ф",
  "F": "Ф",
  "g": "Г",
  "G": "Г",
  "h": "Х",
  "H": "Х",
  "i": "И",
  "I": "И",
  "j": "Ј",
  "J": "Ј",
  "k": "К",
  "K": "К",
  "l": "Л",
  "L": "Л",
  "lj": "Љ",
  "LJ": "Љ",
  "m": "М",
  "M": "М",
  "n": "Н",
  "N": "Н",
  "nj": "Њ",
  "NJ": "Њ",
  "o": "О",
  "O": "О",
  "p": "П",
  "P": "П",
  "r": "Р",
  "R": "Р",
  "s": "С",
  "S": "С",
  "š": "Ш",
  "Š": "Ш",
  "t": "Т",
  "T": "Т",
  "u": "У",
  "U": "У",
  "v": "В",
  "V": "В",
  "z": "З",
  "Z": "З",
  "Ж": "ж",
  "Ž": "Ж"
};

export const getCustomerIndetification = (customer, isBH = false) => {
  if (isBH) {
    if (!isEmpty(customer.tin) && isEmpty(customer.jbkjc)) {
      return customer.tin;
    } else if (!isEmpty(customer.tin) && !isEmpty(customer.jbkjc)) {
      return `${customer.tin}:${customer.jbkjc}`;
    } else if (!isEmpty(customer.jmbg)) {
      return customer.jmbg;
    } else {
      return customer.otherIndificationNumber;
    }
  }
  const taxCorePrefix = parseInt(customer.taxCorePrefix);
  if (ONLY_TIN.includes(taxCorePrefix)) {
    return `${taxCorePrefix}:${customer.tin}`;
  } else if (taxCorePrefix === 11) {
    return `${taxCorePrefix}:${customer.jmbg}`;
  } else if (taxCorePrefix === 12) {
    return `${taxCorePrefix}:${customer.tin}:${customer.jbkjc}`;
  }
  return `${taxCorePrefix}:${customer.otherIndificationNumber}`;
};
