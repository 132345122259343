import axios from "axios";
import { AUTH } from "../auth/FirebaseContext";
import { getLpfrUrl, getSessionSelectedLocation } from "../helper/session";
import { getStatusLpfr, postInvoiceLpfr } from "./lpfr";
import { customAxios } from "./axiosConfig";
import { insertIntoFirestore } from "../helper/invoice/firestore";
import {
  closeOpenAdvanceInvoice,
  connectOpenAdvanceInvoice,
  createOpenAdvanceInvoice,
  refundOpenAdvanceInvoice
} from "../helper/firestore/openAdvance";
import { TRANSACTION_TYPE } from "../constants";
import { LPFR_URL } from "../config";

const lpfrBaseUrl = async () =>
  new Promise((resolve, reject) => {
    const location = getSessionSelectedLocation();
    if (location?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
      const saved = getLpfrUrl();
      if (saved) {
        return resolve(saved);
      }
      return reject("LPFR nije podesen!")
    }
    return resolve(LPFR_URL);
  })

export async function getTaxCoreStatus(isAlreadyPosted = false) {
  try {
    const responseVpfr = await getStatusFirebaseVpfr();
    return {
      response: responseVpfr.data,
      type: "vpfr"
    };
  } catch (errorVpfr) {
    if (!isAlreadyPosted) {
      try {
        await loginOnError403(errorVpfr);
        const r = await getTaxCoreStatus(true);
        return {
          response: r.response,
          type: r.type
        };
      } catch (loginError) {
        throw loginError;
      }
    } else {
      try {
        const lpfrUrl = await lpfrBaseUrl();
        const responsLpfr = await getStatusLpfr(lpfrUrl);
        return {
          response: responsLpfr.data,
          type: "lpfr"
        };
      } catch (lpfrError) {
        throw lpfrError;
      }
    }
  }
}

async function getStatusFirebaseVpfr() {
  const token = await AUTH.currentUser.getIdToken(true);
  const pac = getSessionSelectedLocation().PAC;
  return customAxios(token, pac).get("/api/v3/status");
}

export async function closeAdvanceInvoice(data, rootInvoice) {
  try {
    const invoice = await insertInvoice(data);
    await insertIntoFirestore(data, invoice);
    await closeOpenAdvanceInvoice(data, invoice, rootInvoice);
    return invoice;
  } catch (e) {
    throw e;
  }
}

export async function connectNewAdvanceInvoice(data, rootInvoice, referentInvoiceTimestamp) {
  try {
    const invoice = await insertInvoice(data);
    await insertIntoFirestore(data, invoice);
    await connectOpenAdvanceInvoice(data, invoice, rootInvoice, referentInvoiceTimestamp);
    return invoice;
  } catch (e) {
    throw e;
  }
}

export async function insertOrRefundAdvanceInvoice(data, realItems, rootInvoice, referentInvoiceTimestamp) {
  try {
    const invoice = await insertInvoice(data);
    await insertIntoFirestore(data, invoice);
    if (data.transactionType === TRANSACTION_TYPE.sale) {
      await createOpenAdvanceInvoice(data, invoice, realItems);
    } else {
      await refundOpenAdvanceInvoice(data, invoice, rootInvoice, referentInvoiceTimestamp);
    }
    return invoice;
  } catch (e) {
    throw e;
  }
}

export async function insertNewInvoice(data) {
  try {
    const invoice = await insertInvoice(data);
    await insertIntoFirestore(data, invoice);
    return invoice;
  } catch (e) {
    throw e;
  }
}

export function insertInvoice(data, isAlreadyPosted = false) {
  return new Promise(async (resolve, reject) => {
    await postInvoiceVpfr(data).then(response => {
      resolve(response.data);
    }).catch(async e => {
      parseInsertInvoiceError(e, reject);
      if (!isAlreadyPosted) {
        await loginOnError403(e).then(_ => {
          insertInvoice(data, true).then(r => {
            resolve(r);
          }).catch(reason => {
            reject(reason)
          })
        });
      } else {
        let lpfrUrl;
        try {
          lpfrUrl = await lpfrBaseUrl();
        } catch (e) {
          return reject(e);
        }
        await postInvoiceLpfr(data, lpfrUrl).then(response => {
          resolve(response.data);
        }).catch(reason => {
          reject(reason);
        });
      }
    });
  });
}

export function postInvoiceVpfr(data) {
  const token = AUTH.currentUser.stsTokenManager.accessToken;
  const pac = getSessionSelectedLocation().PAC;
  return customAxios(token, pac).post("/api/v3/invoices", data);
}

function parseInsertInvoiceError(e, reject) {
  if (e?.response?.status === 400) {
    if (e?.response?.data) {
      reject(e.response.data);
    } else {
      reject(e);
    }
  }
}

async function loginOnError403(e) {

  if (e?.response?.status === 403) {
    try {
      // TODO da li sad treba ovo?

      // const bytes = CryptoJS.AES.decrypt(getLoggedUser(), 'esiresir');
      // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      //await signInWithEmailAndPassword(AUTH, AUTH.currentUser.email, AUTH.password);
    } catch {
      console.error("nije proslo logovanje");
    }
  }
}

export async function getNewReports(from, to) {
  const token = await AUTH.currentUser.getIdToken(true);
  const pac = getSessionSelectedLocation().PAC;
  return axios.get(`https://europe-central2-esir-44ade.cloudfunctions.net/esir/report?from=${from}&to=${to}`, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
      "PAC": pac
    }
  });
}
