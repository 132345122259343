import axios from "axios";
import { AUTH } from "../../auth/FirebaseContext";
import { getEFactureApiKey } from "../../helper/session";

const eFactureAxios = axios.create({
  baseURL: "https://europe-central2-esir-44ade.cloudfunctions.net/efaktura/api/publicApi/", headers: {
    "ApiKey": "",
    "Accept": "text/plain",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});


eFactureAxios.interceptors.request.use(
  (config) => {
    const api_key = getEFactureApiKey();
    if (api_key) {
      config.headers.ApiKey = `${api_key}`;
      config.headers.Authorization = `Bearer ${AUTH.currentUser.stsTokenManager.accessToken}`;
    }
    if (typeof config.data === "string") {
      config.headers = {
        ...config.headers,
        "Authorization": `Bearer ${AUTH.currentUser.stsTokenManager.accessToken}`,
        "Content-Type": "application/xml",
        "Accept": "application/xml"
      };

    }
    return config;
  }, (error) => Promise.reject(error)
);


eFactureAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const statusCode = error.response?.status;
    const errorData = error.response?.data;
    return Promise.reject({ statusCode, errorData });
  }
);
export default eFactureAxios;


