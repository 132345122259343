import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTaxCoreStatus } from "../../api/vpfr";

export const fetchTaxCoreStatus = createAsyncThunk("fetchTaxCoreStatus", async () => {
  return await getTaxCoreStatus();
});

const initialState = {
  vpfrOnline: false,
  lpfrOnline: false,
  lpfrIsPinRequired: false,
  currentTaxRates: [],
  loading: false,
  isPostingNewInvoice: false,
  invoice: undefined,
  insertError: undefined
};

const slice = createSlice({
  name: "taxCore",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxCoreStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTaxCoreStatus.fulfilled, (state, action) => {
        const { response, type } = action.payload;
        if (type === "vpfr") {
          if (!state.vpfrOnline) {
            state.vpfrOnline = true;
            state.lpfrOnline = false;
          }
        } else {
          if (!state.lpfrOnline) {
            state.lpfrOnline = true;
            state.vpfrOnline = false;
          }
          if (state.lpfrIsPinRequired !== response.isPinRequired) {
            state.lpfrIsPinRequired = response.isPinRequired;
          }
        }
        if (state.currentTaxRates.length === 0) {
          state.currentTaxRates = response.currentTaxRates.taxCategories;
        }
        state.loading = false;
      })
      .addCase(fetchTaxCoreStatus.rejected, (state) => {
        state.vpfrOnline = false;
        state.lpfrOnline = false;
        state.loading = false;
      });
  }
});

export default slice.reducer;

