import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { itemsAveragePurchasePrice } from "../../helper/realtimeDatabase";
import moment from "moment";
import { child, get } from "firebase/database";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { getAllFacturesByDateFromFirestore } from "../../helper/firestore/factures";
import { getNewReports } from "../../api/vpfr";
import { parseNewReport } from "../../helper/report/newReport";
import { translate } from "../../locales/i18n";
import { calculateBasicOfItem } from "../../helper/other";
import { calculateMarkUp } from "../../helper/invoice";
import { itemsStateOnDate } from "../../helper/products";
import { getAllWrittenOffProductsFromFirestoreForDate } from "../../helper/firestore/writeOff";

export const fetchStockList = createAsyncThunk("fetchStockList",
  async (data, { getState }) => {
    const state = getState();
    const { currentTaxRates } = state.taxCore;
    const { allItemsList } = state.items;
    const { chosenDate, location } = data;
    let items = [];
    let ingItems = [];
    for (let item of allItemsList) {
      if (Object.keys(item.ingredients || {}).length > 0) {
        ingItems.push({
          ...item,
          type: translate("item")
        });
      } else {
        items.push({
          ...item,
          type: translate("item")
        });
      }
    }
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients`))).forEach((child1) => {
      const ingIndex = ingItems.findIndex(value => value.ingredients[child1.key]);
      if (ingIndex !== -1) {
        items.push({
          ...child1.val(),
          uid: child1.key,
          itemIngUid: ingItems[ingIndex].uid,
          itemIngIndex: ingIndex,
          type: translate("ingredient")
        });
      } else {
        items.push({
          ...child1.val(),
          uid: child1.key,
          type: translate("ingredient")
        });
      }
    });
    //entryQuantity
    let factures = await getAllFacturesByDateFromFirestore(
      moment(chosenDate).set("hour", 0).set("minute", 0).set("second", 0).toDate(),
      moment(chosenDate).set("hour", 23).set("minute", 59).set("second", 59).toDate());
    for (const item of items) {
      if (item.entryQuantity === undefined || item.entryQuantity === null) {
        item.entryQuantity = 0;
      }
      for (const facture of factures) {
        for (const fItem of [...facture.items, ...facture.costs, ...(facture?.ingredients || [])]) {
          if (fItem.uid === item.uid) {
            item.entryQuantity += Number(fItem.quantity);
          }
        }
      }
    }
    const dateFrom = moment(chosenDate).format("YYYY-MM-DD");
    const dateTo = moment(chosenDate).add(1, "day").format("YYYY-MM-DD");
    const result = (await getNewReports(dateFrom, dateTo)).data;
    const report = parseNewReport(result.derived);
    //exitQuantity
    items = items.map(item => {
      let exitQuantity = 0;
      for (const sItem of report.itemsSale) {
        if (sItem.uid && sItem.uid[item.uid]) {
          exitQuantity += Number(sItem.uid[item.uid]);
        } else if (sItem.uid && sItem.uid[item.itemIngUid]) {
          const itemWithIng = ingItems[item.itemIngIndex];
          const ing = itemWithIng.ingredients[item.uid];
          exitQuantity += Number(sItem.uid[item.itemIngUid] * ing.quantity);
        }
      }
      return {
        ...item,
        exitQuantity
      };
    });

    //WriteOff
    const writeOffDateTo = moment(chosenDate).set({
      hour: 23,
      minute: 59,
      second: 59
    });
    const allWriteOff = await getAllWrittenOffProductsFromFirestoreForDate(new Date(dateFrom), writeOffDateTo.toDate());
    for (const writeOff of allWriteOff) {
      const index = items.findIndex(value => value.uid === writeOff.product?.uid);
      if (index !== -1) {
        items[index].exitQuantity -= Number(writeOff.quantity);
      }
    }

    //average purchase price
    let avgItemsPrice = await itemsAveragePurchasePrice(chosenDate);
    let currentItemsState = await itemsStateOnDate(chosenDate, location);
    items = items.map(item => {
      const price = item.price || 0;
      let exitQuantity = item.exitQuantity;
      let entryQuantity = item.entryQuantity;
      // Koristimo stanje koje se nalazi na proizvodu
      let avgPurchasePrice = avgItemsPrice[item.uid] || 0;
      let avgPurchasePriceWithoutTax = calculateBasicOfItem({
        quantity: 1,
        unitPrice: avgPurchasePrice,
        vat: item.vat
      }, currentTaxRates);
      let priceWithoutTax = calculateBasicOfItem({
        quantity: 1,
        unitPrice: item.price,
        vat: item.vat
      }, currentTaxRates);
      let restQuantity = Number(item.quantity);
      let startQuantity = currentItemsState[item.uid] || 0;
      return {
        ...item,
        id: item.uid,
        startQuantity: Number(startQuantity).toFixed(2),
        entryQuantity: Number(entryQuantity).toFixed(2),
        exitQuantity: Number(exitQuantity).toFixed(2),
        restQuantity: Number(restQuantity).toFixed(2),
        marza: (item.price ? Number(calculateMarkUp(avgPurchasePrice, price)).toFixed(2) : 0) + "%",
        ruc: (item.price ? Number(item.price - avgPurchasePrice).toFixed(2) : 0),
        avgPurchasePriceWithoutTax: Number(avgPurchasePriceWithoutTax).toFixed(2),
        avgPurchasePrice: Number(avgPurchasePrice).toFixed(2),
        nabVreSaPdv: Number(avgPurchasePrice * restQuantity).toFixed(2),
        nabVreBezPdv: Number(avgPurchasePriceWithoutTax * restQuantity).toFixed(2),
        price: Number(price).toFixed(2),
        priceWithoutTax: Number(priceWithoutTax).toFixed(2),
        prodVreBezPdv: Number(priceWithoutTax * restQuantity).toFixed(2),
        prodVreSaPdv: Number(price * restQuantity).toFixed(2),
        exitValueWithoutTax: Number(exitQuantity * avgPurchasePriceWithoutTax).toFixed(2),
        exitValueWithTax: Number(exitQuantity * avgPurchasePrice).toFixed(2)
      };
    });
    return items;
  });

const initialState = {
  stockList: [],
  loading: false
};

export const slice = createSlice({
  name: "stockList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStockList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStockList.fulfilled, (state, { payload }) => {
        state.stockList = payload;
        state.loading = false;
      })
      .addCase(fetchStockList.rejected, (state) => {
        state.loading = false;
      });
  }
});

// Reducer
export default slice.reducer;
