import moment from "moment/moment";
import { INVOICE_TYPE, TRANSACTION_TYPE } from "../../constants";
import { translate } from "../../locales/i18n";
import { TAX_MAPPER } from "../other";

export function getTimestampFromSdc(sdcDateTime) {
  try {
    return moment(sdcDateTime).format("x");
  } catch (e) {
    return new Date().toString();
  }
}

export const translatePaymentNumber = (name) => {
  switch (name.toString()) {
    case "1":
      return translate("cash");
    case "2":
      return translate("card");
    case "3":
      return translate("check");
    case "4":
      return translate("wireTransfer");
    case "5":
      return translate("voucher");
    case "6":
      return translate("mobileMoney");
    case "0":
      return translate("other");
    default:
      return;
  }
};

export const translateInvoiceType = (invType) => {
  switch (invType) {
    case "Normal":
      return "Promet";
    case "Advance":
      return "Avansni";
    case "Proforma":
      return "Predracuni";
    case "Copy":
      return "Kopija";
    case "Training":
      return "Obuka";
    default:
      return "Other";
  }
};

export function createAdvanceProductName(vat) {
  if (!vat) {
    return undefined;
  }
  let name;
  switch (vat.toString()) {
    case "F":
      name = "10: Avans (F)";
      break;
    case "E":
      name = "11: Avans (E)";
      break;
    case "T":
      name = "12: Avans (T)";
      break;
    case "A":
      name = "13: Avans (A)";
      break;
    case "Ђ":
      name = "10: Avans (Ђ)";
      break;
    case "Е":
      name = "11: Avans (Е)";
      break;
    case "Г":
      name = "12: Avans (Г)";
      break;
    case "А":
      name = "13: Avans (А)";
      break;
    default:
      name = undefined;
  }
  return name;
}

export function getCounterFromInvoice(invoice) {
  let invoiceNumber = invoice.invoiceNumber;
  let index = invoiceNumber.lastIndexOf("-") + 1;
  let counter = invoiceNumber.substring(index, invoiceNumber.length);
  let firstLetter;
  switch (invoice.invoiceType) {
    case INVOICE_TYPE.training:
      firstLetter = "О";
      break;
    case INVOICE_TYPE.copy:
      firstLetter = "К";
      break;
    case INVOICE_TYPE.normal:
      firstLetter = "П";
      break;
    case INVOICE_TYPE.advance:
      firstLetter = "А";
      break;
    case INVOICE_TYPE.proforma:
      firstLetter = "Р";
      break;
    default:
      firstLetter = "#";
      break;
  }
  let secondLetter = invoice.transactionType === TRANSACTION_TYPE.sale ? "П" : "Р";
  return `${counter}/${firstLetter}${secondLetter}`;
}

export const calculateMarkUp = (purchasePrice, sellPrice) => {
  if (purchasePrice === 0) {
    return 0;
  }
  return ((sellPrice - purchasePrice) / purchasePrice) * 100;
};


const clearCsvString = (string) => {
  try {
    return string.replaceAll("\"", "");
  } catch (e) {
    return undefined;
  }
};

const parseNumber = (string) => {
  try {
    const number = Number(clearCsvString(string));
    return Number.isNaN(number) ? undefined : number;
  } catch (e) {
    return undefined;
  }
};

const parseTax = (tax, labels) => {
  const vat = TAX_MAPPER[clearCsvString(tax)];
  if (labels.some(l => l === vat)) {
    return vat;
  }
  return undefined;
};
const parseCsvItem = (row, labels) => {
  return ({
    name: clearCsvString(row[8]),
    addedQuantity: parseNumber(row[9]),
    unit: clearCsvString(row[10])?.toLowerCase(),
    vat: parseTax(row[11], labels),
    unitPriceWithoutTax: parseNumber(row[12]),
    unitTax: parseNumber(row[13]),
    discountPrecentage: parseNumber(row[14]),
    discountAmount: parseNumber(row[15]),
    priceWithDiscountWithoutTax: parseNumber(row[16]),
    unitPrice: parseNumber(row[12]),
    totalWithoutTax: parseNumber(row[18]),
    totalTax: parseNumber(row[19]),
    total: parseNumber(row[20])
  });
};

export const parseCsvInvoice = (text, currentTaxRates, fileKey, labels) => {
  const lines = text.split("\n");
  const items = [];
  for (let i = 0; i < lines.length; i++) {
    if (i !== 0) {
      let splited = lines[i].split(",");
      if (splited.length > 2) {
        items.push({
          ...parseCsvItem(splited, labels),
          fileKey,
          id: `${fileKey}-${i}`
        });
      }
    }
  }
  return items;
};
