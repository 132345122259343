import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
// slices
import groupsPublicReducer from "./slices/groupsPublic";
import { operatorsReducer } from "./slices/operators";
import suppliersReducer from "./slices/suppliers";
import inventoryReducer from "./slices/inventory";
import stockListReducer from "./slices/stockList";
import ingredientsReducer from "./slices/ingredients";
import taxCoreReducer from "./slices/taxCore";
import notificationsReducer from "./slices/notifications";
import facturesReducer from "./slices/factures";
import writeOffReducer from "./slices/writeOff";
import reportsReducer from "./slices/reports";
import settingsReducer from "./slices/settings";
import locationsReducer from "./slices/locations";
import esirInvoicesReducer from "./slices/esirInvoices";
import synchronizationsReducer from "./slices/synchronization";
import createInvoiceReducer from "./slices/createInvoice";
import openAdvanceReducer from "./slices/openAdvance";
import eFactureInvoicesReducer from "./slices/eFacture/eFactureInvoices";
import eFactureDraftReducer from "./slices/eFacture/eFactureDraft";
import eFacturesGroupsReducer from "./slices/eFacture/eFactureGroups";
import eFactureFinancialAccountsReducer from "./slices/eFacture/eFactureFinancialAccounts";
import eFactureNomenclaturesReducer from "./slices/eFacture/eFactureNomenclatures";
import eFactureVatsReducer from "./slices/eFacture/eFactureVats";
import eFacturePurchaseInvoicesReducer from "./slices/eFacture/eFacturePurchaseInvoices";

import { discountsReducer } from "./slices/discounts";
import { licenseReducer } from "./slices/license";
import { customersReducer } from "./slices/customers";
import { costsReducer } from "./slices/costs";
import { discountCustomerReducer } from "./slices/customersDiscounts";
import { itemsReducer } from "./slices/items";
import { newReportsReducer } from "./slices/newReport";
import { imagesReducer } from "./slices/images";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: []
};

const createInvoicePersistConfig = {
  key: "createInvoice",
  storage,
  keyPrefix: "redux-",
  whitelist: []
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  images: imagesReducer,
  operators: operatorsReducer,
  locations: locationsReducer,
  stockList: stockListReducer,
  ingredients: ingredientsReducer,
  taxCore: taxCoreReducer,
  suppliers: suppliersReducer,
  writeOff: writeOffReducer,
  inventory: inventoryReducer,
  notifications: notificationsReducer,
  factures: facturesReducer,
  reports: reportsReducer,
  synchronizations: synchronizationsReducer,
  newReports: newReportsReducer,
  groupsPublic: groupsPublicReducer,
  esirInvoices: esirInvoicesReducer,
  customers: customersReducer,
  costs: costsReducer,
  items: itemsReducer,
  discounts: discountsReducer,
  license: licenseReducer,
  discountCustomers: discountCustomerReducer,
  openAdvance: openAdvanceReducer,
  eFactureInvoices: eFactureInvoicesReducer,
  eFacturePurchaseInvoices: eFacturePurchaseInvoicesReducer,
  eFactureDraft: eFactureDraftReducer,
  eFacturesGroups: eFacturesGroupsReducer,
  eFactureFinancialAccounts: eFactureFinancialAccountsReducer,
  eFactureNomenclatures: eFactureNomenclaturesReducer,
  eFactureVats: eFactureVatsReducer,
  createInvoice: persistReducer(createInvoicePersistConfig, createInvoiceReducer)
});

export { rootPersistConfig, rootReducer };
