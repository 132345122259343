import {useLayoutEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {alpha} from "@mui/material/styles";
import {Box, CircularProgress, Divider, MenuItem, Stack, Typography} from "@mui/material";
import {PATH_AUTH, PATH_DASHBOARD} from "../../../routes/path";
import {CustomAvatar} from "../../../components/custom-avatar";
import MenuPopover from "../../../components/menu-popover";
import {IconButtonAnimate} from "../../../components/animate";
import {clearSession, getSessionOperator} from "../../../helper/session";
import {findRoleName} from "../../../helper/other";
import {OPERATORS_GROUP} from "../../../constants";
import {useLocales} from "../../../locales";
import {dispatch, useSelector} from "../../../redux/store";
import {deleteOperatorSession} from "../../../redux/slices/operators";
import ActiveLicense from "./ActiveLicense";
import {fetchEsirBalance} from "../../../redux/slices/esirInvoices";
import Iconify from "../../../components/Iconify";
import {useSnackbar} from "notistack";
import {useAppSettings} from "../../../context/AppSettings";

// ----------------------------------------------------------------------

const OPTIONS = (translate, isBH) => {
    const arr = [
        {
            label: translate("dashboard"),
            linkTo: "/"
        },
        {
            label: translate("settings"),
            linkTo: PATH_DASHBOARD.general.settings
        },
    ];
    if (!isBH) {
        arr.push({
            label: translate("referrals"),
            linkTo: PATH_DASHBOARD.referrals.root
        })
    }
    return arr;
}

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const navigate = useNavigate();
    const {IS_BH} = useAppSettings();
    const {enqueueSnackbar} = useSnackbar();
    const operator = getSessionOperator();
    const {translate} = useLocales();
    const [openPopover, setOpenPopover] = useState(null);

    const {balance, loading: loadingBalance} = useSelector((state => state.esirInvoices));

    useLayoutEffect(() => {
        if (balance === undefined) {
            dispatch(fetchEsirBalance()).unwrap().catch(reason => {
                console.error("fetchEsirBalance", reason);
                enqueueSnackbar(translate("errorWhileLoadingData"), {variant: "error"});
            });
        }
    }, [balance]);
    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleLogout = async () => {
        dispatch(deleteOperatorSession());
        clearSession(false);
        navigate(PATH_AUTH.login, {replace: true});
        handleClosePopover();
    };

    const handleClickItem = (path) => {
        handleClosePopover();
        navigate(path);
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpenPopover}
                sx={{
                    p: 0,
                    ...(openPopover && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
                        }
                    })
                }}>
                <CustomAvatar src={operator?.photoURL} alt={operator?.username} name={operator?.username}/>
            </IconButtonAnimate>
            <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{width: 200, p: 0}}>
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap>
                        {operator?.username}
                    </Typography>

                    <Typography variant="body2" sx={{color: "text.secondary"}} noWrap>
                        {findRoleName(operator?.group)}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Iconify width={25} icon="fluent-emoji-flat:balance-scale"/>
                        <Typography variant="caption" fontWeight="bold" noWrap>{translate("balance")}: {loadingBalance ?
                            <CircularProgress size={10}/> : balance || "/"}</Typography>
                    </Stack>
                </Box>
                <Divider sx={{borderStyle: "dashed"}}/>
                {[OPERATORS_GROUP.admin, OPERATORS_GROUP.manager, OPERATORS_GROUP.eFactureAdmin].includes(operator?.group) &&
                    <Stack sx={{p: 1}}>
                        {OPTIONS(translate, IS_BH).map((option) => (
                            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                                {option.label}
                            </MenuItem>
                        ))}
                        <Divider sx={{borderStyle: "dashed"}}/>
                    </Stack>
                }
                <ActiveLicense/>
                <Divider sx={{borderStyle: "dashed"}}/>
                <MenuItem onClick={handleLogout} sx={{m: 1}}>
                    {translate("logout")}
                </MenuItem>
            </MenuPopover>
        </>
    );
}
