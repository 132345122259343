import { getItemImageOffline, updateItemOffline } from "../../store/offlineDb";
import { blobToImage, formatQuantity } from "../../utils/other";
import { fetchImageUrlAsync } from "../other";
import NoImagePhoto from "../../assets/photos/Image_not_available.png";
import { translate } from "../../locales/i18n";
import moment from "moment";
import { getAllFacturesByEndDateFromFirestore } from "../firestore/factures";
import { getNewReports } from "../../api/vpfr";

export const QUANTITY_STATUS = (translate) => {
  return ({
    inStock: {
      value: 0,
      check: (qty) => qty >= 25,
      label: translate("inStock")
    },
    uponExpiration: {
      value: 1,
      check: (qty) => qty < 25,
      label: translate("uponExpiration")
    },
    notAvailable: {
      value: 2,
      check: (qty) => qty <= 0,
      label: translate("notAvailable")
    },
    notDefined: {
      value: 3,
      check: (qty) => qty === null || qty === undefined,
      label: translate("notDefined")
    }
  });
};

export const quantityToString = (qty) => {
  if (qty === null || qty === undefined) {
    return translate("notDefined");
  }
  if (qty <= 0) {
    return translate("noOnState");
  } else if (qty < 25) {
    return translate("expiration");
  } else {
    return translate("inStock");
  }
};

export const getProductImage = async (uid) => {
  try {
    let offlineItem = await getItemImageOffline(uid);
    if (offlineItem) {
      if (offlineItem.image !== undefined && offlineItem.image !== false) {
        return blobToImage(offlineItem.image);
      }
    }
    const onlineImage = await fetchImageUrlAsync(uid);
    await updateItemOffline({
      uid: uid,
      image: onlineImage.blobImage
    });
    return onlineImage.url;
  } catch (e) {
    await updateItemOffline({
      uid: uid,
      image: false
    });
  }
  return NoImagePhoto;
};

export const parseWeightBarCode = (text, products) => {
  let parsedCode = text.substring(2, 7).replace("^0+(?!$)", "");
  let filtered = products.filter(item => item.code === parsedCode);
  let quantity = formatQuantity(Number(text.substring(7, 12)) / 1000);
  if (!filtered[0]) {
    return undefined;
  } else {
    return {
      product: filtered[0],
      quantity: quantity
    };
  }
};

export const searchProductByQuery = (products, query) => {
  if (query === "") {
    return products[0];
  }
  let item = products.find(item => `${item.code}` === `${query}`);
  if (!item) {
    item = products.find(item => `${item.ean}` === `${query}`);
  }
  if (!item) {
    item = products.find(item =>
      item.name && item.name.toLowerCase().includes(`${query}`.toLowerCase()));
  }
  return item;
};

export async function itemsStateOnDate(date, location) {
  try {
    let dateForBefore = moment(date).set("hour", 0).set("minute", 0).set("second", 0);
    let facturesBefore = await getAllFacturesByEndDateFromFirestore(dateForBefore.toDate());
    const result = (await getNewReports("2022-01-01",
      dateForBefore.format("YYYY-MM-DD"))).data;
    let allItems = {};
    let forProcessing = result.derived || {};
    if (location) {
      forProcessing = {
        [location]: result.derived[location] || {}
      };
    }
    for (const JID of Object.keys(forProcessing)) {
      for (const itemKey of Object.keys(forProcessing[JID].itemsSale)) {
        if (forProcessing[JID].itemsSale[itemKey].uid) {
          for (const itemUid of Object.keys(forProcessing[JID].itemsSale[itemKey].uid)) {
            if (allItems[itemUid] === undefined) {
              allItems[itemUid] = -(forProcessing[JID].itemsSale[itemKey].uid[itemUid]);
            } else {
              allItems[itemUid] -= forProcessing[JID].itemsSale[itemKey].uid[itemUid];
            }
          }
        }
      }
    }
    for (const facture of facturesBefore) {
      for (const item of facture.items) {
        allItems[item.uid] = allItems[item.uid] ? allItems[item.uid] + Number(item.quantity) : Number(item.quantity);
      }
    }
    return allItems;
  } catch (e) {
    console.error("itemsStateOnDate", e);
    return {};
  }
}
