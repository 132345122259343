import {useEffect} from "react";
import Dexie from "dexie";
import Router from "./routes";
import ThemeProvider from "./theme";
import {ThemeSettings} from "./components/settings";
import SnackbarProvider from "./components/snackbar";
import {createDB} from "./store/offlineDb";
import {LoadingProvider} from "./context/Loading";
import {OFFLINE_DB_NAME} from "./constants/OfflineDb";
import Scrollbar from "./components/Scrollbar";
import { AppSettingsProvider } from "./context/AppSettings";

// TODO onaj alert kao na starom esiru
// TODO izbaciti moment, moment-timezone

function App() {

    useEffect(() => {
        Dexie.exists(OFFLINE_DB_NAME).then(exists => {
            if (!exists) {
                createDB();
            }
        }).catch(reason => {
            console.error(reason);
        });
    }, []);

    return (
      <AppSettingsProvider>
          <ThemeProvider>
              <ThemeSettings>
                  <SnackbarProvider>
                      <LoadingProvider>
                          <Scrollbar sx={{maxHeight: "100vh"}}>
                              <Router/>
                          </Scrollbar>
                      </LoadingProvider>
                  </SnackbarProvider>
              </ThemeSettings>
          </ThemeProvider>
      </AppSettingsProvider>
    );
}

export default App;
