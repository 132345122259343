import { createSlice } from "@reduxjs/toolkit";
import eFactureAxios from "../../../api/eFacture/axios";
import { dispatch } from "../../store";

function objFromArray(array, key = "Code") {
  try{
    return array.reduce((accumulator, current) => {
      accumulator[current[key]] = current;
      return accumulator;
    }, {});
  }catch (e) {
    console.error("e", e)
    return 0
  }
}

function taxFilter(obj, prop = "Category") {
  return obj.reduce((acc, item) => {
    let key = item[prop];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  unitMeasures: { byId: {}, allIds: [] },
  labels: [],
  taxes: { byId: {}, allIds: [] },
  valueAddedTaxExemptionReasonList: []
};

const slice = createSlice({
  name: "eFactureNomenclatures",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },

    // GET UNIT MEASURES
    getUnitMeasuresSuccess(state, { payload }) {
      const measures = payload;
      state.isLoading = false;
      state.labels = measures;
      state.unitMeasures.byId = objFromArray(measures);
      state.unitMeasures.allIds = Object.keys(state.unitMeasures.byId);
    },
    getValueAddedTaxExemptionReasonList(state, { payload }) {
      const taxes = payload;
      state.isLoading = false;
      state.taxes.byId = taxFilter(taxes);
      state.taxes.allIds = ["S", ...Object.keys(state.taxes.byId)];

      // state.isLoading = false;
      // state.valueAddedTaxExemptionReasonList = payload;
    }
  }
});

// Reducer
export default slice.reducer;


export function getUnitMeasures() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eFactureAxios.get("/get-unit-measures");
      dispatch(slice.actions.getUnitMeasuresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getValueAddedTaxExemptionReasonList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eFactureAxios.get("/sales-invoice/getValueAddedTaxExemptionReasonList");
      dispatch(slice.actions.getValueAddedTaxExemptionReasonList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
