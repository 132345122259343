import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { child, get, push, update } from "firebase/database";
import { AUTH, dbRef } from "../../auth/FirebaseContext";

export const fetchCostGroups = createAsyncThunk("fetchCostGroups", async () => {
  return (await get(child(dbRef, `/public/categories/costs`))).val();
});
export const fetchAllCosts = createAsyncThunk("fetchAllCosts", async () => {
  const costs = [];
  (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs`))).forEach(cost => {
    costs.push({
      ...cost.val(),
      uid: cost.key
    });
  });
  return costs;
});
export const addNewCost = createAsyncThunk("addNewCost", async (payload) => {
  delete payload.avatarUrl;
  const costKey = await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs`),
    { ...payload, inactive: false });
  return { ...payload, uid: costKey.key };
});

export const updateCost = createAsyncThunk("updateCost", async (payload) => {
  delete payload.avatarUrl;
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs/${payload.uid}`),
    { ...payload, inactive: false });
  return { ...payload, inactive: false };
});

export const deleteCost = createAsyncThunk("deleteCost", async (payload) => {
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs/${payload.uid}`),
    { ...payload, inactive: !payload.inactive });
  return {
    ...payload,
    inactive: !payload.inactive
  };
});


const initialState = {
  costsList: [],
  groups: [],
  cost: undefined,
  loadingCosts: false
};

export const costsSlice = createSlice({
  name: "costs",
  initialState,
  reducers: {
    addCostToRedux: (state, { payload }) => {
      const arr = [...current(state.costsList)];
      arr.push(payload);
      state.costsList = arr;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCostGroups.fulfilled, (state, { payload }) => {
        state.groups = payload;
      })
      .addCase(fetchAllCosts.pending, (state) => {
        state.loadingCosts = true;
      })
      .addCase(fetchAllCosts.fulfilled, (state, action) => {
        state.costsList = action.payload;
        state.loadingCosts = false;
      })
      .addCase(fetchAllCosts.rejected, (state) => {
        state.loadingCosts = false;
      })
      .addCase(addNewCost.pending, (state) => {
        state.loadingCosts = true;
      })
      .addCase(addNewCost.fulfilled, (state, { payload }) => {
        const arr = [...current(state.costsList)];
        arr.push(payload);
        state.costsList = arr;
        state.loadingCosts = false;
      })
      .addCase(addNewCost.rejected, (state) => {
        state.loadingCosts = false;
      })
      .addCase(updateCost.pending, (state) => {
        state.loadingCosts = true;
      })
      .addCase(updateCost.fulfilled, (state, { payload }) => {
        const arr = [...current(state.costsList)];
        const index = arr.findIndex(i => i.uid === payload.uid);
        if (index !== -1) {
          arr[index] = payload;
          state.costsList = arr;
        }
        state.loadingCosts = false;
      })
      .addCase(updateCost.rejected, (state) => {
        state.loadingCosts = false;
      })
      .addCase(deleteCost.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCost.fulfilled, (state, { payload }) => {
        const arr = [...current(state.costsList)];
        const index = arr.findIndex(i => i.uid === payload.uid);
        if (index !== -1) {
          arr[index] = payload;
          state.costsList = arr;
        }
        state.loading = false;
      })
      .addCase(deleteCost.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const { addCostToRedux } = costsSlice.actions;
export const costsReducer = costsSlice.reducer;
