import { child, get, increment, set, update } from "firebase/database";
import { storeErrorToOffline, updateItemOffline, updateQuantityOffline } from "../../store/offlineDb";
import { getAllFacturesByEndDateFromFirestore } from "../firestore/factures";
import moment from "moment";
import { CHANGE_ITEM_QUANTITY } from "../../constants";
import {analyticsFB, AUTH, dbRef} from "../../auth/FirebaseContext";
import { addMessage } from "../../redux/slices/notifications";
import { dispatch } from "../../redux/store";
import { formatQuantity } from "../../utils/other";
import { changeReduxItemQuantity } from "../../redux/slices/items";
import { changeReduxIngQuantity } from "../../redux/slices/ingredients";
import {logEvent} from "firebase/analytics";

export async function changeIngQuantity(uid, quantity, operation) {
  try {
    let totalQty = 0;
    if (operation === CHANGE_ITEM_QUANTITY.reduce) {
      totalQty = -formatQuantity(quantity);
    } else {
      totalQty += formatQuantity(quantity);
    }
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${uid}`), {
      quantity: increment(totalQty)
    });
    dispatch(changeReduxIngQuantity({
      uid: uid,
      quantity: totalQty
    }));
    await updateQuantityOffline(uid, totalQty, "ingrediants");
  } catch (e) {
    console.error("changeIngQuantity", e);
    storeErrorToOffline("chngeIngQuantity", "helper/realtimeDatabase/index.js", [uid, quantity,
      operation], e?.toString());
  }
}

export async function changeCostQuantity(uid, quantity, operation) {
  try {
    let totalQty = 0;
    if (operation === CHANGE_ITEM_QUANTITY.reduce) {
      totalQty = -formatQuantity(quantity);
    } else {
      totalQty += formatQuantity(quantity);
    }
    await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs/${uid}`), {
      quantity: increment(totalQty)
    });
    await updateQuantityOffline(uid, totalQty, "costs");
  } catch (e) {
    console.error("changeCostQuantity", e);
    storeErrorToOffline("chngeCostQuantity", "helper/realtimeDatabase/index.js", [uid, quantity,
      operation], e?.toString());
  }
}

export async function changeItemQuantity(uid, quantity, operation) {
  if (uid === undefined || uid === null) {
    return;
  }
  try {
    let item = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`))).val();
    item = {
      ...item,
      uid: uid
    };
    if (item.ingredients) {
      await changeItemIngredientsQty(item, quantity, operation);
    } else {
      let itemQuantity;
      if (item.quantity) {
        itemQuantity = operation === CHANGE_ITEM_QUANTITY.reduce ?
          Number(Number(item.quantity) - Number(parseFloat(quantity).toFixed(3)))
          : Number(Number(item.quantity) + Number(parseFloat(quantity).toFixed(3)));
      } else {
        itemQuantity = quantity;
      }
      await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`), {
        ...item,
        quantity: itemQuantity
      });
      dispatch(changeReduxItemQuantity({
        uid: uid,
        quantity: itemQuantity
      }));
      await updateItemOffline({
        ...item,
        quantity: itemQuantity
      });
      addAlertMessage(item, itemQuantity, true);
    }
  } catch (e) {
    console.error("changeItemQuantity", e);
    storeErrorToOffline("changeItemQuantity", "helper/realtimeDatabase/index.js", [uid, quantity,
      operation], e?.toString());
  }
}

async function changeItemIngredientsQty(item, quantity, operation) {
  let ingredients = item.ingredients;
  for (const ingredientUid of Object.keys(item.ingredients)) {
    let needForMakeQty = ingredients[ingredientUid].quantity;
    let ingItem = await
      (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${ingredientUid}`))).val();
    let ingQty;
    if (operation === CHANGE_ITEM_QUANTITY.reduce) {
      ingQty = parseFloat((Number(ingItem.quantity) - (Number(quantity) *
        Number(parseFloat(needForMakeQty))).toFixed(3)));
    } else {
      ingQty = Number((Number(ingItem.quantity) + (Number(quantity) *
        Number(parseFloat(needForMakeQty))).toFixed(3)));
    }
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${ingredientUid}`),
      {
        ...ingItem,
        quantity: ingQty
      });
    dispatch(changeReduxIngQuantity({
      uid: ingredientUid,
      quantity: ingQty
    }));

    addAlertMessage(ingItem, ingQty, false);
  }
}

const addAlertMessage = (item, quantity, isItem) => {
  if (item?.minimalQuantity !== undefined && ((item.quantity - quantity) < item?.minimalQuantity)) {
    let code = isItem ? "Proizvod sa šifrom: " : "Sastojak sa šifrom: ";
    let name = item.name ? item.name : code + item.code;
    dispatch(addMessage({
      body: name + " ostalo još: " + quantity,
      date: new Date().toString(),
      isRead: false,
      title: " Premala količina: " + name
    }));
  }
};

export async function getProductFromRealtimeDbByUid(uid) {
  const publicItem = await (await get(child(dbRef, `public/items/${uid}`))).val();
  const privateItem = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`))).val();
  return {
    ...publicItem,
    ...privateItem
  };
}

export async function getCostsFromRealtimeDbByUid(uid) {
  return await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs/${uid}`))).val();
}

export async function getIngredientFromRealtimeDbByUid(uid) {
  return await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${uid}`))).val();
}

export async function itemsAveragePurchasePrice(date) {
  try {
    let dateForBefore = moment(date);
    let facturesBefore = await getAllFacturesByEndDateFromFirestore(dateForBefore.toDate());
    let items = [];
    for (const facture of facturesBefore) {
      for (const item of [...facture.items, ...facture.costs, ...(facture?.ingredients || [])]) {
        if (item?.uid) {
          let index = items.findIndex(obj => obj.uid === item?.uid);
          if (index === -1) {
            items.push({
              uid: item.uid,
              sumPrices: Number(item.purchasePrice),
              counter: 1
            });
          } else {
            items[index] = {
              ...items[index],
              sumPrices: items[index].sumPrices + Number(item.purchasePrice),
              counter: items[index].counter + 1
            };
          }
        }
      }
    }
    let objItems = {};
    for (const item of items) {
      objItems[item.uid] = item.sumPrices / item.counter;
    }
    return objItems;
  } catch (e) {
    console.error("itemAveragePurchasePrice", e);
    return {};
  }
}

export const itemsAnalytics = (componentName) => {
  const eventData = {
    user_uid: AUTH.currentUser.uid,
    screen: componentName,
  };
  logEvent(analyticsFB, 'read_items_webEsirNew', eventData);
}

