import { Helmet } from "react-helmet-async";
import { useLocales } from "../../../locales";
import { useAuthContext } from "../../../auth/useAuthContext";
import { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import FormProvider, { RHFTextField } from "../../../components/hook-form";
import { Alert, Container, IconButton, InputAdornment, Link, Stack, Typography } from "@mui/material";
import Iconify from "../../../components/Iconify";
import { LoadingButton } from "@mui/lab";
import { StyledContent, StyledSection } from "../../../layouts/login/styles";
import Image from "../../../components/image";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useAppSettings } from "../../../context/AppSettings";
import packageInfo from "../../../../package.json";

export default function LoginPage() {
  const { translate } = useLocales();
  const { IS_BH } = useAppSettings();
  const { login } = useAuthContext();
  const { height } = useWindowDimensions();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(translate("emailFormatValidation")).required(translate("emailIsRequired")),
    password: Yup.string().required(translate("passwordIsRequired"))
  });

  const defaultValues = {
    email: "",
    password: ""
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);

      reset();

      setError("afterSubmit", {
        ...error,
        message: translate("invalidCredentials")
      });
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <Helmet>
        <title>{translate("loginHelmet")}</title>
      </Helmet>
      <Container>
        <Typography variant="subtitle1" textAlign="center" sx={{
          mt: "3vh",
          fontSize: "2vh"
        }}>
          {translate("welcomeText")}
        </Typography>
        <Stack
          sx={{
            // height: "100vh",
            height: {
              sm: "80vh",
              md: "90vh"
            },
            display: "flex",
            flexDirection: {
              sm: "column",
              md: "row"
            },
            alignItems: {
              md: "center"
            },
            justifyContent: {
              sm: "flex-start",
              md: "center"
            }
          }}>
          <StyledSection>
            <Image
              visibleByDefault
              alt="auth"
              src={"/assets/wf.png"}
              sx={{ maxWidth: 720 }}
            />
          </StyledSection>

          <StyledContent>
            <Stack sx={{ width: 1 }}>
              <Stack spacing={2} sx={{ mb: 2, position: "relative" }}>
                <Typography variant="h4" textTransform="upper">{translate("signInTitle")}</Typography>
              </Stack>

              <Alert sx={{ mb: 3 }}>
                {translate("tehnicalSupport")}<strong>24/7/365</strong><br />
                {translate("phone")}:
                <strong>
                  {IS_BH ? "+38751981295" : "011/4444-588"}
                </strong>
              </Alert>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                  <RHFTextField name="email" label={translate("email")} />

                  <RHFTextField
                    name="password"
                    label={translate("password")}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Stack>

                <LoadingButton
                  fullWidth
                  color="inherit"
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitSuccessful || isSubmitting}
                  sx={{
                    mt: 1,
                    bgcolor: "text.primary",
                    color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800"),
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800")
                    }
                  }}>
                  {translate("signIn")}
                </LoadingButton>
              </FormProvider>
            </Stack>
          </StyledContent>
        </Stack>
      </Container>
      <Stack
        sx={{
          position: "fixed",
          display: height > 680 ? "flex" : "none",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          bottom: 0,
          // fontSize: "1vh",
          left: 0,
          right: 0
        }}>
        <Typography>
          {translate("detailedOn")}
        </Typography>
        <Link
          target="_blank"
          rel="noreferrer"
          sx={{
            mx: 1
          }}
          href="https://elektronskafiskalizacija.rs/">
          elektronskafiskalizacija.rs
        </Link>
        <Typography>
          {translate("or")}
        </Typography>
        <Link
          target="_blank"
          rel="noreferrer"
          sx={{
            mx: 1
          }}
          href="https://e-fiskal.rs/">
          e-fiskal.rs
        </Link>
      </Stack>
      <div
        style={
          { position: 'fixed'
          , bottom: 0
          , left: 0
          , padding: '10px'
          , cursor: 'pointer'
          }} onClick={toggleVisibility}>
        <Typography variant="caption" color="gray" visibility={ isVisible ? 'visible' : 'hidden'}>
          {`Version ${packageInfo.version}.${packageInfo.build}`}
        </Typography>
      </div>
    </>
  );
}
