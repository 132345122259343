import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { child, get, push, update } from "firebase/database";
import { AUTH, dbRef } from "../../auth/FirebaseContext";

export const fetchAllDiscounts = createAsyncThunk("fetchAllDiscounts", async () => {
  const arr = [];
  (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/discounts`))).forEach(child1 => {
    arr.push({
      ...child1.val(),
      uid: child1.key
    });
  });
  return arr;
});

export const addDiscount = createAsyncThunk("addDiscount", async (payload) => {
  delete payload.avatarUrl;
  const costKey = await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/discounts`),
    { ...payload, inactive: false });
  return { ...payload, uid: costKey.key };
});

export const updateDiscount = createAsyncThunk("updateDiscount", async (payload) => {
  delete payload.avatarUrl;
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/discounts/${payload.uid}`),
    { ...payload, inactive: false });
  return { ...payload, inactive: false };
});

export const deleteDiscount = createAsyncThunk("deleteDiscount", async (payload) => {
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/discounts/${payload.uid}`),
    { ...payload, inactive: !payload.inactive });
  return {
    ...payload,
    inactive: !payload.inactive
  };
});

const initialState = {
  discountsList: [],
  loading: false,
  discount: undefined
};

export const discountsSlice = createSlice({
  name: "discounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDiscounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDiscounts.fulfilled, (state, { payload }) => {
        state.discountsList = payload;
        state.loading = false;
      })
      .addCase(fetchAllDiscounts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addDiscount.pending, (state) => {
        state.loadingCosts = true;
      })
      .addCase(addDiscount.fulfilled, (state, { payload }) => {
        state.discountsList = [...state.discountsList, payload];
        state.loadingCosts = false;
      })
      .addCase(addDiscount.rejected, (state) => {
        state.loadingCosts = false;
      })
      .addCase(updateDiscount.pending, (state) => {
        state.loadingCosts = true;
      })
      .addCase(updateDiscount.fulfilled, (state, { payload }) => {
        state.discountsList = state.discountsList.map((item) =>
          item.uid === payload.uid ? payload : item
        );
        state.loadingCosts = false;
      })
      .addCase(updateDiscount.rejected, (state) => {
        state.loadingCosts = false;
      })
      .addCase(deleteDiscount.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDiscount.fulfilled, (state, { payload }) => {
        state.discountsList = state.discountsList.map((item) =>
          item.uid === payload.uid ? payload : item
        );
        state.loading = false;
      })
      .addCase(deleteDiscount.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const discountsReducer = discountsSlice.reducer;
