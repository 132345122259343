import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { dispatch } from "../../redux/store";
import { deleteOperatorSession } from "../../redux/slices/operators";
import { clearSession } from "../../helper/session";
import { PATH_AUTH } from "../../routes/path";
import { useNavigate } from "react-router-dom";
import Iconify from "../../components/Iconify";
import { useLocales } from "../../locales";

export default function SessionExpiredDialog({ open }) {
  const navigate = useNavigate();
  const {translate} = useLocales();
  const handleLogout = () => {
    dispatch(deleteOperatorSession());
    clearSession(false);
    navigate(PATH_AUTH.login, { replace: true });
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Iconify icon="flat-color-icons:expired" width={100} />
          <Typography variant="h4">
            {translate("maxSessionMessage")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogout}>
          {translate("signOut")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
