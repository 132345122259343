import { Helmet } from "react-helmet-async";

// layouts
import OperatorLayout from "../../../layouts/login/OperatorLayout";
import { useLocales } from "../../../locales";

export default function OperatorLoginPage() {
  const { translate } = useLocales();
  return (
    <>
      <Helmet>
        <title>{translate("loginOperatorHelmet")}</title>
      </Helmet>
      <OperatorLayout />
    </>
  );
}
