import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import Scrollbar from "../Scrollbar";
import moment from "moment";

export default function AllNotificationsDialog({ open, onClose, messages }) {
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <DialogTitle>Lista notifikacija</DialogTitle>
      </Box>

      <Scrollbar>
        <Box
          sx={{
            padding: 2,
            maxHeight: 700
          }}>

          <Stack spacing={1} justifyContent="space-between"
                 sx={{ my: 1 }}>
            {messages.map((notification, index) =>
              <NotificationItem
                key={`all-notifications-preview-${notification.uid}-${index}`}
                notification={notification} />)}
          </Stack>
        </Box>
      </Scrollbar>
    </Dialog>
  );
}

const NotificationItem = (data) => {
  return (
    <Box border="1px solid lightGrey" borderRadius={1} padding={2} width={1}>
      <Typography variant="subtitle1" color="text.disabled">{data.notification.title}</Typography>
      <Typography variant="inherit">{data.notification.body}</Typography>
      <Typography fontWeight={500} variant="caption">
        {moment(data.notification.date).format("DD.MM.YYYY HH:mm:ss")}
      </Typography>
    </Box>
  );
};
