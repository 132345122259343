import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  changeItemsAndTotalAdvanceInOpenAdvance,
  getAllOpenAdvanceFromFirestore,
  getOpenAdvanceInvoiceByTimestamp
} from "../../helper/firestore/openAdvance";

export const fetchOpenAdvanceByTimestamp = createAsyncThunk("fetchOpenAdvanceByTimestamp", async (timestamp) => {
  const invoice = await getOpenAdvanceInvoiceByTimestamp(timestamp);
  const total = invoice.items.reduce((acc, { quantity, unitPrice }) => acc + (quantity * unitPrice), 0);
  return ({
    ...invoice,
    total,
    forPayment: total - invoice.advanceAmount,
    totalPayed: invoice.advanceAmount
  });
});
export const fetchAllOpenAdvanceInvoices = createAsyncThunk("fetchAllOpenAdvanceInvoices", async (locationId) => {
  const invoices = await getAllOpenAdvanceFromFirestore(locationId);
  return invoices.map(invoice => {
    const total = invoice.items.reduce((acc, { quantity, unitPrice }) => acc + (quantity * unitPrice), 0);
    return ({
      ...invoice,
      total,
      forPayment: total - invoice.advanceAmount,
      totalPayed: invoice.advanceAmount
    });
  });
});

export const changeOpenAdvanceItems = createAsyncThunk("changeOpenAdvanceItems", async (data) => {
  const { items, timestamp } = data;
  await changeItemsAndTotalAdvanceInOpenAdvance(items, timestamp);
});

const initialState = {
  invoices: [],
  loading: false
};

const slice = createSlice({
  name: "openAdvance",
  initialState,
  reducers: {
    removeClosedAdvance: (state, { payload }) => {
      state.invoices = state.invoices.filter(item => item.timestamp !== payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOpenAdvanceInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllOpenAdvanceInvoices.fulfilled, (state, { payload }) => {
        state.invoices = payload;
        state.loading = false;
      })
      .addCase(fetchAllOpenAdvanceInvoices.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changeOpenAdvanceItems.fulfilled, (state, { meta }) => {
        const { timestamp, items } = meta.arg;
        state.invoices = state.invoices.map(item =>
          item.timestamp === timestamp
            ? {
              ...item,
              items,
              total: items.reduce((acc, { quantity, unitPrice }) => acc + quantity * unitPrice, 0),
              forPayment: items.reduce((acc, {
                quantity,
                unitPrice
              }) => acc + quantity * unitPrice, 0) - item.advanceAmount
            }
            : item
        );
      })
      .addCase(fetchOpenAdvanceByTimestamp.fulfilled, (state, { meta, payload }) => {
        const timestamp = meta.arg;
        state.invoices = state.invoices.map(item =>
          item.timestamp === timestamp ? payload : item
        );
      });
  }
});

export const { removeClosedAdvance } = slice.actions;

// Reducer
export default slice.reducer;
