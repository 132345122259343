import { getSessionOperator } from "../../session";
import { collection, doc, getDocs, query, setDoc, Timestamp, where } from "firebase/firestore";
import { AUTH, DB } from "../../../auth/FirebaseContext";

const parseItemsForRepresentation = (items) => {
  return items.map(item => ({
    name: item.name || null,
    uid: item.uid || null,
    quantity: item.quantity || null,
    unit: item.unit || null,
    labels: item.labels || null,
    code: item.code || null,
    category: item.category || null,
    unitPrice: item.unitPrice || null,
    totalAmount: item.totalAmount || null
  }));
};

export async function addRepresentationOnFirestore(items, note, discount) {
  let cashier = getSessionOperator().username;
  let id = Date.now();
  return await setDoc(doc(DB, "invoices", "representations", AUTH.currentUser.uid, id.toString()), {
    items: parseItemsForRepresentation(items),
    cashier,
    note: note || null,
    discount: discount || null,
    time: Timestamp.fromMillis(id)
  });
}

export async function getRepresentationsFromFirestoreForDate(startDate, endDate) {
  let arr = [];
  const q = await query(collection(DB, `invoices/representations/${AUTH.currentUser.uid}`),
    where("time", ">=", Timestamp.fromDate(startDate)),
    where("time", "<=", Timestamp.fromDate(endDate)));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    arr.push({
      ...doc.data(),
      uid: doc.id
    });
  });
  return arr;
}
