import React from "react";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import {Provider as ReduxProvider} from "react-redux";
import {PersistGate} from "redux-persist/lib/integration/react";

import reportWebVitals from "./reportWebVitals";

// redux
import {persistor, store} from "./redux/store";
// @mui
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import App from "./App";

import {SettingsProvider} from "./components/settings";
import {AuthProvider} from "./auth/FirebaseContext";
import LoadingScreen from "./components/loading-screen";
import Fallback from "./components/Fallback";
import {ErrorBoundary} from "react-error-boundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ErrorBoundary
        FallbackComponent={Fallback}>
        <HelmetProvider>
            <ReduxProvider store={store}>
                <PersistGate loading={<LoadingScreen/>} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <SettingsProvider>
                            <AuthProvider>
                                <BrowserRouter>
                                    <App/>
                                </BrowserRouter>
                            </AuthProvider>
                        </SettingsProvider>
                    </LocalizationProvider>
                </PersistGate>
            </ReduxProvider>
        </HelmetProvider>
    </ErrorBoundary>
);
reportWebVitals();
