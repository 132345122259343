import { onChildAdded, onChildChanged, onChildRemoved, ref } from "firebase/database";
import { AUTH, RDB } from "../../auth/FirebaseContext";
import { dispatch } from "../../redux/store";
import { addMessage, changeMessage, deleteMessage } from "../../redux/slices/notifications";
import { changedLicense } from "../../redux/slices/license";
import { clearSession, deleteLocalSessionId, getSessionId } from "../session";

export const setAllRealtimeDbListeners = (onMaxSession) => {
  let messageRef = ref(RDB, `users/${AUTH.currentUser.uid}/private/messages/inbox`);
  const onMessChange = onChildChanged(messageRef, (data) => {
    dispatch(changeMessage({
      ...data.val(),
      uid: data.key
    }));
  });
  const onMessRemoved = onChildRemoved(messageRef, (data) => {
    dispatch(deleteMessage({
      ...data.val(),
      uid: data.key
    }));
  });
  const onMessAdded = onChildAdded(messageRef, (data) => {
    dispatch(addMessage({
      ...data.val(),
      uid: data.key
    }));
  });
  let licenseRef = ref(RDB, `users/${AUTH.currentUser.uid}/protected/license/`);
  const onLicenseChanged = onChildChanged(licenseRef, (data) => {
    dispatch(changedLicense({
      ...data.val(),
      uid: data.key
    }));
  });
  const arr = [onMessChange, onMessRemoved, onMessAdded, onLicenseChanged];
  let sessionId = getSessionId();
  if (sessionId) {
    let sessionRef = ref(RDB, `users/${AUTH.currentUser.uid}/protected/license/0/sessions/${sessionId}`);
    const onSessionRemoved = onChildRemoved(sessionRef, () => {
      deleteLocalSessionId();
      clearSession(false);
      if (onMaxSession) {
        onMaxSession();
      }
      arr.push(onSessionRemoved);
    });
  }
  return arr;
};
