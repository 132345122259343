import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//
import { child, get } from "firebase/database";
import { dbRef } from "../../auth/FirebaseContext";
import { ESIR_OPERATORS_GROUPS } from "../../constants";

export const fetchAllPublicGroups = createAsyncThunk("groups/fetchAll", async () => {
  let arr = [];
  (await get(child(dbRef, "public/groups"))).forEach(child1 => {
    if (ESIR_OPERATORS_GROUPS.includes(parseInt(child1.key))) {
      arr.push({
        ...child1.val(),
        uid: parseInt(child1.key)
      });
    }
  });
  (await get(child(dbRef, "public/groupsFakture"))).forEach(child1 => {
    if (ESIR_OPERATORS_GROUPS.includes(parseInt(child1.key))) {
      arr.push({
        ...child1.val(),
        uid: parseInt(child1.key)
      });
    }
  });
  return arr;
});

const initialState = {
  loading: false,
  groups: []
};

const slice = createSlice({
  name: "groupsPublic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPublicGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPublicGroups.fulfilled, (state, { payload }) => {
        state.groups = payload;
        state.loading = false;
      })
      .addCase(fetchAllPublicGroups.rejected, (state) => {
        state.loading = false;
      });
  }
});

export default slice.reducer;
