import { useTranslation } from "react-i18next";
// components
//
import { allLangs, defaultLang } from "./config-lang";
import { getSessionLanguage, setSessionLanguage } from "../helper/session";
import { useSettingsContext } from "../components/settings";

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { onChangeDirectionByLang } = useSettingsContext();
  const langStorage = getSessionLanguage() || "";

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = async (newlang) => {
    await i18n.changeLanguage(newlang);
    onChangeDirectionByLang(newlang);
    setSessionLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text, options) => translate(text, options),
    currentLang,
    currency: currentLang?.currency,
    allLangs
  };
}
