import axios from "axios";
import {HOST_API_KEY} from "../config";
import {getSessionAppVersion} from "../helper/session";

const BASE_URL = `${HOST_API_KEY}/taxCoreAPI`;

export const customAxios = (token, pac) => axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`,
    "PAC": pac,
    "client_os": "web",
    "client_app": "fisSoft",
    "client_version": getSessionAppVersion()
  },
  withCredentials: false
});
