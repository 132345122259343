import { useState } from "react";
import { Menu, MenuItem, Stack } from "@mui/material";
import { IconButtonAnimate } from "../animate";
import Image from "../image";
import { useLocales } from "../../locales";

export default function LanguagePopover() {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(anchorEl && {
            bgcolor: "action.selected"
          })
        }}>
        <Image disabledEffect src={currentLang.icon} alt={currentLang.label} />
      </IconButtonAnimate>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClosePopover}
        sx={{ width: 180 }}>
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}>
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28 }} />
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </>
  );
}
