import { Box } from "@mui/material";
import Iconify from "../../components/Iconify";
import { useLocales } from "../../locales";
import { useSelector } from "../../redux/store";
import { keyframes } from "@mui/system";

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;
export default function StatusIndicator() {
  const { translate } = useLocales();
  const { vpfrOnline, lpfrOnline } = useSelector(((state) => state.taxCore));

  return (
    <Box
      gap={1.5}
      display="flex"
      alignItems="center"
      flexDirection="row"
      sx={{
        px: 2,
        pt: 1,
      }}>
      <Box
        gap={0.5}
        display="flex"
        alignItems="center"
        flexDirection="row">
        <Iconify
          sx={{
            animation: vpfrOnline ? `${blink} 1.5s linear infinite;` : null
          }}
          color={vpfrOnline ? "success.main" : "error.main"}
          icon={vpfrOnline ? "pajamas:status-active" : "pajamas:status-alert"} />
        {translate("vpfr")}
      </Box>
      <Box
        gap={1}
        display="flex"
        alignItems="center"
        flexDirection="row">
        <Iconify
          sx={{
            animation: lpfrOnline ? `${blink} 1.5s linear infinite;` : null
          }}
          color={lpfrOnline ? "success.main" : "error.main"}
          icon="pajamas:status-active" />
        {translate("lpfr")}
      </Box>
    </Box>
  );
}
