import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import { PATH_AFTER_LOGIN } from "../config";
import {
  AddOldInvoicePage,
  AnalyticPage,
  ClientPreviewPage,
  ClientsPage,
  CompensationAddRemovePage,
  CompensationPage,
  CostsEditPage,
  CostsList,
  CostsNew,
  CostsReportPage,
  CsvInvoicePage,
  CustomersCardPage,
  CustomersEditPage,
  CustomersList,
  CustomersNew,
  DiscountsEditPage,
  DiscountsList,
  DiscountsNew,
  DiscountsUserNew,
  DiscountsUsersList,
  DiscountUserEditPage,
  EFactureAnalytics,
  EFactureExistInvoices,
  EFactureIncommingInvoices,
  EFactureIndividuals,
  EFactureIndividualsCreate,
  EFactureNewInvoice,
  EFactureSummary,
  EFactureSummaryCreate,
  FactureAdditionalPayment,
  FactureCreatePage,
  FactureEditPage,
  FacturePreview,
  FacturesPage,
  FilterReportPage,
  HomePage,
  IngredientCreatePage,
  IngredientEditPage,
  IngredientRatioPage,
  IngredientsPage,
  InventoryHistoryPage,
  InventoryPage,
  InventoryPreviewPage,
  InvoicesPage,
  ItemsCardPage,
  ItemsEditPage,
  ItemsNewPage,
  ItemsPage,
  ItemsReportPage,
  Login,
  OldInvoicesPage,
  OpenAdvancePage,
  OperatorsEditPage,
  OperatorsList,
  OperatorsNew,
  OverviewPage,
  ProfitReportPage,
  ReferralsPage,
  SavedInvoicesPage,
  SettingsPage,
  StockListPage,
  SuppliersCreatePage,
  SuppliersEditPage,
  SuppliersPage,
  TurnoverWithoutCompensationPage,
  WriteOffCreatePage,
  WriteOffPage,
  WriteOffReportPage
} from "./elements";
import AuthGuard from "../auth/AuthGuard";
import { PATH_AUTH, PATH_DASHBOARD } from "./path";
import GuestGuard from "../auth/GuestGuard";

export default function Router() {
  return useRoutes([
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: PATH_DASHBOARD.general.app, element: <HomePage /> },
        { path: PATH_DASHBOARD.general.csvInvoice, element: <CsvInvoicePage /> },
        { path: PATH_DASHBOARD.general.settings, element: <SettingsPage /> },
        { path: PATH_DASHBOARD.analytics.root, element: <AnalyticPage /> },
        { path: PATH_DASHBOARD.referrals.root, element: <ReferralsPage /> },
        {
          path: PATH_DASHBOARD.reports.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.reports.overview} replace />, index: true },
            { path: PATH_DASHBOARD.reports.overview, element: <OverviewPage /> },
            { path: PATH_DASHBOARD.reports.item, element: <ItemsReportPage /> },
            { path: PATH_DASHBOARD.reports.costs, element: <CostsReportPage /> },
            { path: PATH_DASHBOARD.reports.writeOff, element: <WriteOffReportPage /> },
            { path: PATH_DASHBOARD.reports.filter, element: <FilterReportPage /> },
            { path: PATH_DASHBOARD.reports.profit, element: <ProfitReportPage /> },
            { path: PATH_DASHBOARD.reports.turnoverWithoutCompensation, element: <TurnoverWithoutCompensationPage /> },
            { path: PATH_DASHBOARD.reports.itemCard, element: <ItemsCardPage /> }
          ]
        },
        {
          path: PATH_DASHBOARD.compensation.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.compensation.list} replace />, index: true },
            { path: PATH_DASHBOARD.compensation.list, element: <CompensationPage /> },
            { path: PATH_DASHBOARD.compensation.editRemove, element: <CompensationAddRemovePage /> }
          ]
        },
        {
          path: PATH_DASHBOARD.items.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.items.list} replace />, index: true },
            { path: PATH_DASHBOARD.items.list, element: <ItemsPage /> },
            { path: PATH_DASHBOARD.items.new, element: <ItemsNewPage /> },
            { path: ":id/edit", element: <ItemsEditPage /> }
          ]
        },
        {
          path: PATH_DASHBOARD.costs.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.costs.list} replace />, index: true },
            { path: PATH_DASHBOARD.costs.list, element: <CostsList /> },
            { path: PATH_DASHBOARD.costs.new, element: <CostsNew /> },
            { path: ":name/edit", element: <CostsEditPage /> }
          ]
        },
        {
          path: PATH_DASHBOARD.operators.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.operators.list} />, index: true },
            { path: PATH_DASHBOARD.operators.list, element: <OperatorsList /> },
            { path: PATH_DASHBOARD.operators.new, element: <OperatorsNew /> },
            { path: ":name/edit", element: <OperatorsEditPage /> }
          ]
        },
        {
          path: PATH_DASHBOARD.customers.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.customers.list} replace />, index: true },
            { path: PATH_DASHBOARD.customers.list, element: <CustomersList /> },
            { path: PATH_DASHBOARD.customers.new, element: <CustomersNew /> },
            { path: ":name/edit", element: <CustomersEditPage /> },
            { path: ":name/card", element: <CustomersCardPage /> }
          ]
        },
        {
          path: PATH_DASHBOARD.discounts.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.discounts.list} replace />, index: true },
            { path: PATH_DASHBOARD.discounts.list, element: <DiscountsList /> },
            { path: PATH_DASHBOARD.discounts.new, element: <DiscountsNew /> },
            { path: PATH_DASHBOARD.discounts.customers, element: <DiscountsUsersList /> },
            { path: PATH_DASHBOARD.discounts.customersNew, element: <DiscountsUserNew /> },
            { path: ":name/edit", element: <DiscountsEditPage /> },
            { path: ":name/editCustomer", element: <DiscountUserEditPage /> }
          ]
        },
        {
          path: PATH_DASHBOARD.warehouse.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.warehouse.suppliers} replace />, index: true },
            { path: PATH_DASHBOARD.warehouse.suppliers, element: <SuppliersPage /> },
            { path: PATH_DASHBOARD.warehouse.newSupplier, element: <SuppliersCreatePage /> },
            { path: PATH_DASHBOARD.warehouse.editSupplier, element: <SuppliersEditPage /> },
            { path: PATH_DASHBOARD.warehouse.factures, element: <FacturesPage /> },
            { path: PATH_DASHBOARD.warehouse.viewFacture, element: <FacturePreview /> },
            { path: PATH_DASHBOARD.warehouse.addPaidAmountFacture, element: <FactureAdditionalPayment /> },
            { path: PATH_DASHBOARD.warehouse.newFacture, element: <FactureCreatePage /> },
            { path: PATH_DASHBOARD.warehouse.editFacture, element: <FactureEditPage /> },
            { path: PATH_DASHBOARD.warehouse.stockList, element: <StockListPage /> },
            {
              path: PATH_DASHBOARD.warehouse.ingredients.root, children: [
                { element: <Navigate to={PATH_DASHBOARD.warehouse.ingredients.list} replace />, index: true },
                { path: PATH_DASHBOARD.warehouse.ingredients.list, element: <IngredientsPage /> },
                { path: PATH_DASHBOARD.warehouse.ingredients.ingredientRatio, element: <IngredientRatioPage /> }
              ]
            },
            {
              path: PATH_DASHBOARD.warehouse.inventory.root, children: [
                { element: <Navigate to={PATH_DASHBOARD.warehouse.inventory.list} replace />, index: true },
                { path: PATH_DASHBOARD.warehouse.inventory.list, element: <InventoryPage /> },
                { path: PATH_DASHBOARD.warehouse.inventory.history, element: <InventoryHistoryPage /> }
              ]
            },
            { path: PATH_DASHBOARD.warehouse.editIngredient, element: <IngredientEditPage /> },
            { path: PATH_DASHBOARD.warehouse.createIngredient, element: <IngredientCreatePage /> },
            { path: PATH_DASHBOARD.warehouse.inventoryPreview, element: <InventoryPreviewPage /> },
            { path: PATH_DASHBOARD.warehouse.writeOff, element: <WriteOffPage /> },
            { path: PATH_DASHBOARD.warehouse.newWriteOff, element: <WriteOffCreatePage /> },
            { path: PATH_DASHBOARD.warehouse.clients, element: <ClientsPage /> },
            { path: PATH_DASHBOARD.warehouse.clientsPreview, element: <ClientPreviewPage /> }
          ]
        },
        {
          path: PATH_DASHBOARD.invoices.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.invoices.list} replace />, index: true },
            { path: PATH_DASHBOARD.invoices.list, element: <InvoicesPage /> },
            { path: PATH_DASHBOARD.invoices.old, element: <OldInvoicesPage /> },
            { path: PATH_DASHBOARD.invoices.createOld, element: <AddOldInvoicePage /> },
            { path: PATH_DASHBOARD.invoices.advance, element: <OpenAdvancePage /> },
            { path: PATH_DASHBOARD.invoices.saved, element: <SavedInvoicesPage /> }
          ]
        },
        {
          path: PATH_DASHBOARD.eFacture.root,
          children: [
            { element: <Navigate to={PATH_DASHBOARD.eFacture.analytics} replace />, index: true },
            { path: PATH_DASHBOARD.eFacture.analytics, element: <EFactureAnalytics /> },
            { path: PATH_DASHBOARD.eFacture.incomingInvoices, element: <EFactureIncommingInvoices /> },
            { path: PATH_DASHBOARD.eFacture.exitInvoicesList, element: <EFactureExistInvoices /> },
            { path: PATH_DASHBOARD.eFacture.new, element: <EFactureNewInvoice /> },
            { path: PATH_DASHBOARD.eFacture.individualRecordsList, element: <EFactureIndividuals /> },
            { path: PATH_DASHBOARD.eFacture.newIndividualRecord, element: <EFactureIndividualsCreate /> },
            { path: PATH_DASHBOARD.eFacture.summaryRecordsList, element: <EFactureSummary /> },
            { path: PATH_DASHBOARD.eFacture.newSummaryRecord, element: <EFactureSummaryCreate /> }
          ]
        }
      ]
    }
  ]);
}
