import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
// redux
import { child, get, push, update } from "firebase/database";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { deleteLocalSessionId, setSessionId } from "../../helper/session";
import { ESIR_OPERATORS_GROUPS } from "../../constants";
import { createUserSessionApi, deleteUserSessionApi } from "../../api/esir";
// ----------------------------------------------------------------------

export const fetchAllOperators = createAsyncThunk("fetchAllOperators", async () => {
  const arr = [];
  (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/operators`))).forEach(child1 => {
    if (ESIR_OPERATORS_GROUPS.includes(parseInt(child1.val().group))) {
      arr.push({
        ...child1.val(),
        uid: child1.key
      });
    }
  });
  return arr;
});

export const createOperator = createAsyncThunk("createOperator", async (operator) => {
  const obj = {
    username: operator.username,
    secret: operator.secret,
    group: Number(operator.group),
    inactive: false
  };
  (await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/operators`),
    obj));
  return obj;
});

export const changeOperatorStatus = createAsyncThunk("changeOperatorStatus", async (operator) => {
  (await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/operators/${operator.uid}`), {
    ...operator,
    inactive: !operator.inactive
  }));
  return {
    ...operator,
    inactive: !operator.inactive
  };
});

export const updateOperator = createAsyncThunk("updateOperator", async (operator) => {
  const obj = {
    group: Number(operator.group),
    inactive: false,
    secret: operator.secret,
    uid: operator.uid,
    username: operator.username
  };
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/operators/${operator.uid}`), obj);
  return obj;
});

export const createOperatorSession = createAsyncThunk("createOperatorSession", async (data) => {
  let response = (await createUserSessionApi(data)).data;
  setSessionId(response);
  return response;
});

export const deleteOperatorSession = createAsyncThunk("deleteOperatorSession", async () => {
  await deleteUserSessionApi();
  deleteLocalSessionId();
});

const initialState = {
  loading: false,
  allOperators: [],
  operator: null
};

// Reducer
export const operatorsSlice = createSlice({
  name: "operators",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOperators.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllOperators.fulfilled, (state, { payload }) => {
        state.allOperators = payload.sort((a, b) => a.group - b.group);
        state.loading = false;
      })
      .addCase(fetchAllOperators.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changeOperatorStatus.pending, updateLoading)
      .addCase(changeOperatorStatus.fulfilled, updateOperatorInList)
      .addCase(changeOperatorStatus.rejected, updateLoading)
      .addCase(updateOperator.pending, updateLoading)
      .addCase(updateOperator.fulfilled, updateOperatorInList)
      .addCase(updateOperator.rejected, updateLoading)
      .addCase(createOperator.pending, updateLoading)
      .addCase(createOperator.fulfilled, addOperatorToList)
      .addCase(createOperator.rejected, updateLoading);
  }
});

function updateLoading(state) {
  state.loading = true;
}

function updateOperatorInList(state, { payload }) {
  const arr = [...current(state.allOperators)];
  const index = arr.findIndex((o) => o.uid === payload.uid);
  if (index !== -1) {
    arr[index] = payload;
    state.allOperators = arr;
  }
  state.loading = false;
}

function addOperatorToList(state, { payload }) {
  const arr = [...current(state.allOperators)];
  arr.push(payload);
  state.allOperators = arr;
  state.loading = false;
}

export const operatorsReducer = operatorsSlice.reducer;
