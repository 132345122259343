import { lazy, Suspense } from "react";
import LoadingScreen from "../components/loading-screen";

const Loadable = (Component) => (props) =>
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>;


// ----------------------------------------------------------------------


// AUTHENTICATION
export const Login = Loadable(lazy(() => import("../pages/auth/LoginPage")));

// Compensation
export const CompensationPage = Loadable(lazy(() => import("../pages/Compensation")));
export const CompensationAddRemovePage =
  Loadable(lazy(() => import("../pages/Compensation/CompensationAddRemove")));

//PAGES
export const HomePage = Loadable(lazy(() => import("../pages/Home")));
export const ReferralsPage = Loadable(lazy(() => import("../pages/Referrals")));
export const CsvInvoicePage = Loadable(lazy(() => import("../pages/CsvInvoice")));
export const AnalyticPage = Loadable(lazy(() => import("../pages/Analytics")));
export const SettingsPage = Loadable(lazy(() => import("../pages/Settings")));
//RACUNI
export const InvoicesPage = Loadable(lazy(() => import("../pages/Invoices")));
export const OldInvoicesPage = Loadable(lazy(() => import("../pages/Invoices/Old")));
export const AddOldInvoicePage = Loadable(lazy(() => import("../pages/Invoices/Old/AddOldInvoicePage")));
export const OpenAdvancePage = Loadable(lazy(() => import("../pages/Invoices/OpenAdvance")));
export const SavedInvoicesPage = Loadable(lazy(() => import("../pages/Invoices/Saved")));

//IZVESTAJ
export const OverviewPage = Loadable(lazy(() => import("../pages/Reports/Overview")));
export const ItemsReportPage = Loadable(lazy(() => import("../pages/Reports/Items/ItemsReportPage")));
export const CostsReportPage = Loadable(lazy(() => import("../pages/Reports/Costs")));
export const WriteOffReportPage = Loadable(lazy(() => import("../pages/Reports/WriteOff")));
export const FilterReportPage = Loadable(lazy(() => import("../pages/Reports/Filter")));
export const ProfitReportPage = Loadable(lazy(() => import("../pages/Reports/Profit")));
export const TurnoverWithoutCompensationPage =
  Loadable(lazy(() => import("../pages/Reports/TurnoverWithoutCompensationPage")));
export const ItemsCardPage = Loadable(lazy(() => import("../pages/Reports/ItemsCard")));
export const SuppliersPage = Loadable(lazy(() => import("../pages/Warehouse/Suppliers")));
export const SuppliersCreatePage =
  Loadable(lazy(() => import("../pages/Warehouse/Suppliers/SuppliersCreatePage")));
export const SuppliersEditPage = Loadable(lazy(() => import("../pages/Warehouse/Suppliers/SuppliersEditPage")));
export const FacturesPage = Loadable(lazy(() => import("../pages/Warehouse/Factures")));
export const FacturePreview = Loadable(lazy(() => import("../pages/Warehouse/Factures/FacturePreview")));
export const FactureAdditionalPayment =
  Loadable(lazy(() => import("../pages/Warehouse/Factures/FactureAdditionalPayment")));
export const FactureCreatePage = Loadable(lazy(() => import("../pages/Warehouse/Factures/FactureCreatePage")));
export const FactureEditPage = Loadable(lazy(() => import("../pages/Warehouse/Factures/FactureEditPage")));
export const StockListPage = Loadable(lazy(() => import("../pages/Warehouse/StockList")));

//INGREDIANTS
export const IngredientsPage = Loadable(lazy(() => import("../pages/Warehouse/Ingredients")));
export const IngredientRatioPage = Loadable(lazy(() => import("../pages/Warehouse/IngrediantRatio")));
export const IngredientEditPage =
  Loadable(lazy(() => import("../pages/Warehouse/Ingredients/IngredientEditPage")));
export const IngredientCreatePage =
  Loadable(lazy(() => import("../pages/Warehouse/Ingredients/IngredientCreatePage")));

// INVENTORY
export const InventoryPage = Loadable(lazy(() => import("../pages/Warehouse/Inventory")));
export const InventoryHistoryPage = Loadable(lazy(() => import("../pages/Warehouse/InventoryHistory")));
export const InventoryPreviewPage =
  Loadable(lazy(() => import("../pages/Warehouse/InventoryPreview")));

// WRITEOFF
export const WriteOffPage = Loadable(lazy(() => import("../pages/Warehouse/WriteOff")));
export const WriteOffCreatePage = Loadable(lazy(() => import("../pages/Warehouse/WriteOff/WriteOffCreatePage")));
export const ClientsPage = Loadable(lazy(() => import("../pages/Warehouse/Clients")));
export const ClientPreviewPage = Loadable(lazy(() => import("../pages/Warehouse/Clients/ClientPreview")));

// ITEMS
export const ItemsPage = Loadable(lazy(() => import("../pages/Items")));
export const ItemsNewPage = Loadable(lazy(() => import("../pages/Items/ItemsNew")));
export const ItemsEditPage = Loadable(lazy(() => import("../pages/Items/ItemsEdit")));
// OPERATORS
export const OperatorsList = Loadable(lazy(() => import("../pages/Operators")));
export const OperatorsNew = Loadable(lazy(() => import("../pages/Operators/OperatorsNew")));
export const OperatorsEditPage = Loadable(lazy(() => import("../pages/Operators/OperatorsEditPage")));

// CUSTOMERS
export const CustomersList = Loadable(lazy(() => import("../pages/Customers")));
export const CustomersNew = Loadable(lazy(() => import("../pages/Customers/CustomersNew")));
export const CustomersEditPage = Loadable(lazy(() => import("../pages/Customers/CustomersEditPage")));
export const CustomersCardPage = Loadable(lazy(() => import("../pages/Customers/Card")));

// CUSTOMERS
export const CostsList = Loadable(lazy(() => import("../pages/Costs")));
export const CostsNew = Loadable(lazy(() => import("../pages/Costs/CostsNew")));
export const CostsEditPage = Loadable(lazy(() => import("../pages/Costs/CostsEditPage")));

//DISCOUNTS
export const DiscountsList = Loadable(lazy(() => import("../pages/Discounts")));
export const DiscountsNew = Loadable(lazy(() => import("../pages/Discounts/DiscountsNew")));
export const DiscountsEditPage = Loadable(lazy(() => import("../pages/Discounts/DiscountEditPage")));
export const DiscountsUsersList = Loadable(lazy(() => import("../pages/Discounts/DiscountUsersList")));
export const DiscountsUserNew = Loadable(lazy(() => import("../pages/Discounts/CustomerDiscountNew")));
export const DiscountUserEditPage = Loadable(lazy(() => import("../pages/Discounts/CustomerDiscountEditPage")));

//eFacture
export const EFactureAnalytics = Loadable(lazy(() => import("../pages/eFacture/Analytics")));
export const EFactureIncommingInvoices = Loadable(lazy(() => import("../pages/eFacture/IncomingInvoice")));
export const EFactureExistInvoices = Loadable(lazy(() => import("../pages/eFacture/Invoice/InvoiceList")));
export const EFactureNewInvoice = Loadable(lazy(() => import("../pages/eFacture/Invoice/InvoiceCreate")));
export const EFactureIndividuals = Loadable(lazy(() => import("../pages/eFacture/Records/Individual")));
export const EFactureIndividualsCreate = Loadable(lazy(() =>
  import("../pages/eFacture/Records/Individual/IndividualRecordsCreate")));
export const EFactureSummary = Loadable(lazy(() => import("../pages/eFacture/Records/Summary")));
export const EFactureSummaryCreate = Loadable(lazy(() =>
  import("../pages/eFacture/Records/Summary/SummaryRecordsCreate")));
