// @mui
import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    Typography
} from "@mui/material";
import Iconify from "../../components/Iconify";
import {useLayoutEffect, useState} from "react";
import {dispatch, useSelector} from "../../redux/store";
import {fetchAllOperators} from "../../redux/slices/operators";
import {useAuthContext} from "../../auth/useAuthContext";
import {useSettingsContext} from "../../components/settings";
import {useSnackbar} from "notistack";
import FormProvider, {RHFTextField} from "../../components/hook-form";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {LoadingButton} from "@mui/lab";
import {fetchAllPublicGroups} from "../../redux/slices/groupsPublic";
import Scrollbar from "../../components/Scrollbar";
import LoadingScreen from "../../components/loading-screen";
import {useLocales} from "../../locales";

OperatorLayout.propTypes = {};
export default function OperatorLayout() {
    const {themeStretch} = useSettingsContext();
    const {enqueueSnackbar} = useSnackbar();
    const {translate} = useLocales();

    const {operatorLogin, logout} = useAuthContext();

    const {groups, loading: groupLoading} = useSelector(state => state.groupsPublic);
    const {allOperators, loading: operatorLoading} = useSelector(state => state.operators);

    const loading = groupLoading || operatorLoading;

    useLayoutEffect(() => {
        dispatch(fetchAllOperators()).unwrap().catch(reason => {
            console.error("fetchAllOperators", reason);
            enqueueSnackbar(translate("unableToRetrieveOperators"), {variant: "error"});
        });
    }, []);

    useLayoutEffect(() => {
        if (groups.length === 0) {
            dispatch(fetchAllPublicGroups()).unwrap().catch(reason => {
                console.error("fetchAllPublicGroups", reason);
                enqueueSnackbar(translate("unableToRetrieveGroups"), {variant: "error"});
            });
        }
    }, [groups.length]);

    const handleLogout = async () => {
        try {
            logout();
        } catch (error) {
            console.error(error);
            enqueueSnackbar(translate("errorOccurred"), {variant: "error"});
        }
    };

    if (loading) {
        return <LoadingScreen/>;
    }

    return (
        <Scrollbar sx={{height: "100vh"}}>
            <Container maxWidth={themeStretch ? false : "xl"}>
                {allOperators.length === 0 ?
                    <Box sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        flex: 1,
                        width: "100hw",
                        height: "100vh"
                    }}>
                        <Typography variant={"h5"}>
                            {translate("operatorsNotFound")}
                        </Typography>
                    </Box> :
                    <Box
                        paddingY={5}
                        gap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: "repeat(2, 1fr)",
                            sm: "repeat(3, 1fr)",
                            md: "repeat(4, 1fr)"
                        }}>
                        {allOperators.filter(op => {
                            if (!op.inactive) {
                                return true;
                            }
                            return !op.inactive;
                        }).map((operator, index) =>
                            <OperatorCard
                                key={`operators-${operator.uid}-${index}`}
                                operatorLogin={operatorLogin}
                                data={operator}
                                groups={groups}
                            />
                        )}
                    </Box>
                }
                <Button
                    onClick={handleLogout}
                    sx={{position: "fixed", bottom: 25, right: 25}}
                    variant={"contained"}>
                    {translate("signOut")}
                </Button>
            </Container>
        </Scrollbar>
    );
}

const OperatorCard = ({data, groups, operatorLogin}) => {
    const {translate} = useLocales();
    const {group, username} = data;

    const [showPassword, setShowPassword] = useState(false);

    const OperatorLoginSchema = Yup.object().shape({
        password: Yup.string().required(translate("passwordIsRequired"))
    });

    const defaultValues = {
        password: ""
    };

    const methods = useForm({
        resolver: yupResolver(OperatorLoginSchema),
        defaultValues
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: {errors, isSubmitting}
    } = methods;

    const findRoleName = (roleId) => {
        return groups?.find(g => g.uid === roleId)?.name;
    };

    const onSubmit = async (props) => {
        try {
            await operatorLogin(username, props.password.toString());
        } catch (error) {
            console.error(error);
            reset();
            setError("afterSubmit", {
                ...error,
                message: error.message
            });
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

            <Card
                sx={{
                    py: 5,
                    px: 5,
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    flexDirection: "column"
                }}>
                <Stack direction="row" alignItems="center" sx={{top: 8, right: 8, position: "absolute"}}>
                    {group === 31 &&
                        <Checkbox
                            icon={<Iconify icon="eva:star-outline"/>}
                            checkedIcon={<Iconify icon="eva:star-fill"/>}
                            checked={true}
                            sx={{p: 0.75}}
                        />}
                </Stack>
                <Avatar alt={username} src={"avatarUrl"} sx={{width: 64, height: 64, mb: 3}}/>

                <Link variant="subtitle2" color="text.primary">
                    {findRoleName(group)}
                </Link>

                <Link variant="subtitle1" color="text.primary">
                    {username.toUpperCase()}
                </Link>

                <RHFTextField
                    name="password"
                    label={translate("password")}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />

                <LoadingButton
                    fullWidth
                    color="inherit"
                    size="small"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{
                        mt: 1,
                        bgcolor: "text.primary",
                        color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800"),
                        "&:hover": {
                            bgcolor: "text.primary",
                            color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800")
                        }
                    }}>
                    {translate("signIn")}
                </LoadingButton>
            </Card>
        </FormProvider>
    );
};
