import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const SpinnerWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgb(255,255,255,0.6)",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 99999
}));

export default function LoadingModal() {
  return (
    <SpinnerWrapper>
      <CircularProgress />
    </SpinnerWrapper>
  );
}
