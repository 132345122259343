export const OFFLINE_DB_NAME = "ESIR_WEB_V2";

export const offline_tables = {
  all_refunded_invoices: "all_refunded_invoices",
  all_advance_invoices: "all_advance_invoices",
  all_accounting_invoices: "all_accounting_invoices",
  all_normal_invoices: "all_normal_invoices",
  all_copies_invoices: "all_copies_invoices",
  all_training_invoices: "all_training_invoices",
  all_unhandled_invoices: "all_unhandled_invoices",
  all_advance_open_invoices: "all_advance_open_invoices",
  all_advance_old_invoices: "all_advance_old_invoices",
  all_errors: "all_errors",
  checks: "checks",
  items: "items",
  costs: "costs",
  ingredients: "ingredients",
  discounts: "discounts",
  customers: "customers",
  operators: "operators",
  settings: "settings",
  factures: "factures",
  active_orders: "active_orders",
  layouts: "layouts"
};
