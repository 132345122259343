import packageInfo from "../../../package.json";

const ba = {
  oldFiscalError: "Unesite IBFM ili XXXXXXXX-br. računa",
  oldFiscalHelp:"IBFM ili XXXXXXXX-br. računa",
  onlyDecimals: "Možete unijeti samo {{number}} decimalna mjesta.",
  customerIdentification: "Identifikacija Kupca",
  customerIdHelper: "Broj LK ili nekog drugog identifikacionog dokumenta",
  otherPaymentType: "Drugo bezgotovinsko plaćanje",
  optionalBuyerInfo: "Opciono polje kupca",
  closeInvoice: "Zatvori račun",
  cancelInvoice: "Poništi račun",
  issueInvoice: "Izdaj račun",
  reportsEFiscal: "Izvještaji eFiskal",
  analyticsEFiscal: "Analitika eFiskal",
  reportEFactures: "Izvještaji eFakture",
  exitConfirmTitle: "Jeste li sigurni da želite izaći?",
  exitImportProducts: "Svi uvezeni proizvodi bit će izbrisani.",
  uid: "UID",
  dragFileOrOpen: "Povucite fajl ovdje ili kliknite",
  deleteExistingProducts: "Izbriši postojeće proizvode",
  productImport: "Uvoz proizvoda",
  someProductsNotValid: "Neki proizvodi nisu važeći!",
  fileNotImported: "Fajl nije uvezen!",
  changeProductOnBillName: "Izmeni naziv proizvoda na računu",
  changeProductOnBillPrice: "Unesite novu cenu proizvoda",
  changeProductOnBillDiscount: "Unesite popust za proizvod",
  invoiceTranslate: {
    ba: {
      tax: "Porez",
      name: "Naziv",
      total: "Ukupno",
      tin: "JIB",
      address: "Adresa",
      forPayment: "Za plaćanje",
      price: "Cijena",
      pfrTime: "PFR vrijeme",
      esirTime: "ESIR vrijeme:",
      refTime: "ESIR vrijeme:"
    },
    sr: {
      tax: "Porez",
      name: "Naziv",
      total: "Ukupno",
      tin: "JIB",
      address: "Adresa",
      forPayment: "Za plaćanje",
      price: "Cena",
      pfrTime: "PFR vreme",
      esirTime: "ESIR vreme:",
      refTime: "ESIR vreme:"
    }
  },
  loginHelmet: "Prijava | ESIR",
  aboutAppItemsSR: [
    { title: "Proizvođač:", value: "IT Creator SLTS doo, ul. Mileševska 36A, 11000 Beograd" },
    { title: "Serijski broj:", value: "20220128" },
    { title: "Verzija softvera:", value: packageInfo.version + "." + packageInfo.build },
    { title: "Email za podršku:", value: "podrska@it-creator.rs" },
    { title: "Broj podrške:", value: "+381 11 4444 588" },
    { title: "Veb sajt", value: "www.e-fiskal.rs" },
    {
      title: "Korisni linkovi:", value: [
        {
          title: "Korisničko uputstvo za ESIR WIN Fiskal",
          value: "https://it-creator.sr/download/Korisnicko-uputstvo-za-ESIR-WIN-Fiskal.pdf"
        },
        { title: "Funkcije ESIR WIN Fiskal", value: "https://it-creator.sr/download/ESIR-WIN_Fiskal_funkcije.pdf" },
        { title: "Uputstvo za ESIR magacin", value: "https://it-creator.sr/download/ESIR_Magacin_Uputstvo.pdf" },
        {
          title: "Vodič za izdavanje i vezivanje avansnih računa u ESIR WIN Fiskal",
          value: "https://it-creator.sr/download/" +
            "Korisnicko_uputstvo_za_izdavanje_i_vezivanje_avansnih_računa-ESIR-WIN-Fiskal.pdf"
        }
      ]
    }
  ],
  aboutAppItemsBA: [
    { title: "Proizvođač:", value: "IT Creator Solutions doo, ul. Sime Šolaje 1A, 78000 Banja Luka" },
    { title: "Serijski broj:", value: "20240401" },
    { title: "Verzija softvera:", value: packageInfo.version + "." + packageInfo.build},
    { title: "Email za podršku:", value: "podrska@it-creator.ba" },
    { title: "Broj podrške:", value: "051 981 295" },
    { title: "Veb sajt", value: "https://elektronskafiskalizacija.ba/" },
    {
      title: "Korisni linkovi:", value: [
        {
          title: "Korisničko uputstvo za ESIR WIN Fiskal",
          value: "https://it-creator.ba/download/Korisnicko-uputstvo-za-ESIR-WIN-Fiskal.pdf"
        }
      ]
    }
  ],
  loginOperatorHelmet: "Prijava operatera | ESIR",
  operatorsNotFound: "Operateri nisu pronađeni...",
  compensation: "Kompenzacija",
  dragOrSelectFile: "Prevuci ili izaberi datoteku",
  dragFilesHereOrClick: "Prevuci datoteke ovdje ili klikni",
  browse: "pregledaj",
  toAddFileMaxSizeIs: "za dodavanje datoteke. Maksimalna veličina je",
  maxNumberOfImagesIs: "Maksimalan broj slika je",
  deleteImage: "Obriši sliku",
  areYouSureYouWantToDeleteImage: "Da li ste sigurni da želite izbrisati sliku?",
  discount: "Popust",
  compensations: "Kompenzacije",
  dashboard: "Početna",
  factureNumber: "Broj fakture",
  factureDetails: "Detalji fakture",
  paid: "Plaćeno",
  factures: "Fakture",
  facturePreview: "Pregled fakture",
  allFactures: "Sve fakture",
  allInvoices: "Svi računi",
  withCompensation: "Sa kompenzacijom",
  warehouse: "Skladište",
  byCompensation: "Po kompenzaciji",
  unpaid: "Neplaćeno",
  overdue: "Van roka",
  factureNotFound: "Faktura nije pronađena, molimo pokušajte ponovo!",
  total: "Ukupno",
  addCompensation: "Dodaj nadoknadu",
  remainingToPay: "Preostalo za plaćanje",
  invoiceNumber: "Broj računa",
  editExpenses: "Uredi troškove",
  expense: "Trošak",
  save: "Sačuvaj",
  totalPriceExcludingVAT: "Ukupna cijena bez PDV-a",
  totalPriceIncludingVAT: "Ukupna cijena sa PDV-om",
  discountPerProduct: "Popust (po proizvodu)",
  unitPriceExcludingVAT: "Jedinična cijena bez PDV-a",
  unitPriceIncludingVAT: "Jedinična cijena sa PDV-om",
  priceIsPurchase: "Cijena je nabavna",
  rate: "Stopa",
  discounts: "Popusti",
  listExpenses: "Lista troškova",
  edit: "Uredi",
  clear: "Očisti",
  close: "Zatvori",
  newUser: "Novi korisnik",
  active: "Aktivan",
  costLower: "trošak",
  activateLower: "aktiviraj",
  cardNumber: "Broj kartice",
  discountUsers: "Korisnici popusta",
  listOfDiscounts: "Lista popusta",
  deactivateLower: "deaktiviraj",
  areYouSureYouWantTo: "Da li ste sigurni da želite",
  expenseModification: "Izmjena troška",
  activate: "Aktiviraj",
  totalAmount: "Ukupan iznos",
  referenceNumber: "Referentni broj",
  statusModification: "Izmjena statusa",
  deactivate: "Deaktiviraj",
  deactivated: "Deaktiviran",
  copy: "Kopija",
  details: "Detalji",
  training: "Obuka",
  sale: "Prodaja",
  proforma: "Predračun",
  profitExclVAT: "Profit bez PDV-a",
  profitInclVAT: "Profit sa PDV-om",
  linkedAdvanceInvoices: "Povezani avansni računi",
  advance: "Avans",
  cancellation: "Otkazivanje",
  counter: "Brojač",
  invoice: "Račun",
  receptionDate: "Datum prijema",
  issuingTime: "Vrijeme izdavanja",
  productOrServiceName: "Naziv proizvoda ili usluge",
  issuanceDate: "Datum izdavanja",
  transactionType: "Tip transakcije",
  invoiceType: "Tip računa",
  refund: "Refundacija",
  cin: "MB",
  quotations: "Ponude",
  traffic: "Promet",
  stockList: "Lista zaliha",
  invoices: "Računi",
  entrance: "Ulaz",
  paymentDate: "Datum plaćanja",
  paymentDateTime: "Datum i vrijeme plaćanja",
  purchasePriceWithoutVAT: "Nabavna cijena bez PDV-a",
  purchasePriceWithVAT: "Nabavna cijena sa PDV-om",
  purchaseValueWithVAT: "Nabavna vrijednost sa PDV-om",
  purchaseValueWithoutVAT: "Nabavna vrijednost bez PDV-a",
  sellingPriceWithoutVAT: "Prodajna cijena bez PDV-a",
  sellingPriceWithVAT: "Prodajna cijena sa PDV-om",
  sellingValueWithoutVAT: "Prodajna vrijednost bez PDV-a",
  sellingValueWithVAT: "Prodajna vrijednost sa PDV-om",
  purchaseValueOfOutputWithoutVAT: "Vrijednost izlaza bez PDV-a",
  purchaseValueOfOutputWithVAT: "Vrijednost izlaza sa PDV-om",
  outputTaxRate: "Stopa poreza na izlaz",
  lpfr: "L-PFR",
  vpfr: "V-PFR",
  addNew: "Dodaj novi",
  contactEmails: "Kontakt emailovi",
  addOrChangeContactEmail: "Dodaj/Izmijeni kontakt email",
  developerMode: "Režim programera",
  currentAccount: "Tekući račun",
  virtualKeyboard: "Virtuelna tastatura",
  invoiceSettings: "Podešavanja računa",
  trainingModSection: "Sekcija obuke moda",
  rawMaterials: "Sirovine",
  inputTaxRate: "Stopa poreza na ulaz",
  selectTaxRate: "Izaberite stopu poreza!",
  unitPrice: "Jedinična cijena",
  lastPurchasePrice: "Posljednja nabavna cijena",
  currentStock: "Trenutno stanje zaliha",
  unitPriceCalculator: "Kalkulator jedinične cijene",
  group: "Grupa",
  preview: "Pregled",
  cancel: "Otkaži",
  delete: "Obriši",
  action: "Akcija",
  areYouSureYouWantToDelete: "Jeste li sigurni da želite obrisati?",
  payments: "Uplate",
  taxCategory: "Poreska kategorija",
  taxRate: "Stopa poreza",
  aboutApp: "O aplikaciji",
  remove: "Ukloni",
  addPaymentMethod: "Dodaj način plaćanja",
  addPayment: "Dodaj plaćanje",
  paymentMethod: "Način plaćanja",
  totalAmountForPayment: "Ukupan iznos za plaćanje",
  removeAll: "Ukloni sve",
  append: "Dodaj",
  totalPaid: "Ukupno plaćeno",
  toPay: "Za plaćanje",
  turnedOff: "Isključeno",
  inStock: "Na stanju",
  uponExpiration: "Po isteku",
  notAvailable: "Nije dostupno",
  notDefined: "Nije definisano",
  productList: "Lista proizvoda",
  products: "Proizvodi",
  areYouSureToDelete: "Jeste li sigurni da želite obrisati",
  productsQuestion: "proizvod(a)?",
  code: "Interna šifra",
  show: "Prikaži",
  unit: "Jedinica",
  category: "Kategorija",
  state: "Stanje",
  product: "Proizvod",
  posted: "Objavljeno",
  supplier: "Dobavljač",
  paidAmount: "Plaćeni iznos",
  receiptDate: "Datum prijema",
  valueDate: "Datum vrijednosti",
  printing: "Štampanje",
  base: "Osnova",
  sellingPrice: "Prodajna cijena",
  printTablePrompt: "Da li želite odštampati cijelu ili filtriranu tabelu?",
  savedInvoices: "Sačuvani računi",
  printingError: "Greška pri štampanju. Molimo pokušajte ponovo.",
  errorDeletingInvoice: "Greška pri brisanju računa",
  invoiceDeleted: "Račun obrisan",
  errorLoadingSavedInvoices: "Greška pri učitavanju sačuvanih računa",
  itemNumber: "Broj stavki",
  finalInvoice: "Završni račun",
  quantity: "Količina",
  operator: "Operator",
  role: "Uloga",
  creationDate: "Datum kreiranja",
  fiscalize: "Fiskalizuj",
  discountUserModification: "Modifikacija korisnika sa popustom",
  actions: "Akcije",
  newDiscountUser: "Novi korisnik sa popustom",
  errorWhileDeleting: "Greška pri brisanju",
  invoiceNotFound: "Račun nije pronađen",
  discountUser: "Korisnik sa popustom",
  creatingDiscountUser: "Kreiranje korisnika sa popustom",
  username: "Korisničko ime",
  amount: "Iznos",
  issuanceTime: "Vrijeme izdavanja",
  firstNameLastName: "Ime i prezime",
  enterCardNumbers: "Unesite brojeve kartica (pritisnite Enter nakon unosa)",
  status: "Status",
  unableToDeleteDiscount: "Nije moguće izbrisati ovaj popust.",
  phone: "Telefon",
  numberOfProducts: "Broj proizvoda",
  contactPerson: "Kontakt osoba",
  creatingDiscount: "Kreiranje popusta",
  priceWithDiscount: "Cijena sa popustom",
  productOrService: "Proizvod/usluga",
  unitOfMeasure: "Jedinica mjere",
  allowedFormat: "Dozvoljeni format",
  dateFrom: "Datum od",
  customerType: "Tip kupca",
  invoiceCancellation: "Refundacija računa",
  enterIdentificationNumber: "Morate unijeti identifikacioni broj!",
  loading: "Učitavanje",
  submit: "Potvrdi",
  final: "Konačni",
  saved: "Sačuvano",
  dpu: "DPU",
  warehouseManagement: "Upravljanje skladištem",
  representations: "Prikazi",
  calculations: "Izračuni",
  nonFeeTransactions: "Transakcije bez naknade",
  netQuantity: "Neto količina",
  oldFiscalization: "Stara fiskalizacija",
  selectOperator: "Izaberite operatora",
  creatingIngredient: "Kreiranje sastojka",
  supplierName: "Ime dobavljača",
  dateTo: "Datum do",
  logout: "Odjava",
  analytics: "Analitika",
  salesRecords: "Zapisi o prodaji",
  analyticsAndReports: "Analitika i izvještaji",
  ean: "EAN",
  label: "Oznaka",
  initial: "Početno",
  writeOff: "Otpis",
  orderNumber: "Broj narudžbine",
  deletionTime: "Vrijeme brisanja",
  changePasswordRequest: "Zahtjev za promjenom lozinke",
  description: "Opis",
  enterFinalInvoiceDetails: "Unesite detalje konačnog računa",
  lastAdvanceInvoiceNumber: "Posljednji broj avansnog računa",
  createInvoiceOldFiscalization: "Kreiranje računa putem stare fiskalizacije",
  invoiceCreation: "Kreiranje računa",
  invoiceNumberBuyerVat: "Broj računa \\ ID kupca...",
  maximumSizeUpTo: "Maksimalna veličina do",
  productsServices: "Proizvodi/Usluge",
  purchasePrice: "Nabavna cijena",
  enterOrChangeLpfr: "Unesite ili promijenite LPFR",
  taxPreview: "Pregled poreza",
  trainingMode: "Režim obuke",
  turnOnOrOffTrainingMode: "Uključite ili isključite režim obuke",
  activeTaxRates: "Aktivne porezne stope",
  selectRate: "Izaberite stopu",
  saveImage: "Sačuvaj sliku",
  invoiceAlreadyExists: "Račun već postoji pod tim brojem",
  totalSumMismatch: "Ukupna suma proizvoda i načina plaćanja se ne podudara!",
  enterAtLeastOnePaymentMethod: "Morate unijeti barem jedan način plaćanja",
  enterAtLeastOneProduct: "Morate unijeti barem jedan proizvod",
  operatorModification: "Izmjena operatora",
  supplierSuccessfullyDeleted: "Dobavljač uspješno obrisan",
  errorWhileLoadingData: "Greška prilikom učitavanja podataka",
  noData: "Nema podataka",
  choosePaymentMethods: "Izaberite načine plaćanja",
  exportImport: "Izvoz/Uvoz proizvoda",
  customerCard: "Kartica kupca",
  chooseLocation: "Izaberite lokaciju",
  itemsOnBill: "Stavke na računu",
  changeQuantity: "Promijeni količinu",
  productName: "Naziv proizvoda",
  saveInDatabase: "Sačuvaj u bazi podataka",
  changeProductOnBill: "Promijeni proizvod na računu",
  enter: "Unesi",
  trafficSale: "Promet prodaje",
  closeDialog: "Zatvori dijalog",
  clearAllCategories: "Obriši sve kategorije",
  productNotFound: "Proizvod nije pronađen",
  dpuList: "Lista DPU",
  dpuForDate: "DPU za datum",
  serialNumber: "Serijski broj",
  dishAndBeverageName: "Naziv jela i pića za konzumaciju na licu mjesta",
  transferredQuantity: "Preneta količina",
  procuredQuantity: "Nabavljena količina",
  endingStock: "Završna zaliha",
  consumedQuantityDuringDay: "Količina potrošena tokom dana (7-8)",
  unitPriceWithVAT: "Jedinična cijena sa PDV-om",
  servicesRevenue: "Prihod od usluga (9x10)",
  totalRevenueFromDishesAndBeverages: "Ukupni prihod od jela i pića za konzumaciju na licu mjesta (6x10)",
  beveragesRevenue: "Prihod od pića",
  dishesRevenue: "Prihod od jela",
  difference: "Razlika",
  selectSupplier: "Odaberite dobavljača",
  inventory: "Inventar",
  stockListForDate: "Lista zaliha za datum",
  cardOverview: "Pregled kartice",
  expected: "Očekivano",
  measured: "Mjereno",
  listOfCustomers: "Lista kupaca",
  averagePurchasePrice: "Prosječna nabavna cijena",
  totalQuantity: "Ukupna količina",
  suppliers: "Dobavljači",
  bookIt: "Knjiži",
  unBook: "Odknjiži",
  productCard: "Kartica proizvoda",
  id: "ID",
  split: "Podijeli",
  factureWithThisNumberAlreadyExist: "Faktura s ovim brojem već postoji",
  moreThanRemainingPayment: "Unijeli ste više nego što je preostali iznos za plaćanje",
  supplierHasActiveFactures: "Dobavljač ima aktivne fakture. Brisanje nije moguće!",
  openAdvances: "Otvoreni avansi",
  selectAtLeastOneProduct: "Odaberite barem 1 proizvod",
  selectDateAndEnterAmount: "Odaberite datum i unesite iznos po stopi",
  paymentTotalMustMatch: "Ukupan iznos plaćanja mora se poklapati s ukupnim iznosom",
  choosePaymentMethod: "Odaberite način plaćanja",
  youCanOnlyEnterNumbers: "Možete unijeti samo brojeve",
  atLeast7Characters: "Mora imati najmanje 7 karaktera",
  exportError: "Greška pri izvozu. Pokušajte ponovo.",
  fieldIsRequired: "Polje je obavezno",
  successfullyModifiedIngredient: "Sastojak uspješno izmijenjen",
  successfullyCreatedIngredient: "Sastojak uspješno kreiran",
  errorCreatingIngredient: "Greška pri kreiranju sastojka",
  errorModifyingIngredient: "Greška pri izmjeni sastojka",
  print: "Štampaj",
  addingProductService: "Dodavanje proizvoda/usluge",
  factureEdit: "Izmjena fakture",
  purchasePriceExclVAT: "Nabavna cijena bez PDV-a",
  companyRegistrationNumber: "Matični broj",
  jbkjs: "jbkjs",
  accountant: "Računovođa",
  item: "Proizvod",
  settings: "Podešavanja",
  editIngredient: "Izmjena sastojka",
  accountSettings: "Podešavanja računa",
  myInvoices: "Moje fakture",
  companyName: "Naziv kompanije",
  filteredTable: "Filtrirana tabela",
  export: "Izvoz",
  download: "Preuzmi",
  pibNotFoundInAPR: "JIB nije pronađen",
  exportTablePrompt: "Želite li izvesti cijelu ili filtriranu tablicu?",
  supplierWithEnteredPIBExists: "Dobavljač s unesenim JIB-om već postoji!",
  supplierSuccessfullyModified: "Dobavljač uspješno izmijenjen",
  supplierSuccessfullyAdded: "Dobavljač uspješno dodan",
  enterPIBToFillInData: "Unesite JIB kako biste popunili podatke automatski",
  addAtLeastOneProduct: "Dodajte barem jedan proizvod",
  minimumQuantityIs1: "Minimalna količina je 1",
  invalidDate: "Nevažeći datum",
  invalidDateFrom: "Nevažeći datum od",
  invalidDateTo: "Nevažeći datum do",
  expenses: "Troškovi",
  totalBase: "Ukupno osnova",
  rawMaterial: "Sirovina",
  totalRebate: "Ukupan popust",
  ingredientRatio: "Odnos sastojaka",
  totalVAT: "Ukupni PDV",
  writeOffTime: "Vrijeme otpisa",
  forPayment: "Za plaćanje",
  normative: "Normativ",
  addExpense: "Dodaj trošak",
  addDiscount: "Dodaj popust",
  addIngredient: "Dodaj sastojak",
  addUser: "Dodaj korisnika",
  createFacture: "Kreiraj fakturu",
  newIngredient: "Novi sastojak",
  ingredients: "Sastojci",
  ingredient: "Sastojak",
  addProduct: "Dodaj proizvod",
  newProduct: "Novi proizvod",
  enterProductFirst: "Prvo morate unijeti proizvod",
  enterIngredientFirst: "Prvo morate unijeti sastojak",
  enterExpenseFirst: "Prvo morate unijeti trošak",
  reportByFilter: "Izvještaj po filteru",
  change: "Kusur",
  publishing: "Omogući izdavanje",
  select: "Odaberi",
  createdBy: "Kreirao",
  hide: "Sakrij",
  additionalOptions: "Dodatne opcije",
  newExpense: "Novi trošak",
  creatingClientSupplier: "Kreiranje klijenta/dobavljača",
  expenseCreation: "Kreiranje troška",
  selectAccounts: "Odaberite račune",
  errorOccurred: "Došlo je do greške!",
  clientSupplier: "Klijent/Dobavljač",
  clientsCustomers: "Kupci",
  reports: "Izvještaji",
  fullScreen: "Puni ekran",
  minimize: "Minimiziraj",
  syncCompleted: "Sinkronizacija za odabrani period završena",
  syncError: "Greška prilikom sinkronizacije",
  owe: "Duguje",
  time: "Vrijeme",
  searchProduct: "Pretraži proizvod",
  searchClientSupplier: "Pretraži klijenta ili dobavljača",
  date: "Datum",
  table: "Sto",
  claims: "Potražuje",
  balance: "Bilans",
  licenseDuration: "Trajanje licence",
  note: "Napomena",
  icons: "Ikone",
  codes: "Interne šifre",
  viewItems: "Pogledaj stavke",
  chooseItemDisplay: "Izaberite željeni prikaz stavke",
  database: "Baza podataka",
  notValidEmail: "nije validna email adresa.",
  writtenOffItems: "Stavke koje su otpisane",
  editSupplier: "Izmjeni dobavljača",
  inventoryHistory: "Istorija popisa",
  newClientSupplier: "Novi klijent/dobavljač",
  newEditClientSupplier: "Novi/izmeni klijenta/dobavljača",
  errorLoadingData: "Greška pri učitavanju podataka",
  costReport: "Izvještaj o troškovima",
  ingredientNotFound: "Sastojak nije pronađen, molimo pokušajte ponovo!",
  passwordUsernameUsedByAnotherOperator: "Ova lozinka ili korisničko ime već se koristi od strane drugog operatera.",
  listOfClientsSuppliers: "Lista klijenata/dobavljača",
  clientSupplierModification: "Izmjena klijenta/dobavljača",
  selectAtLeastOneAccount: "Izaberite barem 1 nalog",
  enterSearchParameter: "Unesite parametar pretrage",
  errorDuringSearch: "Greška tokom pretrage!",
  unableToRetrieveGroups: "Nije moguće pronaći grupe!",
  unableToRetrieveOperators: "Nije moguće pronaći operatere!",
  totalSalesForThisPeriod: "Ukupna prodaja za ovaj period",
  totalRefundForThisPeriod: "Ukupna refundacija za ovaj period",
  totalEarningsForThisPeriod: "Ukupna zarada za ovaj period",
  annualTurnover: "Godišnji promet",
  totalSoldFoodBeveragesOther: "Promet po načinu plaćanja",
  food: "Hrana",
  remaining: "Preostalo",
  createNew: "Kreiraj",
  list: "Lista",
  itemExample: "Primer stavke",
  notValid: "Nije validno",
  itemName: "Ime stavke",
  issueDate: "Datum izdavanja",
  priceWithVAT: "Cena sa PDV-om",
  dateAndTime: "Datum i vrijeme",
  selectPaymentMethod: "Izaberite način plaćanja",
  closeOrMergeAdvanceInvoices: "Zatvaranje ili nadovezivanje avansnih računa",
  selectDateAndAmountPerRate: "Izaberite datum i unesite iznos po stopi",
  totalAmountMismatch: "Ukupan iznos po stopi PDV-a ne odgovara ukupnom iznosu načina plaćanja!",
  unableToFindInvoice: "Nije moguće pronaći račun! Kontaktirajte podršku.",
  priceSuccessfullyUpdated: "Cijena uspješno ažurirana",
  errorUpdatingPrice: "Greška pri ažuriranju cijene!",
  appendingOrIssuingFinal: "Nadoveži ili izdaj konačni račun",
  productViewEdit: "Pregled/izmena proizvoda",
  invoiceOverview: "Pregled računa",
  invalidInvoiceContactSupport: "Faktura nije validna. Molimo kontaktirajte podršku!",
  totalProductAmountCannotBeLess: "Ukupan iznos proizvoda ne može biti manji od plaćenog iznosa!",
  exampleAdvanceInvoiceOldFiscalization: "Primer avansnog računa prethodne fiskalizacije",
  noAccountsAssociatedWithInvoice: "Trenutno nema povezanih naloga sa ovom fakturim",
  noInvoices: "Nema računa",
  newPrice: "Nova cijena",
  invoiceList: "Lista računa",
  itemOverviewEdit: "Pregled/izmena proizvoda",
  successfullyCompletedCompensation: "Uspješno ste dodali kompenzaciju",
  successfullyRemovedCompensation: "Uspješno ste uklonili kompenzaciju",
  tax: "Porez",
  taxExample: "Grupa: A Porez: 0%",
  clients: "Klijenti",
  priceEdit: "Izmjena cijene",
  exit: "Izlaz",
  error: "Greška",
  confirm: "Potvrdi",
  search: "Pretraga",
  tin: "JIB",
  invoiceFrom: "Račun od",
  paymentCurrency: "Valuta plaćanja",
  type: "Tip",
  accountNumber: "Broj računa",
  months: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
  beverage: "Piće",
  other: "Ostalo",
  cash: "Gotovina",
  card: "Platna kartica",
  check: "Ček",
  wireTransfer: "Prenos na račun",
  voucher: "Vaučer",
  mobileMoney: "Instant placanje",
  cashier: "Kasir",
  country: "Država",
  sales: "Prodaja",
  top: "Top",
  email: "Email",
  address: "Adresa",
  city: "Grad",
  user: "Korisnik",
  number: "Broj",
  totalSales: "Ukupna prodaja",
  totalRefund: "Ukupno povraćaj",
  password: "Šifra",
  addingSupplier: "Dodavanje dobavljača",
  signOut: "Odjavi me",
  deleting: "Brisanje",
  accept: "Prihvati",
  chosen: "Izabrano",
  theme: "Tema",
  new: "Novo",
  old: "Staro",
  youHave: "Imate",
  unreadMessages: "nepročitane poruke",
  MarkAllAsRead: "Označi sve kao pročitano",
  surname: "Prezime",
  notifications: "Obaveštenja",
  showAll: "Prikaži sve",
  jsonFileInterrupted: "Json fajl prekinut",
  importSuccess: "Uspešan import",
  downloadJsonFileErrors: "Preuzmi fajl sa neuspelim json objektima",
  importErrors: "Greške pri importu",
  dragFileHereOrClick: "Prevuci fajl ovde ili klikni",
  invoiceImport: "Uvoz računa",
  chooseLightOrDark: "Izaberi svijetlu ili tamnu temu",
  successSaved: "Uspešno sačuvano",
  passwordResetLinkSentSuccessfully: "Link za resetovanje šifre uspešno poslan",
  adjustAppearanceInvoice: "Prilagodi izgled svog računa",
  productDontHaveId: "Proizvod nema ID",
  startProducts: "Početni proizvodi",
  advertisingText: "Reklamni tekst",
  printLogo: "Štampaj logo",
  printTestInvoice: "Štampaj uzorak računa",
  turnOnOrOffVirtualKeyboard: "Uključi ili isključi virtuelnu tastaturu",
  searchProductsServices: "Pretraži proizvode/usluge",
  signIn: "Prijavi se",
  enterFullVATID: "Unesite puni JIB da biste pronašli novog kupca",
  confirmDelete: "Da li ste sigurni da želite da obrišete?",
  operators: "Operateri",
  addOperator: "Dodaj operatera",
  customerList: "Lista kupaca",
  listOperators: "Lista operatera",
  exampleSignInToThePortal: "Primer prijave na portal",
  passwordIsRequired: "Šifra je obavezna!",
  invalidCredentials: "Uneti podaci nisu validni!",
  emailIsRequired: "Email je obavezan!",
  emailFormatValidation: "Email adresa mora biti u validnom formatu!",
  thanLastMonth: " od prošlog mjeseca",
  topSellingProductsForThisMonth: "Najprodavaniji proizvodi za izabrani period",
  topOperatorsForThisMonth: "Operateri za izabrani period",
  trafficAnalytics: "Analitika",
  errorMessage: "Došlo je do greške",
  listOfOperators: "Lista operatera",
  addCustomers: "Dodaj kupce",
  newOperator: "Novi operater",
  customerDatabase: "Kupci",
  inactive: "neaktivan",
  creatingOperator: "Kreiranje operatera",
  basic: "Osnova",
  name: "Naziv",
  all: "Svi",
  add: "Dodaj",
  price: "Cijena",
  profile: "Profil",
  advertisements: "Reklame",
  categories: "Kategorije",
  bill: "Račun",
  ppProducts: "proizvode",
  noteIsRequired: "Napomena je obavezna!",
  printA4: "A4 format",
  imageMustBeIn169Format: "Slika mora biti u 16:9 formatu",
  noSearchResult: "Nema rezultata pretrage",
  noSearchResultFor: "Nema pronađenih reuzultata pretrage za",
  pleaseCheckInput: "Molimo Vas provjerite da li je unos ispravan",
  enterKeyword: "Unesite ključne riječi",
  issueAgin: "Izdaj ponovo",
  chooseItems: "Izaberite proizvode",
  issue: "Izdaj",
  forbidden: "Zabranjen pristup",
  noAcces: " Nemate dozvolu za ovu stranicu",
  cinJmbg: "MB/JMBG",
  choose: "Izaberite",
  searchPages: "Pretražite stranicu",
  esirNumber: "ESIR broj",
  issuingPlace: "Mesto izdavanja",
  cannotLoadLocations: "Nije moguće učitati lokacije",
  cannotLoadTaxRates: "Nije moguće učitati porezne stope",
  cannotLoadSettings: "Nije moguće učitati podešavanja",
  cannotLoadBankAccount: "Nije moguće učitati tekući račun",
  cannotLoadUserImage: "Nije moguće učitati logo",
  cannotLoadAds: "Nije moguće učitati reklamni tekst",
  invoiceSyncError: "Nije moguće sinhronizovati račune",
  locationSuccessAdded: "Uspešno dodijeljena lokacija",
  enterNewName: "Unesite novi naziv",
  enterNewPrice: "Unesite novu cijenu",
  a4Preview: "Pregled a4 formata",
  a4Print: "Štampanje a4 formata",
  company: "Kompanija",
  location: "Lokacija",
  district: "Opština",
  productOrProducts: "proizvod/a",
  loadingData: "Učitavanje podataka",
  discountEdit: "Izmena popusta",
  deleted: "Obrisan",
  cannotIssueThisInvoice: "Ne možete ponovo izdati ovaj račun",
  itemsCodeDescription: "(KOLIČINA * ŠIFRA PROIZVODA)",
  newProductService: "Nova proizvod/usluga",
  alreadyHaveCode: "Već postoji proizvod/usluga sa ovom internom šifrom",
  addNormative: "Dodaj normativ",
  sold: "Prodano",
  refunded: "Refundirano",
  yearSell: "Godišnja prodaja",
  contact: "Kontakt",
  jbkjc: "JBKJS",
  eFactures: "eFakture",
  exitInvoices: "Izlazne fakture",
  newExitInvoice: "Nova izlazna faktura",
  incomingInvoices: "Ulazne fakture",
  individualRecords: "Pojedinačni zapisi",
  newIndividualRecord: "Novi pojedinačni zapis",
  summaryRecords: "Sažeti zapisi",
  newSummaryRecord: "Novi sažeti zapis",
  customerAlreadyExist: "Već postoji kupac sa ovim JIB/MB brojem",
  a4Email: "Email za prikaz na A4 formatu",
  companyInfo: "Informacije o kompaniji",
  basicInfo: "Osnovne informacije",
  saveBasicInfo: "Sačuvaj osnovne informacije",
  saveCompanyInfo: "Sačuvaj informacije o kompaniji",
  yes: "Da",
  no: "Ne",
  overview: "Pregled",
  allF: "Sve",
  totalTaxForThisPeriod: "Ukupni PDV za ovaj period",
  totalBasicForThisPeriod: "Ukupna osnovica za ovaj period",
  editProductService: "Izmjena proizvoda/usluge",
  chooseProductService: "Odaberite proizvod/uslugu",
  reportFor: "Izvještaj za",
  month: "Mjesec",
  year: "Godina",
  itemReport: "Izvještaj o proizvodu",
  downloadExcel: "Preuzmi Excel",
  downloadImage: "Preuzmi sliku",
  dateFromNotValid: "Datum od nije važeći",
  dateToNotValid: "Datum do nije važeći",
  dateToBeforeFrom: "Datum od je prije datuma do",
  ingName: "Naziv sastojka",
  taxVat: "Porezna stopa",
  dateOfIssue: "Datum izdavanja",
  kitchen: "Kuhinja",
  bar: "Bar",
  bartender: "Šanker",
  driver: "Vozač",
  parking: "Parking",
  adminTourist: "Admin Turista",
  operatorTourist: "Operater Turista",
  waiter: "Konobar",
  chef: "Kuvar",
  admin: "Administrator",
  manager: "Menadžer",
  services: "Usluge",
  eFactureAdmin: "eFakture Administrator",
  eFactureExitDocuments: "eFakture Izlazna dokumenta",
  eFactureEnterDocuments: "eFakture Ulazna dokumenta",
  eFactureUser: "eFakture Korisnik",
  eFactureRevisor: "eFakture Revizor",
  goods: "Roba",
  units: ["kom", "kg", "km", "g", "l", "tona", "m2", "m3", "minut", "h", "dan", "mjesec", "godina", "par", "sum", "ostalo"],
  licenseExpiredMessage: "Vaša licenca je istekla zbog neplaćanja.",
  callTenantMessage: "Molimo vas da kontaktirate svog dobavljača.",
  mustBeGreaterThen: "Mora biti veće od 0",
  oneItemRequired: "Morate odabrati barem jedan proizvod",
  productIng: "Proizvod/Sastojak",
  marza: "Marža",
  ruc: "RUC",
  logoutError: "Greška prilikom odjave",
  createWriteOff: "Kreiraj otpis",
  maxSessionMessage: "Dosegnut je maksimalan broj aktivnih sesija.",
  file: "Datoteka",
  alreadyExist: "Već postoji!",
  csvInvoice: "Uvoz CSV računa",
  oneFileRequired: "Morate odabrati barem jednu .csv datoteku!",
  priceWithDiscountWithoutTax: "Cijena sa popustom bez PDV-a",
  noOnState: "Nema na stanju",
  expiration: "Istek",
  oneOf: "Jedan od",
  fileIsNotValid: "Datoteka nije važeća!",
  validate: "Validiraj",
  profit: "Profit",
  withTax: "Sa PDV-om",
  withoutTax: "Bez PDV-a",
  periodFrom: "Period od",
  period: "Period",
  filter: "Filter",
  chooseDates: "Odaberite datume",
  chooseMinPeriod: "Odaberite manji period!",
  stateOverview: "Dnevni presjek stanja",
  yesterdayOverview: "Jučerašnji presjek stanja",
  overviewByDate: "Periodični izvještaj",
  totalInvoiceNumber: "Ukupan broj izdatih računa",
  JID: "JID",
  trafficByLocation: "Promet po lokaciji",
  saleProduct: "Prodani proizvodi/usluge",
  refundProduct: "Refundirani proizvodi/usluge",
  paymentTypes: "Načini plaćanja",
  cashiers: "Blagajnici",
  taxByVat: "Porez po PDV-u",
  summary: "Sažetak",
  taxBalance: "Saldo poreza",
  totalTaxForRefund: "Ukupan porez za povrat",
  totalTaxForSale: "Ukupan porez za prodaju",
  totalSaleBalance: "Ukupni saldo prodaje",
  totalRefundBalance: "Ukupni saldo povrata",
  totalAmountOfSale: "Ukupni iznos prodaje",
  firmName: "Naziv firme",
  zipCode: "Poštanski broj",
  reportForPeriod: "Izvještaj za period",
  to: "do",
  reportDetails: "Detalji izvještaja",
  sellItems: "Prodani proizvodi",
  errorWithExport: "Greška prilikom izvoza. Pokušajte ponovo.",
  report: "Izvještaj",
  allReport: "Ceo izvještaj",
  allDetails: "Svi detalji",
  partialReport: "Djelimičan izvještaj",
  onlyBalance: "Samo saldo",
  reportB: "Izvještaj",
  tehnicalSupport: "Tehnička podrška je dostupna: ",
  signInTitle: "Prijavite se na WIN Fiskal",
  welcomeText: "Dobrodošli na WIN Fiskal - Fiskalizacija koja vam je potrebna",
  detailedOn: "Detalji na: ",
  or: " ili ",
  percentage: "Postotak",
  inputDiscount: "Unesite popust u ",
  enterDiscount: "Unesite iznos popusta",
  newPriceCannotBeBelovedZero: "Nova cijena ne može biti manja od nule!",
  checkOnline: "Provjerite online",
  notFound: "Nije pronađeno",
  prepTime: "Vrijeme pripreme",
  qtyForAlarm: "Količina za upozorenje",
  productType: "Tip proizvoda",
  disableDiscount: "Onemogući popust",
  turnOffProduct: "Isključi proizvod",
  productIsNormative: "Proizvod je normativan",
  productHaveNormative: "Proizvod ima normativ",
  addProductNormativeDesc: "Ako odaberete normativ, količina se ne dodaje za proizvod.",
  draft: "Nacrt",
  recorded: "Zabilježeno",
  replaced: "Zamijenjeno",
  cancelled: "Otkazano",
  statusChangeDate: "Datum promjene statusa",
  success: "Uspješno",
  send: "Pošalji",
  russian: "Ruski",
  serbian: "Srpski",
  english: "Engleski",
  customers: "Kupci",
  referrals: "Preporuke",
  commingSoon: "Uskoro",
  prevousMonthPrecentage: "u odnosu na prethodni mjesec",
  lastSaleTransactions: "Posljednje prodajne transakcije",
  trafficExitFactures: "Promet izlaznih faktura",
  trafficEnterFactures: "Promet ulaznih faktura",
  permissionDenied: "Dozvola odbijena",
  notHavePermission: "Nemate dozvolu za pristup ovoj stranici",
  back: "Nazad",
  connect: "Poveži se"
};

export default ba;
