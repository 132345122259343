import { collection, getDocs, query, Timestamp, where } from "firebase/firestore";
import { AUTH, DB } from "../../../auth/FirebaseContext";
import moment from "moment";

export async function getFacturesBySupplierUid(supplierUid) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = await query(collection(DB, `factures/users/${AUTH.currentUser.uid}`),
        where("supplierUid", "==", supplierUid));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          uid: doc.id
        });
      });
      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}

export async function getAllFacturesByEndDateFromFirestore(endDate) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = await query(collection(DB, `factures/users/${AUTH.currentUser.uid}`),
        where("dateOfIssue", "<=", Timestamp.fromDate(endDate)));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          uid: doc.id
        });
      });
      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}

export async function getAllFacturesByDateFromFirestore(startDate, endDate) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = await query(collection(DB, `factures/users/${AUTH.currentUser.uid}`),
        where("dateOfIssue", ">", Timestamp.fromDate(startDate)),
        where("dateOfIssue", "<=", Timestamp.fromDate(endDate)));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          id: doc.id
        });
      });
      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}

export async function getAllFacturesByProductUid(productUid) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];

      const q = await query(collection(DB, `factures/users/${AUTH.currentUser.uid}`),
        where("itemsIds", "array-contains", productUid));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          id: doc.id
        });
      });
      arr = arr.sort((a, b) => moment(a.dateOfIssue.toDate()).isBefore(moment(b.dateOfIssue.toDate())) ? 1 : -1);
      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}
