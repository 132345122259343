import {putInvoiceOffline, putLogToFirebase} from "../../store/offlineDb";
import {doc, setDoc} from "firebase/firestore";
import {AUTH, DB} from "../../auth/FirebaseContext";
import {getTimestampFromSdc} from "./index";
import {PAYMENT_TYPE_ARRAY} from "../../constants";

const convertInvoiceType = (data) => {
  switch (data) {
    case "Normal":
      return "0";
    case "Proforma":
      return "1";
    case "Copy":
      return "2";
    case "Training":
      return "3";
    case "Advance":
      return "4";
    default:
      return "-1";
  }
};
const convertTransactionType = (data) => {
  switch (data) {
    case "Sale":
      return "0";
    case "Refund":
      return "1";
    default:
      return "-1";
  }
};
const convertPaymentMethod = (data) => {
  return data.map(obj => ({
    ...obj, paymentType: `${PAYMENT_TYPE_ARRAY.indexOf(obj.paymentType)}`
  }));
};
const getItemsFromData = (items) => {
  return items.map(item => ({
    uid: item.uid || null,
    labels: item.labels || null,
    name: item.name || null,
    quantity: Number(item.quantity),
    restaurantGroup: item.restaurantGroup || null,
      discountValue: Number(item.discountValue) || null,
    restaurantItemsCategory: item.restaurantItemsCategory || null,
    totalAmount: Number(item.totalAmount),
    unit: item.unit || null,
    unitPrice: Number(item.unitPrice)
  }));
};

export async function insertIntoFirestore(data, response) {
  const timestamp = getTimestampFromSdc(response.sdcDateTime);
  if (typeof data === "string") {
    data = JSON.parse(data);
  }
  try {
    const invoiceType = convertInvoiceType(data.invoiceType);
    const transactionType = convertTransactionType(data.transactionType);
    const paymentMethod = convertPaymentMethod(data.payment);
    const itemsFromData = getItemsFromData(data.items);
    let obj = {
      dateAndTimeOfIssue: data.dateAndTimeOfIssue || null,
      buyerCostCenter: data.buyerCostCenter || null,
      buyerTin: data.buyerId || null,
      cashier: data.cashier,
      invoiceNumber: response.invoiceNumber,
      invoiceType: invoiceType,
      items: itemsFromData,
      paymentMethod: paymentMethod,
      referentDocumentDT: data.referentDocumentDT || null,
      referentDocumentNumber: data.referentDocumentNumber || null,
      sdcDateTime: response.sdcDateTime,
      taxItems: response.taxItems,
      totalAmount: response.totalAmount,
      transactionType: transactionType,
      internalData: {
        ...data.internalData,
        client: "web"
      }
    };
    await putInvoiceOffline({
      ...obj,
      isInsertedOffline: true,
      invoiceType: data.invoiceType,
      paymentMethod: data.payment,
      transactionType: data.transactionType
    });
    //INVOICE
    const colRef = doc(DB, "invoices", "users", `${AUTH.currentUser.uid}`, timestamp);
    await setDoc(colRef, obj, { merge: true });
  } catch (e) {
    console.error("e", e);
    putLogToFirebase("insertIntoFirestore", "helper/invoice", [data, response], e?.toString());
    throw new Error("7533");
  }
}
