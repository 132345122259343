import PropTypes from "prop-types";
// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import { CircularProgress, InputAdornment, TextField } from "@mui/material";

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  name: PropTypes.string
};

export default function RHFSelect({ name, children, loading = false, ...other }) {
  const { control } = useFormContext();

  if (loading) {
    return (
      <TextField
        size="small"
        disabled
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CircularProgress size={25} />
            </InputAdornment>
          )
        }}
        {...other}/>
    );
  }

  return (
    <Controller
      defaultValue=""
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          size="small"
          {...field}
          select
          fullWidth
          SelectProps={{ native: true }}
          error={!!error}
          helperText={error?.message}
          {...other}>
          {children}
        </TextField>
      )}
    />
  );
}
