import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { Box, Drawer, Stack, Typography } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// config
import { NAV } from "../../../config";
// components
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
//
import navConfig from "./config";

import NavAccount from "./NavAccount";
import { useAuthContext } from "../../../auth/useAuthContext";
import useActiveLink from "../../../hooks/useActiveLink";
import { PATH_DASHBOARD } from "../../../routes/path";
import StatusIndicator from "../StatusIndicator";
import { useLocales } from "../../../locales";

// ----------------------------------------------------------------------

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func
};

export default function NavVertical({ openNav, onCloseNav }) {

  const { pathname } = useLocation();
  const { translate } = useLocales();

  let isDesktop = useResponsive("up", "lg");
  const { active: activeMain } = useActiveLink(PATH_DASHBOARD.general.app);
  const { active: activeReferals } = useActiveLink(PATH_DASHBOARD.referrals.root);

  const active = activeReferals || activeMain;

  if (active) {
    isDesktop = false;
  }

  const { user } = useAuthContext();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    
  }, [pathname]);


  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column"
        }
      }}>

      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0
        }}>
        {/*<Logo />*/}
        <Typography sx={{ ml: 1, fontWeight: 700 }}>{user.displayName}</Typography>
        <NavAccount />
        <StatusIndicator />
      </Stack>

      <NavSectionVertical data={navConfig(translate)} />

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        display: active ? "none" : null,
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD }
      }}>
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
              bgcolor: "transparent",
              borderRightStyle: "dashed"
            }
          }}>
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD
            }
          }}>
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
