import { useLayoutEffect, useState } from "react";
import { IconButtonAnimate } from "../../../components/animate";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import Iconify from "../../../components/Iconify";
import MenuPopover from "../../../components/MenuPopover";
import Scrollbar from "../../../components/Scrollbar";
import { fToNow } from "../../../utils/formatTime";
import PropTypes from "prop-types";
import {
  fetchAllUserMessages,
  setAllNotificationAsRead,
  setNotificationAsRead
} from "../../../redux/slices/notifications";
import { dispatch } from "../../../redux/store";
import { useSelector } from "react-redux";
import TextMaxLine from "../../../components/text-max-line";
import { noCase } from "change-case";
import { translate } from "../../../locales/i18n";
import { useLocales } from "../../../locales";
import { useSnackbar } from "notistack";
import AllNotificationsDialog from "../../../components/allNotificationsDialog";

export default function NotificationsPopover() {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const { messages, loading } = useSelector((state => state.notifications));

  const [openDialog, setOpenDialog] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);
  const [openMessage, setOpenMessage] = useState({ open: false, data: null });

  const totalUnRead = messages.filter((item) => item.isRead !== true).length;
  const open = Boolean(openPopover);

  useLayoutEffect(() => {
    if (messages.length === 0) {
      dispatch(fetchAllUserMessages()).unwrap().catch(reason => {
        console.error("fetchAllCustomers", reason);
        enqueueSnackbar(translate("errorLoadingData"), { variant: "error" });
      });
    }
  }, [messages.length]);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    const arr = messages.map(msg => ({ ...msg, isRead: true }));
    dispatch(setAllNotificationAsRead(arr)).unwrap().then(() => {
      enqueueSnackbar(translate("successSaved"), { variant: "success" });
    }).catch(reason => {
      console.error("setAllNotificationAsRead", reason);
      enqueueSnackbar(translate("error"), { variant: "error" });
    });
  };

  const handleCloseMessage = () => {
    setOpenMessage({ open: false, data: null });
  };

  const handleSeeAll = () => {
    handleClosePopover();
    setOpenDialog(true);
  };

  const handleCloseSeeAll = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? "primary" : "default"}
        onClick={loading ? null : handleOpenPopover}
        sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          {loading ? <CircularProgress size={20} /> :
            <Iconify icon="eva:bell-fill" />
          }
        </Badge>
      </IconButtonAnimate>

      <MenuPopover openPopover={openPopover} open={open} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate("notifications")}</Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {translate("youHave")} {totalUnRead} {translate("unreadMessages")}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title={translate("MarkAllAsRead")}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" }, maxHeight: { xs: 500, sm: 600 } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: "overline" }}>
                {translate("new")}
              </ListSubheader>
            }>
            {messages.slice(0, 2).map((notification, index) => notification.isRead !== true &&
              <NotificationItem
                dispatch={dispatch}
                closePopover={handleClosePopover}
                openMessage={openMessage}
                setOpenMessage={setOpenMessage}
                key={`new-notification-${notification.uid}-${index}`}
                notification={notification} />)}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: "overline" }}>
                {translate("old")}
              </ListSubheader>
            }>
            {messages.slice(0, 5).map((notification, index) => notification.isRead === true &&
              <NotificationItem
                key={`readed-notification-${notification.uid}-${index}`}
                notification={notification}
                setOpenMessage={setOpenMessage}
                closePopover={handleClosePopover} />)}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={handleSeeAll}>
            {translate("showAll")}
          </Button>
        </Box>
      </MenuPopover>
      {openDialog && <AllNotificationsDialog open={openDialog} messages={messages} onClose={handleCloseSeeAll} />}
      {openMessage.open && <NotificationDialog message={openMessage} onClose={handleCloseMessage} />}
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    avatar: PropTypes.node,
    title: PropTypes.string,
    isUnRead: PropTypes.bool,
    date: PropTypes.string
  })
};

function NotificationItem({ notification, setOpenMessage, closePopover }) {
  const { avatar, title } = renderContent(notification);

  const handleOpen = () => {
    setOpenMessage({ open: true, data: notification });
    dispatch(setNotificationAsRead(notification));
    closePopover();
  };

  return (
    <ListItemButton
      onClick={handleOpen}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected"
        })
      }}>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: "caption", color: "text.disabled" }}>
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">{fToNow(notification.date)}</Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {

  const title = (
    <Typography variant="subtitle2">
      {notification?.title.toString()}
      <Typography component="span" variant="body2" sx={{ color: "text.secondary" }}>
        &nbsp; {noCase(notification?.body.toString())}
      </Typography>
      <TextMaxLine variant={"body2"} line={2} persistent>
        {notification?.body.toString()}
      </TextMaxLine>
    </Typography>
  );

  if (notification?.type === undefined) {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
      title
    };
  }
}

function NotificationDialog({ message, onClose, ...other }) {

  return (
    <Dialog fullWidth maxWidth="xs" open={message.open} onClose={onClose} {...other}>
      <DialogTitle>{message.data.title}</DialogTitle>
      {message.data.body && <DialogContent sx={{ typography: "body2" }}> {message.data.body} </DialogContent>}

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          {translate("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
