import axios from "axios";
import { HOST_API_KEY } from "../config";
import { AUTH } from "../auth/FirebaseContext";
import { getSessionId } from "../helper/session";

const customAxios = (token) => axios.create({
  baseURL: `${HOST_API_KEY}/esir`,
  headers: {
    "Accept": "application/json",
    "Authorization": `Bearer ${token}`
  }
});

export async function createUserSessionApi(data) {
  const token = await AUTH.currentUser.getIdToken(true);
  return customAxios(token).post(`/session`, data);
}

export async function deleteUserSessionApi() {
  const token = await AUTH.currentUser.getIdToken(true);
  return customAxios(token).delete(`/session?sessionID=${getSessionId()}&licenseType=ESIR`);
}
