import PropTypes from "prop-types";
import { List, Stack } from "@mui/material";
import { StyledSubheader } from "./styles";
import NavList from "./NavList";
import FullScreenOptions from "../../../layouts/dashboard/nav/FullScreenOptions";
import { getSessionOperator } from "../../../helper/session";
import { useAppSettings } from "../../../context/AppSettings";
import { HIDDEN_PAGES_BH } from "../../../constants";

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array
};

const filterForBH = (items) => {
  const newItems = [];
  for (const item of items) {
    if (!HIDDEN_PAGES_BH.includes(item.path)) {
      const children = item.children;
      newItems.push({
        ...item,
        children: children ? children.filter(value => !HIDDEN_PAGES_BH.includes(value.path)) : undefined
      });
    }
  }
  return newItems;
};

const filterRoles = (items, role) => {
  const newItems = [];
  for (const item of items) {
    const children = item.children;
    if (!item.roles || item?.roles?.includes(role)) {
      newItems.push({
        ...item,
        children: children ? children.filter(value => {
          if (!value.roles) {
            return true;
          }
          return value.roles.includes(role);
        }) : undefined
      });
    }
  }
  return newItems;
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const operator = getSessionOperator();
  const { IS_BH } = useAppSettings();
  if (IS_BH) {
    data = data.filter(menu => !HIDDEN_PAGES_BH.includes(menu.path)).map(menu => ({
      ...menu,
      items: filterForBH(menu.items)
    }));
  }
  data = data.map(menu => ({
    ...menu,
    items: filterRoles(menu.items, operator?.group)
  })).filter(menu => menu.items.length > 0);

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>}

            {group.items.map((list) => (
              <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
            ))}
          </List>
        );
      })}
      <FullScreenOptions />
    </Stack>
  );
}
