import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { child, get, push, remove, set } from "firebase/database";

export const fetchAllIngredientsCategories = createAsyncThunk("fetchAllIngredientsCategories", async () => {
  let arr = [];
  (await get(child(dbRef, `/public/categories/ingredients`))).forEach((child1) => {
    arr.push(child1.val());
  });
  return arr;
});

export const fetchAllIngredients = createAsyncThunk("fetchAllIngredients", async () => {
  let arr = [];
  (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients`))).forEach((child1) => {
    arr.push({
      ...child1.val(),
      uid: child1.key
    });
  });
  return arr;
});

export const addNewIngredient = createAsyncThunk("addNewIngredient", async (data) => {
  if (data.uid) {
    return set(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${data.uid}`),
      {
        name: data.name || "",
        category: data.category || "",
        unit: data.unit || "",
        quantity: data.quantity || 0,
        vat: data.vat || "",
        uid: data.uid
      });
  } else {
    return push(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients`),
      {
        name: data.name || "",
        category: data.category || "",
        unit: data.unit || "",
        quantity: data.quantity || 0,
        vat: data.vat || ""
      });
  }
});

export const updateIngredient = createAsyncThunk("updateIngredient", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${data.uid}`),
    {
      name: data.name || "",
      category: data.category || "",
      unit: data.unit || "",
      quantity: data.quantity || 0,
      vat: data.vat || ""
    });
});

export const removeIngredient = createAsyncThunk("removeIngredient", async (data) => {
  return await remove(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${data.uid}`));
});

const initialState = {
  ingredients: [],
  categories: [],
  loading: false
};

const slice = createSlice({
  name: "ingredients",
  initialState,
  reducers: {
    addIngredientsToRedux: (state, { payload }) => {
      state.ingredients = [...state.ingredients, payload];
    },
    changeReduxIngQuantity: (state, { payload }) => {
      const { uid, quantity } = payload;
      const index = state.ingredients.findIndex(item => item.uid === uid);
      if (index !== -1) {
        state.ingredients = [
          ...state.ingredients.slice(0, index),
          { ...state.ingredients[index], quantity },
          ...state.ingredients.slice(index + 1)
        ];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewIngredient.fulfilled, (state, { meta, payload }) => {
        state.ingredients = [
          ...state.ingredients,
          { ...meta.arg, uid: payload?.key || meta?.arg?.uid }
        ];
      })
      .addCase(updateIngredient.fulfilled, (state, { meta }) => {
        const index = state.ingredients.findIndex(item => item.id === meta.arg.id);
        if (index !== -1) {
          state.ingredients = [
            ...state.ingredients.slice(0, index),
            meta.arg,
            ...state.ingredients.slice(index + 1)
          ];
        }
      })
      .addCase(fetchAllIngredients.fulfilled, (state, { payload }) => {
        state.ingredients = payload;
        state.loading = false;
      })
      .addCase(fetchAllIngredientsCategories.fulfilled, (state, { payload }) => {
        state.categories = payload;
        state.loading = false;
      })
      .addCase(removeIngredient.fulfilled, (state, { meta }) => {
        const index = state.ingredients.findIndex(item => item.id === meta.arg.id);
        if (index !== -1) {
          state.ingredients = [
            ...state.ingredients.slice(0, index),
            ...state.ingredients.slice(index + 1)
          ];
        }
        state.loading = false;
      });
  }
});

export const { addIngredientsToRedux, changeReduxIngQuantity } = slice.actions;
// Reducer
export default slice.reducer;
