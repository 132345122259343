import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { child, get } from "firebase/database";

export const fetchEsirBalance = createAsyncThunk("fetchEsirBalance", async () => {
  return (await get(child(dbRef, `userFinance/${AUTH.currentUser.uid}/balance`))).val();
});

export const fetchAllEsirInvoices = createAsyncThunk("fetchAllEsirInvoices", async () => {
  let arr = [];
  let objArr = (await get(child(dbRef, `userFinance/${AUTH.currentUser.uid}/invoices`))).val();
  if (objArr) {
    for (const key of Object.keys(objArr)) {
      arr.push({
        id: key,
        ...objArr[key]
      });
    }
  }
  return arr;
});

const initialState = {
  invoices: [],
  balance: undefined,
  loading: false
};

export const slice = createSlice({
  name: "esirInvoices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEsirInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllEsirInvoices.fulfilled, (state, { payload }) => {
        state.invoices = payload;
        state.loading = false;
      })
      .addCase(fetchAllEsirInvoices.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchEsirBalance.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEsirBalance.fulfilled, (state, { payload }) => {
        state.balance = payload;
        state.loading = false;
      })
      .addCase(fetchEsirBalance.rejected, (state) => {
        state.loading = false;
      });
  }
});

export default slice.reducer;
