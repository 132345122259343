import PropTypes from "prop-types";
// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  type: PropTypes.any,
  rows: PropTypes.any,
  name: PropTypes.string,
  decimals: PropTypes.any
};

export default function RHFTextField({ type, rows, name, decimals, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      defaultValue=""
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          type={type || "string"}
          size={"small"}
          {...field}
          rows={rows || 1}
          fullWidth
          value={typeof field.value === "number" && field.value === 0 ? "" : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
          onChange={event => {
            const { value } = event.target;
            if (decimals && type === "number") {
              const regex = /^\d*(\.\d{0,2})?$/;
              if (value === "" || regex.test(value)) {
                field.onChange(value);
              }
            } else {
              field.onChange(value);
            }
          }}
        />
      )}
    />
  );
}
