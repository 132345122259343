import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, setDoc, startAfter, where } from "firebase/firestore";
import convert from "xml-js";

import { AUTH, DB } from "../../../auth/FirebaseContext";
import eFactureAxios from "../../../api/eFacture/axios";
import {
  findAttachment,
  findPdf,
  getClientName,
  getObjKey,
  getTotalAmount,
  handleDocumentType,
  sleep
} from "../../../utils/eFacture";

export const postSalesInvoice = createAsyncThunk("postSalesInvoice", async (data) => {
  const { payload, sendToCir } = data;
  let response;
  const send = !!sendToCir ? "Yes" : "No";
  await eFactureAxios.post(`/sales-invoice/ubl?requestId=${Math.floor(Date.now() / 1000)}&sendToCir=${send}`,
    payload).then(async ({ data }) => {
    let obj = {};
    await sleep(1000);
    const foundInvoice = await eFactureAxios.get(`/sales-invoice/xml?invoiceId=${data.SalesInvoiceId}`);
    const convertedJson = JSON.parse(convert.xml2json(foundInvoice.data, { compact: false, spaces: 1 }));
    const findElement = convertedJson.elements[0].elements[1].elements[0].elements;
    const statusResponse = await eFactureAxios
      .get(`/sales-invoice?invoiceId=${convertedJson.elements[0].elements[0].elements[0].elements[0].text}`);
    obj.id = data.SalesInvoiceId;
    obj.invoiceNumber = getObjKey(findElement, "cbc:ID");
    obj.invoiceTypeCode = handleDocumentType(findElement, "cbc:InvoiceTypeCode");
    obj.clientName = await getClientName(findElement, "cac:AccountingSupplierParty");
    obj.customerName = await getClientName(findElement, "cac:AccountingCustomerParty");
    obj.issueDate = getObjKey(findElement, "cbc:IssueDate");
    obj.dueData = getObjKey(findElement, "cbc:DueDate");
    obj.payableAmount = getTotalAmount(findElement, "cac:LegalMonetaryTotal");
    obj.status = statusResponse.data.Status;
    response = obj;
  }).catch(error => {
    response = error;
  });
  return response;
});


export const acceptRejectPurchaseInvoices = createAsyncThunk("acceptRejectPurchaseInvoices",
  async (payload) => {
    const response = await eFactureAxios.post(`/purchase-invoice/acceptRejectPurchaseInvoice`, payload);
    return { ...payload, status: response.data.Invoice.Status };
  });

export const getPurchaseInvoiceById = createAsyncThunk("getPurchaseInvoiceById",
  async (payload) => {
    return await eFactureAxios.get(`/purchase-invoice?invoiceId=${payload}`);
  });

export const updateStatusOfInvoice = createAsyncThunk("updateStatusOfInvoice",
  async (data) => {
    const ref = doc(DB, `efakture`, `purchase`, `${AUTH.currentUser.uid}`, `${data.uid}`);
    delete data.uid;
    delete data.id;
    await setDoc(ref, data, { merge: true });
    return data;
  });

export const fetchAllSalesInvoicesFS = createAsyncThunk("fetchAllSalesInvoicesFS", async () => {
  let lastData = null;
  const size = 100;
  const arr = [];
  while (true) {
    let q = query(
      collection(DB, `efakture/sales/${AUTH.currentUser.uid}`),
      where("status.LastModifiedUtc", "!=", null),
      orderBy("status.LastModifiedUtc", "desc"),
      limit(size)
    );
    if (lastData) {
      q = query(
        collection(DB, `efakture/sales/${AUTH.currentUser.uid}`),
        where("status.LastModifiedUtc", "!=", null),
        orderBy("status.LastModifiedUtc", "desc"),
        startAfter(lastData),
        limit(size)
      );
    }
    const snapshot = await getDocs(q);
    if (snapshot.empty || snapshot.size === 0) {
      break;
    }
    snapshot.docs.forEach((doc) => {
      const data = doc.data();
      arr.push({
        id: doc.id,
        uid: doc.id,
        xml: data.xml,
        status: data.status
      });
      lastData = doc;
    });
  }
  return arr;
});

export const getXmlFile = createAsyncThunk("getXmlFile", async (data) => {
  const { id, type } = data;
  const response = await eFactureAxios.get(`/${type}/xml?invoiceId=${id}`);
  const convertedJson = JSON.parse(convert.xml2json(response.data, { compact: false, spaces: 1 }));
  const findElement = convertedJson.elements[0].elements[0].elements;
  const findElementPdf = convertedJson.elements[0].elements[1].elements[0].elements;
  const pdfInvoice = findPdf(findElement);
  const foundPDFs = findAttachment(findElementPdf);
  return { pdfInvoice: pdfInvoice, foundPDFs };
});

export const getInvoiceXmlByInvoiceId = createAsyncThunk("getInvoiceXmlByInvoiceId",
  async (data) => {
    if (data.type === "sales") {
      return (await (eFactureAxios.get(`sales-invoice/xml?invoiceId=${data.id}`))).data;
    }
    return (await (eFactureAxios.get(`purchase-invoice/xml?invoiceId=${data.id}`))).data;
  });

export const getXmlComment = createAsyncThunk("getXmlComment", async (data) => {
  const { id, type } = data;
  return (await eFactureAxios.get(`/${type}?invoiceId=${id}`)).data;
});

export const cancelInvoice = createAsyncThunk("cancelInvoice", async (data) => {
  await eFactureAxios.post(`/sales-invoice/cancel`, data);
  await updateStatusStornoOrCancel(data.invoiceId, "Cancelled");
  return data;
});

export const stornoInvoice = createAsyncThunk("stornoInvoice", async (data) => {
  await eFactureAxios.post(`/sales-invoice/storno`, data);
  await updateStatusStornoOrCancel(data.invoiceId, "Storno");
  return data;
});

export const updateStatusStornoOrCancel = async (id, status) => {
  const docRef = doc(DB, `efakture/sales/${AUTH.currentUser.uid}`, `${id}`);
  const docSnap = await getDoc(docRef);
  const obj = { ...docSnap.data() };
  const objStatus = { ...obj.status };
  objStatus.Status = status;
  obj.status = objStatus;
  await setDoc(docRef, obj, { merge: true });
};

export const statusSync = createAsyncThunk("statusSync", async (data) => {
  return new Promise(resolve => {
    setTimeout(() =>
      resolve(updateStatus(data)), 500);
  });
});

export const updateStatus = async (id) => {
  let obj = null;
  const found = (await eFactureAxios.get(`sales-invoice?invoiceId=${id}`)).data;
  const docRef = doc(DB, `efakture/sales/${AUTH.currentUser.uid}`, id);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.data().status.Status !== found.Status) {
      obj = { ...docSnap.data() };
      const objStatus = { ...obj.status };
      objStatus.Status = found.Status;
      obj.status = objStatus;
      setDoc(docRef, obj, { merge: true })
        .then(docRef => {

        })
        .catch(error => {
          console.error(error);
        });
    }
  } catch (error) {
    console.error(error);
  }
  return obj;
};

export const deleteDraftInvoice = createAsyncThunk("deleteDraftInvoice", async (id) => {
  return await (eFactureAxios.delete(`sales-invoice/${id}`));
});

const initialState = {
  allPurchaseInvoices: [],
  allSalesInvoices: [],
  loading: false
};

export const slice = createSlice({
  name: "eFactureInvoices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Update Status Of Invoice
      .addCase(updateStatusOfInvoice.pending, updateLoading)
      .addCase(updateStatusOfInvoice.fulfilled, updateInvoiceStatus("allPurchaseInvoices"))
      .addCase(updateStatusOfInvoice.rejected, updateLoading)

      // Accept/Reject Purchase Invoices
      .addCase(acceptRejectPurchaseInvoices.pending, updateLoading)
      .addCase(acceptRejectPurchaseInvoices.fulfilled, updateInvoiceStatus("allPurchaseInvoices"))
      .addCase(acceptRejectPurchaseInvoices.rejected, updateLoading)

      // Fetch All Sales Invoices FS
      .addCase(fetchAllSalesInvoicesFS.pending, updateLoading)
      .addCase(fetchAllSalesInvoicesFS.fulfilled, updateInvoiceList("allSalesInvoices"))
      .addCase(fetchAllSalesInvoicesFS.rejected, updateLoading)

      // Storno Invoice
      .addCase(stornoInvoice.pending, updateLoading)
      .addCase(stornoInvoice.fulfilled, updateInvoiceStatus("allSalesInvoices"))
      .addCase(stornoInvoice.rejected, updateLoading)

      // Cancel Invoice
      .addCase(cancelInvoice.pending, updateLoading)
      .addCase(cancelInvoice.fulfilled, updateInvoiceStatus("allSalesInvoices"))
      .addCase(cancelInvoice.rejected, updateLoading)

      // Post Sales Invoice
      .addCase(postSalesInvoice.pending, updateLoading)
      .addCase(postSalesInvoice.fulfilled, updateLoading)
      .addCase(postSalesInvoice.rejected, updateLoading)

      // Status Sync
      .addCase(statusSync.pending, updateLoading)
      .addCase(statusSync.fulfilled, updateStatusSync)
      .addCase(statusSync.rejected, updateLoading);
  }
});

function updateLoading(state) {
  state.loading = true;
}

function updateInvoiceStatus(invoiceList) {
  return (state, { payload }) => {
    const arr = [...current(state[invoiceList])];
    const index = arr.findIndex(i => i.uid === payload.uid || i.id === payload.invoiceId);
    if (index !== -1) {
      arr[index] = payload;
      state[invoiceList] = arr;
    }
    state.loading = false;
  };
}

function updateInvoiceList(invoiceList) {
  return (state, { payload }) => {
    state[invoiceList] = payload;
    state.loading = false;
  };
}

function updateStatusSync(state, { payload }) {
  const arr = [...current(state.allSalesInvoices)];
  if (payload !== null) {
    const index = arr.findIndex(i => i.xml.SalesInvoiceId === payload.xml.SalesInvoiceId);
    if (index !== -1) {
      arr[index] = { ...arr[index], status: payload.status };
      state.allSalesInvoices = arr;
    }
  }
  state.loading = false;
}

export default slice.reducer;
