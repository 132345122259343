import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { child, get } from "firebase/database";
import { dbRef } from "../../auth/FirebaseContext";
import { clearCache } from "../../helper/other";
import PropTypes from "prop-types";

CacheDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}

export default function CacheDialog({ open, setOpen }) {

  const [version, setVersion] = useState();

  useEffect(() => {
    get(child(dbRef, `public/webAppData/version`)).then(value => {
      setVersion(value.val());
    });
  }, []);

  const handleClose = async () => {
    clearCache(version);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle sx={{ mb: "5px" }} id="alert-dialog-title">
        Obaveštenje
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Dostupna je nova verzija, kliknite na dugme ok.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ mt: "-25px" }} onClick={handleClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
