import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { child, get } from "firebase/database";
import moment from "moment";
import { ALL_LICENSE } from "../../constants";

export const fetchAllUserLicense = createAsyncThunk("fetchAllUserLicense", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/protected/license`))).val();
});

const initialState = {
  licenses: [],
  loading: false,
  isWebLicenseExpired: false
};

export const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    changedLicense: (state, { payload }) => {
      if (payload) {
        const webLicense = state.licenses.find(license => license.productID === ALL_LICENSE.webESIR);
        state.isWebLicenseExpired = moment(webLicense?.expiredDate).isBefore(moment.now());
      }
    },
    updateIsExpired: (state) => {
      state.isWebLicenseExpired = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUserLicense.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllUserLicense.fulfilled, (state, { payload }) => {
        state.licenses = payload;
        const webLicense = payload.find(license => license.productID === ALL_LICENSE.webESIR);
        state.isWebLicenseExpired = moment(webLicense?.expiredDate).isBefore(moment.now());
        state.loading = false;
      })
      .addCase(fetchAllUserLicense.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const licenseReducer = licenseSlice.reducer;
export const { changedLicense, updateIsExpired } = licenseSlice.actions;
