import { createSlice, current } from "@reduxjs/toolkit";
// redux
import { collection, doc, getDocs, query, setDoc, deleteDoc } from "firebase/firestore";
import { dispatch } from "../../store";
import { AUTH, DB } from "../../../auth/FirebaseContext";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  allDrafts: [],
  draft: null
};

const slice = createSlice({
  name: "drafts",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },

    // GET DRAFTS
    getSuccessAllDrafts(state, { payload }) {
      state.allDrafts = payload;
      state.isLoading = false;
    },

    // GET DRAFT
    getSuccessDraft(state, { payload }) {
      state.draft = payload;
      state.isLoading = false;
    },

    // ADD DRAFT
    addSuccessDraft(state, { payload }) {
      const arr = [...current(state.allDrafts)];
      arr.push(payload);
      state.allDrafts = arr;
      state.isLoading = false;
    },

    // DELETE DRAFT
    deleteSuccessDraft(state, { payload }) {
      const arr = [...current(state.allDrafts)];
      const index = arr.findIndex(i => i.name === payload.name);
      if (index !== -1) {
        arr.splice(index, 1);
        state.allDrafts = arr;
      }
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllDrafts(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const arr = [];
      const q = query(collection(DB, `efakture/drafts/${user.uid}`));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        const data = doc.data();
        const uid = doc.id;
        arr.push({ uid, ...data });
      });
      dispatch(slice.actions.getSuccessAllDrafts(arr));
    } catch (e) {
      dispatch(slice.actions.hasError(e));
    }
  };
}

export function saveDraft(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    return new Promise((resolve, reject) => {
      try {
        const timestamp = Math.floor(Date.now() / 1000);
        const ref = doc(DB, `efakture`, `drafts`, `${AUTH.currentUser.uid}`, `${timestamp}`);
        setDoc(ref, data, { merge: true });
        dispatch(slice.actions.addSuccessDraft(data));
        resolve(true);
      } catch (e) {
        dispatch(slice.actions.hasError(e));
        reject(e);
      }
    });
  };
}

export function deleteDraft(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    return new Promise((resolve, reject) => {
      try {
        deleteDoc(doc(DB, "efakture", `drafts`, AUTH.currentUser.uid, `${data.uid}`));
        dispatch(slice.actions.deleteSuccessDraft(data));
        resolve(true);
      } catch (e) {
        dispatch(slice.actions.hasError(e));
        reject(e);
      }
    });
  };
}
