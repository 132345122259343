// @mui
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useLocales } from "../../locales";
import { useSelector } from "../../redux/store";

LocationDialog.propTypes = {};

export default function LocationDialog({ open, handleLocationClick, ...other }) {
  const { translate } = useLocales();
  const { locations, loading } = useSelector((select => select.locations));
  const [selectedLocation, setSelectedLocation] = useState({});
  const handleChange = (event) => {
    setSelectedLocation(event.target.value);
  };
  return (
    <Dialog fullWidth maxWidth="xs" open={open} sx={{ backgroundColor: "rgba(0,0,0,0.9)" }} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{translate("chooseLocation")}</DialogTitle>
      {loading ? <CircularProgress color="success" /> :
        <Select
          sx={{ ml: 2, mr: 2 }}
          size="small"
          value={selectedLocation}
          onChange={handleChange}>
          {locations.map((item) => (
            <MenuItem
              key={item.uid}
              value={item}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: "body2"
              }}>
              {item.name}
            </MenuItem>
          ))}
        </Select>}
      <DialogActions>
        <Button variant="outlined" color="success" onClick={(e) => {
          handleLocationClick(e, selectedLocation);
        }}>
          {translate("submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
