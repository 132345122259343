import { createSlice } from "@reduxjs/toolkit";
import { child, get } from "firebase/database";
import { dispatch } from "../../store";
import { dbRef } from "../../../auth/FirebaseContext";

const initialState = {
  isLoading: false,
  error: null,
  groups: []
};

const slice = createSlice({
  name: "eFacturesGroups",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET GROUPS
    getLabelsSuccess(state, { payload }) {
      state.isLoading = false;
      state.groups = payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllGroupsFactures() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const arr = [];
      (await get(child(dbRef, "public/groupsFakture"))).forEach(child1 => {
        arr.push({
          ...child1.val(),
          uid: parseInt(child1.key)
        });
      });
      dispatch(slice.actions.getLabelsSuccess(arr));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
