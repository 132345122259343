import { AUTH, DB } from "../../../auth/FirebaseContext";
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { getTimestampFromSdc } from "../../invoice";
import { putLogToFirebase } from "../../../store/offlineDb";

export async function getAllOpenAdvanceFromFirestore(locationJid) {
  let arr = [];
  let q;
  if (locationJid) {
    q = await query(collection(DB, `invoices/advanceTransactions/${AUTH.currentUser.uid}`),
      where("invoiceNumber", ">=", locationJid));
  } else {
    q = await query(collection(DB, `invoices/advanceTransactions/${AUTH.currentUser.uid}`));
  }
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    let data = doc.data();
    arr.push({
      ...data,
      timestamp: data.timestamp || data.timeStamp
    });
  });
  return arr;
}

export async function getOpenAdvanceInvoiceByTimestamp(timestamp) {
  const documentRef = doc(DB, `invoices/advanceTransactions/${AUTH.currentUser.uid}`, `${timestamp}`);
  const documentSnapshot = await getDoc(documentRef);
  if (documentSnapshot.exists()) {
    return documentSnapshot.data();
  } else {
    return undefined;
  }
}

export async function createOpenAdvanceInvoice(invoice, response, realItems) {
  const timestamp = getTimestampFromSdc(response.sdcDateTime);
  const advanceAmount = Number(response.totalAmount);
  return await setDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid, `${timestamp}`), {
    advanceAmount: Number(Number(advanceAmount).toFixed(2)),
    invoiceNumber: response.invoiceNumber,
    items: convertItemsForOpenAdvice(realItems),
    linkedInvoices: [parseInt(timestamp)],
    linkedRefund: [],
    timestamp: parseInt(timestamp)
  }, { merge: true });
}

export async function connectOpenAdvanceInvoice(invoice, response, rootInvoice, referentInvoiceTimestamp) {
  try {
    const timestamp = getTimestampFromSdc(response.sdcDateTime);
    let linked = [...(rootInvoice.linkedInvoices || [])];
    let newAmount = Number(rootInvoice.advanceAmount);
    linked.push(parseInt(timestamp));
    newAmount += Number(response.totalAmount);

    await updateDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid, `${rootInvoice.timestamp}`), {
      advanceAmount: Number(Number(newAmount).toFixed(2)),
      linkedInvoices: linked
    });
  } catch (e) {
    putLogToFirebase("connectOpenAdvanceInvoice",
      "helper/firestore/openAdvance",
      [invoice, response, rootInvoice, referentInvoiceTimestamp],
      e);
    throw new Error("7533");
  }
}

export async function refundOpenAdvanceInvoice(invoice, response, rootInvoice, referentInvoiceTimestamp) {
  try {
    const timestamp = getTimestampFromSdc(response.sdcDateTime);
    let linked = [...(rootInvoice.linkedInvoices || [])];
    let refund = [...(rootInvoice.linkedRefund || [])];
    refund.push(parseInt(timestamp));
    let newAmount = Number(rootInvoice.advanceAmount);
    // refundacija prvog sto znaci potpuna refundacija (prebacuje se u advanceTransactionsHistory)
    if (parseInt(rootInvoice.timestamp) === parseInt(referentInvoiceTimestamp)) {
      refund = [...linked, ...refund];
      linked = [];
      newAmount -= Number(response.totalAmount);
      await setDoc(doc(DB, "invoices", `advanceTransactionsHistory`, AUTH.currentUser.uid, `${rootInvoice.timestamp}`),
        {
          advanceAmount: Number(Number(newAmount).toFixed(2)),
          invoiceNumber: rootInvoice.invoiceNumber,
          items: rootInvoice.items,
          linkedInvoices: linked,
          linkedRefund: refund,
          timestamp: rootInvoice.timestamp
        }, { merge: true });
      await deleteDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid, `${rootInvoice.timestamp}`));
    } else {
      const indexLinked = linked.indexOf(parseInt(referentInvoiceTimestamp));
      linked.splice(indexLinked, 1);
      newAmount += Number(response.totalAmount);
      await updateDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid, `${rootInvoice.timestamp}`), {
        advanceAmount: Number(Number(newAmount).toFixed(2)),
        linkedInvoices: linked,
        linkedRefund: refund
      });
    }
  } catch (e) {
    putLogToFirebase("refundOpenAdvanceInvoice",
      "helper/firestore/openAdvance",
      [invoice, response, rootInvoice, referentInvoiceTimestamp],
      e);
    throw new Error("7533");
  }
}

export async function closeOpenAdvanceInvoice(invoice, response, rootInvoice) {
  try {
    const timestamp = getTimestampFromSdc(response.sdcDateTime);
    let linkedInvoices = [...(rootInvoice.linkedInvoices || [])];
    let linkedRefund = [...(rootInvoice.linkedRefund || [])];
    linkedRefund = [...linkedRefund, ...linkedInvoices];
    await setDoc(doc(DB, "invoices", "advanceTransactionsHistory", AUTH.currentUser.uid,
      `${rootInvoice.timestamp}`), {
      ...rootInvoice,
      advanceAmount: rootInvoice.items.reduce((acc, { totalAmount }) => acc + Number(totalAmount), 0),
      linkedInvoices: [parseInt(timestamp)],
      linkedRefund
    }, { merge: true });
    await deleteDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid,
      `${rootInvoice.timestamp}`));
  } catch (e) {
    putLogToFirebase("connectOpenAdvanceInvoice", "helper/firestore/openAdvance",
      [invoice, response, rootInvoice], e);
    throw new Error("7533");
  }
}

export async function changeItemsAndTotalAdvanceInOpenAdvance(items, timestamp) {
  return await setDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid, `${timestamp}`),
    {
      items: items
    }, { merge: true });
}

const convertItemsForOpenAdvice = (items) => {
  return items.map(item => ({
    uid: item.uid || null,
    vat: item.labels[0] || null,
    labels: item.labels || null,
    name: item.name || null,
    quantity: Number(item.quantity),
    totalAmount: Number(item.totalAmount),
    unit: item.unit || null,
    unitPrice: Number(item.unitPrice)
  }));
};
