import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { child, get } from "firebase/database";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { getSessionSelectedLocation } from "../../helper/session";

export const fetchAllLocations = createAsyncThunk("fetchAllLocations", async () => {
  let arr = [];
  (await get(child(dbRef, `users/${AUTH.currentUser.uid}/protected/se`))).forEach(child => {
    if (child.val().PAC) {
      arr.push({
        ...child.val(),
        uid: child.key
      });
    }
  });
  return arr;
});

const initialState = {
  locations: [],
  selectedLocation: getSessionSelectedLocation(),
  loading: false
};

export const slice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllLocations.fulfilled, (state, { payload }) => {
        state.locations = payload;
        state.loading = false;
      })
      .addCase(fetchAllLocations.rejected, (state, action) => {
        state.loading = false;
      });
  }
});


export default slice.reducer;
