import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH, DB, dbRef } from "../../auth/FirebaseContext";
import { collection, deleteDoc, doc, getDocs, query, runTransaction, setDoc } from "firebase/firestore";
import { child, push } from "firebase/database";
import { putCostOffline, putIngredientOffline, putItemOffline } from "../../store/offlineDb";
import { changeCostQuantity, changeIngQuantity, changeItemQuantity } from "../../helper/realtimeDatabase";
import { CHANGE_ITEM_QUANTITY } from "../../constants";
import moment from "moment/moment";
import { getSessionOperator } from "../../helper/session";
import { dispatch } from "../store";
import { addItemToRedux } from "./items";
import { addIngredientsToRedux } from "./ingredients";
import { addCostToRedux } from "./costs";

export const fetchAllFactures = createAsyncThunk("fetchAllFactures", async () => {
  let arr = [];
  const q = query(collection(DB, `factures/users/${AUTH.currentUser.uid}`));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    let facture = doc.data();
    const isGenerated = facture.operatorUid === "GENERATED" || facture.supplierUid === "GENERATED";
    let paidAmount = facture.payments ? facture.payments?.reduce((acc, obj) => {
      return acc + obj.amount;
    }, 0) : 0;
    arr.push({
      ...facture,
      paidAmount,
      totalAmount: isGenerated ? paidAmount : facture.totalPrice,
      dateOfIssue: facture.dateOfIssue.toDate(),
      paymentDate: facture.paymentDate.toDate(),
      dateOfInvoiceReceipt: facture.dateOfInvoiceReceipt.toDate(),
      uid: doc.id
    });
  });
  return arr;
});

export const addFactureRegistered = createAsyncThunk("addFactureRegistered", async (data) => {
  await setDoc(doc(DB, "factures", `users`, AUTH.currentUser.uid, `${data.uid}`),
    { ...data, registered: !data.registered });
  return { ...data, registered: !data.registered };
});

export const updateCompensations = createAsyncThunk("updateCompensations", async (data) => {
  await setDoc(doc(DB, "factures", `users`, AUTH.currentUser.uid, `${data.uid}`), data);
});

export const updateFacture = createAsyncThunk("updateFacture", async (data) => {
  await runTransaction(DB, async () => {
    await dispatch(deleteFacture(data));
    await dispatch(createFacture(data));
  });
});

export const removeFacturePayment = createAsyncThunk("removeFacturePayment", async (data) => {
  await setDoc(doc(DB, "factures", `users`, AUTH.currentUser.uid, `${data.uid}`), data);
});
export const deleteFacture = createAsyncThunk("deleteFacture", async (data) => {
  await deleteDoc(doc(DB, "factures", `users`, AUTH.currentUser.uid, `${data.uid}`));
  await changeQuantityFromFactures(
    CHANGE_ITEM_QUANTITY.reduce,
    data.items.filter(i => i.isNew === false || i.isNew === undefined || i.isNew === null),
    data.ingredients.filter(i => i.isNew === false || i.isNew === undefined || i.isNew === null),
    data.costs.filter(i => i.isNew === false || i.isNew === undefined || i.isNew === null)
  );
  return data;
});

export const createFacture = createAsyncThunk("createFacture", async (data) => {
  const { items, ingredients, costs } = data;
  let timestamp = moment(new Date()).format("X");
  const factureItems = [];
  const factureIng = [];
  const factureCosts = [];
  const itemsIds = [];
  for (const item of items) {
    let cItem = {
      uid: item.uid,
      name: item.name,
      discount: item.discount,
      purchasePrice: item.purchasePrice,
      quantity: item.quantity,
      category: item.category,
      restaurantGroup: item.restaurantGroup,
      vat: item.vat,
      restaurantItemsCategory: item.restaurantItemsCategory,
      total: item.total,
      sumWithoutTax: item.sumWithoutTax
    };
    if (item.isNew) {
      await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`), {
        name: item.name,
        category: item.category,
        description: item.description,
        vat: item.vat,
        unit: item.unit,
        code: item.code,
        price: item.price,
        ean: item.ean,
        quantity: item.quantity,
        restaurantGroup: item.restaurantGroup,
        restaurantItemsCategory: item.restaurantItemsCategory
      }).then(value => {
        dispatch(addItemToRedux(item));
        putItemOffline({ ...item, uid: value.key });
        cItem = {
          uid: value.key,
          name: item.name,
          discount: item.discount,
          purchasePrice: item.purchasePrice,
          quantity: item.quantity,
          category: item.category,
          restaurantGroup: item.restaurantGroup,
          vat: item.vat,
          restaurantItemsCategory: item.restaurantItemsCategory,
          total: item.total,
          sumWithoutTax: item.sumWithoutTax
        };
      });
    }
    factureItems.push(cItem);
    itemsIds.push(cItem.uid);
  }

  for (const ingredient of ingredients) {
    let cIng = {
      uid: ingredient.uid,
      name: ingredient.name,
      discount: ingredient.discount,
      purchasePrice: ingredient.purchasePrice,
      category: ingredient.category,
      quantity: ingredient.quantity,
      vat: ingredient.vat,
      total: ingredient.total,
      sumWithoutTax: ingredient.sumWithoutTax
    };
    if (ingredient.isNew) {
      await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients`), {
        name: ingredient.name,
        category: ingredient.category,
        unit: ingredient.unit,
        vat: ingredient.vat,
        quantity: ingredient.quantity
      }).then(value => {
        dispatch(addIngredientsToRedux(ingredient));
        putIngredientOffline({ ...ingredient, uid: value.key });
        cIng = {
          uid: value.key,
          name: ingredient.name,
          discount: ingredient.discount,
          purchasePrice: ingredient.purchasePrice,
          category: ingredient.category,
          quantity: ingredient.quantity,
          vat: ingredient.vat,
          total: ingredient.total,
          sumWithoutTax: ingredient.sumWithoutTax
        };
      });
    }
    factureIng.push(cIng);
    itemsIds.push(cIng.uid);
  }

  for (const cost of costs) {
    let cItem = {
      uid: cost.uid,
      name: cost.name,
      discount: cost.discount,
      purchasePrice: cost.price,
      quantity: cost.quantity,
      vat: cost.vat,
      category: cost.category,
      total: cost.total,
      sumWithoutTax: cost.sumWithoutTax
    };
    if (cost.isNew) {
      await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs`), {
        name: cost.name,
        group: cost.group,
        price: cost.price,
        quantity: cost.quantity,
        unit: cost.unit,
        vat: cost.vat
      }).then(value => {
        dispatch(addCostToRedux(cost));
        putCostOffline({ ...cost, uid: value.key });
        cItem = {
          uid: value.key,
          name: cost.name,
          discount: cost.discount,
          purchasePrice: cost.price,
          quantity: cost.quantity,
          vat: cost.vat,
          group: cost.group,
          total: cost.total,
          sumWithoutTax: cost.sumWithoutTax
        };
      });
    }
    factureCosts.push(cItem);
    itemsIds.push(cItem.uid);
  }
  let newFacture = {
    dateOfInvoiceReceipt: data.dateOfInvoiceReceipt,
    dateOfIssue: data.dateOfIssue,
    paymentDate: data.paymentDate,
    registered: data.registered,
    payments: data.payments || [],
    supplierUid: data.supplierUid,
    totalPrice: data.totalPrice || 0,
    number: data.number,
    items: factureItems,
    costs: factureCosts,
    ingredients: factureIng,
    itemsIds,
    operatorUid: getSessionOperator().uid
  };
  const factureRef = doc(DB, "factures", "users", AUTH.currentUser.uid, timestamp);
  await setDoc(factureRef, newFacture, { merge: true });
  // await putFactureOffline(newFacture)
  await changeQuantityFromFactures(
    CHANGE_ITEM_QUANTITY.sum,
    factureItems.filter(i => i.isNew === false || i.isNew === undefined || i.isNew === null),
    factureIng.filter(i => i.isNew === false || i.isNew === undefined || i.isNew === null),
    factureCosts.filter(i => i.isNew === false || i.isNew === undefined || i.isNew === null)
  );
  return {
    ...newFacture,
    uid: timestamp,
    totalAmount: newFacture.totalPrice
  };
});

export const addFacturePayment = createAsyncThunk("addFacturePayment", async (data) => {
  await setDoc(doc(DB, "factures", `users`, AUTH.currentUser.uid, `${data.uid}`), data);
  return data;
});

const changeQuantityFromFactures = async (type, items, ingrediants, costs) => {
  for (const item of items) {
    await changeItemQuantity(item.uid, item.quantity, type);
  }
  for (const ingrediant of ingrediants) {
    await changeIngQuantity(ingrediant.uid, ingrediant.quantity, type);
  }
  for (const cost of costs) {
    await changeCostQuantity(cost.uid, cost.quantity, type);
  }
};

const initialState = {
  factures: [],
  loading: false
};

const slice = createSlice({
  name: "factures",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createFacture.fulfilled, (state, { payload }) => {
        const existingFacture = state.factures.find((facture) => facture.uid === payload.uid);
        if (!existingFacture) {
          state.factures.push(payload);
        }
      })
      .addCase(fetchAllFactures.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllFactures.fulfilled, (state, { payload }) => {
        state.factures = payload.sort((a, b) => moment(b.dateOfIssue).format("X") - moment(a.dateOfIssue).format("X"));
        state.loading = false;
      })
      .addCase(fetchAllFactures.rejected, (state) => {
        state.loading = false;
      })
      .addCase(removeFacturePayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeFacturePayment.fulfilled, (state, { meta }) => {
        const factureIndex = state.factures.findIndex((facture) => facture.uid === meta.arg.uid);
        if (factureIndex !== -1) {
          state.factures[factureIndex] = meta.arg;
        }
        state.loading = false;
      })
      // ... (similar changes for other cases)
      .addCase(deleteFacture.fulfilled, (state, { payload }) => {
        const factureIndex = state.factures.findIndex((facture) => facture.uid === payload.uid);
        if (factureIndex !== -1) {
          state.factures.splice(factureIndex, 1);
        }
        state.loading = false;
      })
      .addCase(updateCompensations.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompensations.fulfilled, (state, { meta }) => {
        const factureIndex = state.factures.findIndex((facture) => facture.uid === meta.arg.uid);
        if (factureIndex !== -1) {
          state.factures[factureIndex] = meta.arg;
        }
        state.loading = false;
      })
      .addCase(updateCompensations.rejected, (state) => {
        state.loading = false;
      });
  }
});

// Reducer
export default slice.reducer;
