import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { child, get, push, remove, update } from "firebase/database";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { getClientInfo } from "../../api/bss";

// ----------------------------------------------------------------------

export const fetchAllCustomers = createAsyncThunk("fetchAllCustomers", async () => {
  const arr = [];
  (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers`))).forEach(child1 => {
    arr.push({
      ...child1.val(),
      uid: child1.key
    });
  });
  return arr;
});

export const getClientDetails = createAsyncThunk("getClientDetails", async (tin) => {
  const response = await getClientInfo(tin);
  return response.data;
});
// vat je pib
export const addCustomer = createAsyncThunk("addCustomer", async (customer) => {
  const response = push(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers`), customer);
  return {
    uid: response.key,
    ...customer
  };
});

export const updateCustomer = createAsyncThunk("updateCustomer", async (customer) => {
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers/${customer.uid}`), customer);
  return customer;
});

export const removeCustomer = createAsyncThunk("removeCustomer", async (customer) => {
  await remove(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers/${customer.uid}`));
  return customer;
});
// ----------------------------------------------------------------------

const initialState = {
  allCustomers: [],
  loading: false
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClientDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientDetails.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getClientDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAllCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCustomers.fulfilled, (state, action) => {
        state.allCustomers = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllCustomers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCustomer.fulfilled, (state, { payload }) => {
        state.allCustomers = [...current(state.allCustomers), payload];
        state.loading = false;
      })
      .addCase(addCustomer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomer.fulfilled, (state, { payload }) => {
        state.allCustomers = state.allCustomers.map((customer) =>
          customer.uid === payload.uid ? payload : customer
        );
        state.loading = false;
      })
      .addCase(updateCustomer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(removeCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCustomer.fulfilled, (state, { payload }) => {
        state.allCustomers = state.allCustomers.filter(
          (customer) => customer.uid !== payload.uid
        );
        state.loading = false;
      })
      .addCase(removeCustomer.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const customersReducer = customersSlice.reducer;
