import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { child, get, push, remove, set } from "firebase/database";

export const fetchAllSuppliers = createAsyncThunk("fetchAllSuppliers", async () => {
  let arr = [];
  (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/suppliers`))).forEach((child1) => {
    arr.push({
      ...child1.val(),
      uid: child1.key
    });
  });
  return arr;
});
export const addNewSupplier = createAsyncThunk("addNewSupplier", async (data) => {
  return push(child(dbRef, `users/${AUTH.currentUser.uid}/private/suppliers`),
    {
      name: data.name || "",
      TIN: data.TIN || "",
      CIN: data.CIN || "",
      address: data.address || "",
      city: data.city || "",
      phone: data.phone || "",
      email: data.email || "",
      contactPerson: data.contactPerson || "",
      items: data.items || []
    });
});

export const updateSupplier = createAsyncThunk("updateSupplier", async (data) => {
  return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/suppliers/${data.uid}`),
    {
      name: data.name || "",
      TIN: data.TIN || "",
      CIN: data.CIN || "",
      address: data.address || "",
      city: data.city || "",
      phone: data.phone || "",
      email: data.email || "",
      contactPerson: data.contactPerson || "",
      items: data.items || {}
    });
});

export const removeSupplier = createAsyncThunk("removeSupplier", async (data) => {
  return await remove(child(dbRef, `users/${AUTH.currentUser.uid}/private/suppliers/${data.uid}`));
});

const initialState = {
  suppliers: [],
  loading: false
};

export const slice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSuppliers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllSuppliers.fulfilled, (state, { payload }) => {
        state.suppliers = payload.filter(
          (obj, index, self) => index === self.findIndex((t) => t.TIN === obj.TIN)
        );
        state.loading = false;
      })
      .addCase(fetchAllSuppliers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addNewSupplier.fulfilled, (state, { meta, payload }) => {
        let arr = [...current(state.suppliers)];
        arr.push({
          ...meta.arg,
          uid: payload.key
        });
        state.suppliers = arr;
      })
      .addCase(removeSupplier.fulfilled, (state, { meta }) => {
        let arr = [...current(state.suppliers)];
        let index = arr.findIndex(item => item.uid === meta.arg.uid);
        if (index !== -1) {
          arr.splice(index, 1);
        }
        state.suppliers = arr;
      })
      .addCase(updateSupplier.fulfilled, (state, { meta }) => {
        let arr = [...current(state.suppliers)];
        let index = arr.findIndex(item => item.uid === meta.arg.uid);
        if (index !== -1) {
          arr[index] = meta.arg;
        }
        state.suppliers = arr;
      });
  }
});

// Reducer
export default slice.reducer;
