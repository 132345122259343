import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { child, get, push, update } from "firebase/database";
import { AUTH, dbRef } from "../../auth/FirebaseContext";

export const fetchAllDiscountsCustomers = createAsyncThunk("fetchAllDiscountsCustomers", async () => {
  const arr = [];
  try {
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/discountCustomers`))).forEach(child1 => {
      arr.push({
        ...child1.val(),
        uid: child1.key
      });
    });
  } catch (e) {
    console.error("fetchAllDiscountsCustomers",e)
  }
  return arr;
});

export const updateDiscountsCustomer = createAsyncThunk("updateDiscountsCustomer", async (payload) => {
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/discountCustomers/${payload.uid}`), payload);
  return payload;
});

export const addDiscountCustomer = createAsyncThunk("addDiscountCustomer", async (payload) => {
  const obj = { ...payload };
  await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/discountCustomers`), payload).then(async response => {
    obj.uid = response.key;
  });
  return obj;
});

export const getDiscountCostumer = createAsyncThunk("getDiscountCostumer", async () => {
});

export const changeDiscountCustomerStatus = createAsyncThunk("updateDiscountCustomerStatus", async (data) => {
  (await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/discountCustomers/${data.uid}`), {
    ...data,
    inactive: !data.inactive
  }));
  return {
    ...data,
    inactive: !data.inactive
  };
});

const initialState = {
  discountCustomersList: [],
  loading: false,
  discountCustomer: undefined
};

export const discountCustomerSlice = createSlice({
  name: "discountCustomer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDiscountsCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDiscountsCustomers.fulfilled, (state, { payload }) => {
        state.discountCustomersList = payload;
        state.loading = false;
      })
      .addCase(fetchAllDiscountsCustomers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateDiscountsCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDiscountsCustomer.fulfilled, (state, { payload }) => {
        state.discountCustomersList = state.discountCustomersList.map((item) =>
          item.uid === payload.uid ? payload : item
        );
        state.loading = false;
      })
      .addCase(updateDiscountsCustomer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addDiscountCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDiscountCustomer.fulfilled, (state, { payload }) => {
        state.discountCustomersList = [...state.discountCustomersList, payload];
        state.loading = false;
      })
      .addCase(addDiscountCustomer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changeDiscountCustomerStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeDiscountCustomerStatus.fulfilled, (state, { payload }) => {
        state.discountCustomersList = state.discountCustomersList.map((item) =>
          item.uid === payload.uid ? payload : item
        );
        state.loading = false;
      })
      .addCase(changeDiscountCustomerStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDiscountCostumer.pending, (state) => {
        state.loadingInfo = true;
      })
      .addCase(getDiscountCostumer.fulfilled, (state, { payload }) => {
        state.discountCustomersList = payload;
        state.loadingInfo = false;
      })
      .addCase(getDiscountCostumer.rejected, (state) => {
        state.loadingInfo = false;
      });
  }
});

export const discountCustomerReducer = discountCustomerSlice.reducer;
