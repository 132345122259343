import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import { child, get, set } from "firebase/database";
import { AUTH, dbRef } from "../../../auth/FirebaseContext";
// ----------------------------------------------------------------------

export const fetchAllPayeeFinancialInvoices = createAsyncThunk("fetchAllPayeeFinancialInvoices",
  async () => {
    const arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/efakture/payeeFinancialAccounts`)))
      .forEach(child1 => {
        arr.push({ payeeFinancialNumber: child1.val() });
      });
    if (arr.length) {
      (await get(child(dbRef, "public/banks"))).forEach(child1 => {
        const index = arr.findIndex(i => i.payeeFinancialNumber.substring(3, 0) === child1.key.toString());
        if (index !== -1) {
          arr[index] = { ...arr[index], name: child1.val() };
        }
      });
    }
    return arr;
  });

export const updatePayeeFinancialInvoice = createAsyncThunk("updatePayeeFinancialInvoice",
  async (data) => {
    const arrOfPayeeFinancialNumbers = data.map(i => i.payeeFinancialNumber);
    return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/efakture/payeeFinancialAccounts`),
      arrOfPayeeFinancialNumbers);
  });

export const fetchAllPublicBanks = createAsyncThunk("fetchAllPublicBanks", async () => {
  return await get(child(dbRef, "public/banks"));
});

export const addPayeeFinancialInvoice = createAsyncThunk("addPayeeFinancialInvoice",
  async (payeeFinancialInvoices) => {
    const arrOfPayeeFinancialNumbers = payeeFinancialInvoices.map(i => i.payeeFinancialNumber);
    return (await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/efakture/payeeFinancialAccounts`),
      arrOfPayeeFinancialNumbers));
  });

export const deleteFinancialInvoice = createAsyncThunk("deleteFinancialInvoice",
  async (payload) => {
    const test = payload.map(obj => (obj.payeeFinancialNumber));
    (await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/efakture/payeeFinancialAccounts`), test));
    return payload;
  });

const initialState = {
  payeeFinancialInvoices: [],
  bankNames: [],
  loading: false,
  error: null,
  payeeFinancialInvoice: undefined
};

export const slice = createSlice({
  name: "eFactureFinancialAccounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPayeeFinancialInvoices.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchAllPayeeFinancialInvoices.fulfilled, (state, { payload }) => {
        state.payeeFinancialInvoices = payload;
        state.loading = false;
      })
      .addCase(fetchAllPayeeFinancialInvoices.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(fetchAllPublicBanks.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchAllPublicBanks.fulfilled, (state, { payload }) => {
        state.bankNames = payload;
        state.loading = false;
      })
      .addCase(fetchAllPublicBanks.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addPayeeFinancialInvoice.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(addPayeeFinancialInvoice.fulfilled, (state, { meta }) => {
        state.payeeFinancialInvoices = meta.arg;
        state.loading = false;
      })
      .addCase(addPayeeFinancialInvoice.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updatePayeeFinancialInvoice.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(updatePayeeFinancialInvoice.fulfilled, (state, { meta }) => {
        state.payeeFinancialInvoices = meta.arg;
        state.loading = false;
      })
      .addCase(updatePayeeFinancialInvoice.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteFinancialInvoice.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(deleteFinancialInvoice.fulfilled, (state, { payload }) => {
        state.payeeFinancialInvoices = payload;
        state.loading = false;
      })
      .addCase(deleteFinancialInvoice.rejected, (state) => {
        state.loading = false;
      });
  }
});

export default slice.reducer;
