import axios from "axios";
import { AUTH } from "../auth/FirebaseContext";
import { HOST_API_KEY } from "../config";

const BSS_URL = `${HOST_API_KEY}/taxCoreData`;

const customAxios = (token) => axios.create({
  baseURL: BSS_URL,
  headers: {
    "Accept": "application/json",
    "Authorization": `Bearer ${token}`
  }
});

export async function getClientInfo(tin) {
  const token = await AUTH.currentUser.getIdToken(true);
  return customAxios(token).get(`/getCompanyDetails?TIN=${tin}`);
}
