import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { AUTH, DB } from "../../../auth/FirebaseContext";

export const fetchAllPurchaseInvoices = createAsyncThunk("fetchAllPurchaseInvoices", async () => {
  let lastData = null;
  const size = 100;
  let purchase = [];
  while (true) {
    let q = query(
      collection(DB, `efakture/purchase/${AUTH.currentUser.uid}`),
      where("status.LastModifiedUtc", "!=", null),
      orderBy("status.LastModifiedUtc", "desc"),
      limit(size)
    );
    if (lastData) {
      q = query(
        collection(DB, `efakture/purchase/${AUTH.currentUser.uid}`),
        where("status.LastModifiedUtc", "!=", null),
        orderBy("status.LastModifiedUtc", "desc"),
        startAfter(lastData),
        limit(size)
      );
    }
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      break;
    }
    snapshot.docs.forEach((doc) => {
      const data = doc.data();
      purchase.push({
        id: doc.id,
        uid: doc.id,
        xml: data.xml,
        status: data.status
      });
    });
    lastData = snapshot.docs[snapshot.docs.length - 1];
  }
  return purchase;
});

const initialState = {
  invoices: [],
  loading: false
};

export const slice = createSlice({
  name: "eFacturePurchaseInvoices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPurchaseInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPurchaseInvoices.fulfilled, (state, { payload }) => {
        state.invoices = payload;
        state.loading = false;
      })
      .addCase(fetchAllPurchaseInvoices.rejected, (state) => {
        state.loading = false;
      });
  }
});

export default slice.reducer;
