import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { AUTH, DB } from "../../auth/FirebaseContext";
import { getSessionOperator } from "../../helper/session";
import { collection, doc, getDocs, query, setDoc } from "firebase/firestore";

export const createInventoryList = createAsyncThunk("createInventoryList", async (_, { getState }) => {
  const state = getState();
  const { items } = state.inventory;
  let newItems = items.map(item => ({
    uid: item.uid,
    restaurantItemsCategory: item.restaurantItemsCategory || "",
    restaurantGroup: item.restaurantGroup || "",
    code: item.code || "",
    name: item.name || "",
    unit: item.unit || "",
    quantity: item.quantity || "",
    realQuantity: item.realQuantity || "",
    diff: item.diff || "",
    vat: item.vat || ""
  }));
  const id = moment(new Date()).format("X");
  const data = { items: newItems, operator: getSessionOperator() || "" };
  await setDoc(doc(DB, "inventory", "users", AUTH.currentUser.uid, id), data, { merge: true });
  return {
    ...data,
    uid: id
  };
});

export const fetchAllInventoryHistories = createAsyncThunk("fetchAllInventoryHistories", async () => {
  let arr = [];
  const q = query(collection(DB, `inventory/users/${AUTH.currentUser.uid}`));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    arr.push({
      ...doc.data(),
      uid: doc.id
    });
  });
  return arr;
});

const initialState = {
  items: [],
  inventoriesHistory: [],
  loading: false
};

const slice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setWarehouseItems: (state, { payload }) => {
      state.items = payload;
    },
    changeWarehouseItemReelQuantity: (state, { payload }) => {
      const realQuantity = Number(Number(payload.quantity).toFixed(3));
      const { itemId } = payload;
      state.items = state.items.map(item => {
        if (item.uid === itemId) {
          const itemQuantity = item.quantity ? Number(Number(item.quantity).toFixed(2)) : 0;
          return {
            ...item,
            realQuantity,
            diff: Number(Number(itemQuantity - realQuantity).toFixed(3))
          };
        }
        return item;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInventoryList.fulfilled, (state, { payload }) => {
        state.inventoriesHistory = [...state.inventoriesHistory, payload];
      })
      .addCase(fetchAllInventoryHistories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllInventoryHistories.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.inventoriesHistory = payload;
      })
      .addCase(fetchAllInventoryHistories.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const { changeWarehouseItemReelQuantity, setWarehouseItems } = slice.actions;
export default slice.reducer;
